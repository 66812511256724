import lawetaSvg from 'assets/images/icon-laweta.svg';
import wizytaSvg from 'assets/images/icon-umow.svg';
import pomocSvg from 'assets/images/icon-wezwij-pomoc.svg';
import Header from 'components/Header.component';
import React from 'react';
import { Link } from 'react-router-dom';

const AddReport: React.FC = () => (
	<>
		<Header topbarWidth="50" sidebarWidth="50" />
		<div id="content" className="d-flex flex-wrap p-0">
			<div className="w50 services-container d-flex flex-column px-3">
				<Link className="service pb-5 mb-4 d-flex justify-content-around" to="/report/sos">
					<div className="col-md-5 col-sm-6 pt-5 px-sm-1 pr-md-4 d-flex justify-content-end flex-column">
						<h3 className="dual-heading">
							WEZWIJ
							<br />
							<span>POMOC</span>
						</h3>
					</div>
					<div className="col-md-7 col-sm-6 pt-5 pl-sm-2 pl-md-5 d-flex justify-content-start ">
						<div className="text-left d-flex align-content-end flex-wrap">
							<h4 className="w-100">Brakło paliwa?</h4>
							<p>
								Wezwij pomoc <br />
								<span>natychmiast!</span>
							</p>
						</div>
						<img src={pomocSvg} alt="wezwij-pomoc" />
					</div>
				</Link>

				<Link className="service pb-5 mb-4 d-flex justify-content-around" to="/report/visit">
					<div className="col-sm-6 col-md-5 pt-5 px-sm-2 px-md-4 d-flex justify-content-end flex-column">
						<h3 className="dual-heading">
							UMÓW
							<br />
							<span>WIZYTĘ</span>
						</h3>
					</div>
					<div className="col-md-7 col-sm-6 pt-5 pl-sm-2 pl-md-5 d-flex justify-content-start ">
						<div className="text-left d-flex align-content-end flex-wrap">
							<h4 className="w-100">Rozrząd?</h4>
							<p>
								Znajdź mechanika <br />
								<span>którego potrzebujesz!</span>
							</p>
						</div>
						<img src={wizytaSvg} alt="umow-wizyte" />
					</div>
				</Link>

				<Link className="service pb-5 mb-4 d-flex justify-content-around" to="report/carrier">
					<div className="col-md-5 col-sm-6 pt-5 px-sm-2 px-md-4 d-flex justify-content-end flex-column">
						<h3 className="dual-heading">
							ZAMÓW
							<br />
							<span>LAWETĘ</span>
						</h3>
					</div>
					<div className="col-md-7 col-sm-6 pt-5 pl-sm-2 pl-md-5 d-flex justify-content-start ">
						<div className="text-left d-flex align-content-end flex-wrap">
							<h4 className="w-100">Transport?</h4>
							<p>
								Powiedz gdzie i kiedy <br />
								<span>zawieźć Twoje auto!</span>
							</p>
						</div>
						<img src={lawetaSvg} alt="zamow-lawete" />
					</div>
				</Link>
			</div>
			<div className="w50 bg-car">
				<div className="AddReport-content">
					<h1 className="text-white pl-5 text-bold">
						Potrzebujesz
						<br />
						pomocy?
					</h1>
				</div>
			</div>
		</div>
	</>
);

export default AddReport;
