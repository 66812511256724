import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Service } from 'services/types';
import { useGetServicesTreeQuery, useGetServicesListQuery } from 'services/application.service';

interface SelectedServiceProps {
	service: Service;
	handleDeleteService: Function;
	handleDeleteServiceGroup: Function;
	newService: string | null;
	setNewService: React.Dispatch<React.SetStateAction<string | null>>;
}

const SelectedService: React.FC<SelectedServiceProps> = ({
	service,
	handleDeleteService,
	handleDeleteServiceGroup,
	newService,
	setNewService,
}) => {
	const [activeSelectedService, setActiveSelectedService] = useState<Service | null>(null);

	useEffect(() => {
		if (newService) {
			const children: Service | undefined = _.find(service.children, { _id: newService });
			if (children) {
				setActiveSelectedService(children);
				setNewService(null);
			}
		}
	}, [newService]);

	const handleSelectService = (service: Service) => {
		if (activeSelectedService && service._id === activeSelectedService._id) {
			setActiveSelectedService(null);
		} else {
			setActiveSelectedService(service);
		}
	};

	const deleteService = (id: string) => {
		handleDeleteService(id);
		// for updating the leaf
		const temp: Service | null = _.cloneDeep(activeSelectedService);
		if (temp) {
			temp.children = _.remove(temp.children, (child: Service) => child._id !== id);
		}
		setActiveSelectedService(temp);
	};

	const deleteServiceGroup = (e: any, serviceToDelete: Service) => {
		handleDeleteServiceGroup(e, serviceToDelete);
		setActiveSelectedService(service.children[0] || null);
	};

	const renderSecondRow = () => {
		if (service.children && service.children.length) {
			return service.children.map((service: Service) => (
				<li
					className={`${service._id === activeSelectedService?._id && 'active'}`}
					key={`${service._id}${'secondRowDisplay'}`}
					role="presentation"
					onKeyDown={() => handleSelectService(service)}
					onClick={() => handleSelectService(service)}
				>
					{service.name}
					<i
						className="fas fa-times"
						role="presentation"
						onKeyDown={e => deleteServiceGroup(e, service)}
						onClick={e => deleteServiceGroup(e, service)}
					/>
				</li>
			));
		}
		return null;
	};
	const renderThirdRow = () => {
		if (activeSelectedService && activeSelectedService.children) {
			return activeSelectedService.children.map((service: Service) => (
				<li key={`${service._id}${'thirdRowDisplay'}`}>
					{service.name}
					<i
						className="fas fa-times"
						role="presentation"
						onKeyDown={() => deleteService(service._id)}
						onClick={() => deleteService(service._id)}
					/>
				</li>
			));
		}
		return null;
	};

	return (
		<>
			<hr className="darkgray" />
			<div className="service-selector your-services d-flex flex-wrap w-100">
				<div id="category-level1" className="col-sm-12 col-md-4 col-lg-4 px-0">
					<div className="txt-solid">{service.name}</div>
				</div>
				<div id="category-level2" className="col-sm-12 col-md-4 col-lg-4 p-0">
					<ul className="subcategory-list">{renderSecondRow()}</ul>
				</div>
				<div id="category-level3" className="col-sm-12 col-md-4 col-lg-4 p-0">
					<ul className="subcategory-details"> {renderThirdRow()} </ul>
				</div>
			</div>
		</>
	);
};

interface ServicesProps {
	getValues: Function;
	setValue: Function;
	watch: Function;
	error?: any
}

const Services: React.FC<ServicesProps> = ({ getValues, setValue, watch, error }) => {
	const { data: servicesTree } = useGetServicesTreeQuery();
	const { data: servicesList } = useGetServicesListQuery();

	const selectedServices: string[] = watch('services');

	const [selectedMainService, setSelectedMainService] = useState<Service | null>(null);
	const [selectedSecondaryService, setSelectedSecondaryService] = useState<Service | null>(null);
	const [servicesToDisplay, setServicesToDisplay] = useState<Service[] | null>(null);
	const [newService, setNewService] = useState<string | null>(null);

	useEffect(() => {
		if (servicesTree && servicesTree.length) {
			setSelectedMainService(servicesTree[0]);
		}
	}, [servicesTree]);

	useEffect(() => {
		if (selectedServices) {
			parseSelectedServicesForTreeBuilding();
		}
	}, [selectedServices, servicesList]);

	const findServiceParent = (service: Service) => {
		if (service.parent) {
			const parent: Service | undefined = _.find(servicesList, { _id: service.parent });
			if (parent) return parent;
			return null;
		}
		return null;
	};

	const buildParentTree = (idList: string[]) => {
		const parents: Service[] = [];

		idList.forEach((id: string) => {
			const service = _.cloneDeep(_.find(servicesList, { _id: id }));
			if (service) {
				// find parent
				const parent = _.cloneDeep(findServiceParent(service));
				if (parent) {
					const grandparent = findServiceParent(parent);
					if (!grandparent) {
						parents.push(service);
						return;
					}
					// check if parent already exists
					const foundParent = _.find(parents, { _id: parent._id });
					if (foundParent) {
						foundParent.children.push(service);
					} else {
						if (!parent.children) {
							parent.children = [service];
						} else {
							parent.children.push(service);
						}
						parents.push(parent);
					}
				}
			}
		});

		return parents;
	};

	const buildMainParentTree = (parentTree: Service[]) => {
		const parents: Service[] = [];

		parentTree.forEach((service: Service) => {
			const parent = _.cloneDeep(_.find(servicesList, { _id: service.parent }));
			if (parent) {
				// check if parent already exists
				const foundParent: Service | undefined = _.find(parents, { _id: parent._id });
				if (foundParent) {
					foundParent.children.push(service);
				} else {
					if (!parent.children) {
						parent.children = [service];
					} else {
						parent.children.push(service);
					}
					parents.push(parent);
				}
			}
		});
		return parents;
	};

	const parseSelectedServicesForTreeBuilding = () => {
		let services: Service[] = [];

		if (selectedServices && selectedServices.length) {
			const parentTree: Service[] = buildParentTree(selectedServices);
			// check if current parent tree is root
			if (parentTree && parentTree[0] && parentTree[0].parent) {
				// get new parents
				const mainParentTree: Service[] = buildMainParentTree(parentTree);
				services = mainParentTree;
			} else {
				services = parentTree;
			}
		}
		setServicesToDisplay(services);
	};

	const handleSetSelectedMainService = (service: Service) => {
		setSelectedMainService(service);
		setSelectedSecondaryService(null);
	};

	const renderServicesFirstRow = () => {
		if (servicesTree && servicesTree.length) {
			return servicesTree.map((service: Service) => (
				<option key={`${service._id}${'firstRowSelect'}`} value={JSON.stringify(service)}>
					{service.name}
				</option>
			));
		}
		return null;
	};

	const renderServicesSecondRow = () => {
		if (selectedMainService && selectedMainService.children) {
			return selectedMainService.children.map((service: Service) => (
				<li
					className={`${service._id === selectedSecondaryService?._id && 'active'}`}
					key={`${service._id}${'secondRowSelect'}`}
					role="presentation"
					onKeyDown={() => setSelectedSecondaryService(service)}
					onClick={() => setSelectedSecondaryService(service)}
				>
					{service.name}
					<i
						className="fas fa-plus"
						role="presentation"
						onKeyDown={e => handleSelectServiceGroup(e, service)}
						onClick={e => handleSelectServiceGroup(e, service)}
					/>
				</li>
			));
		}
		return null;
	};

	const renderServicesThirdRow = () => {
		if (selectedSecondaryService && selectedSecondaryService.children) {
			return selectedSecondaryService.children.map((service: Service) => (
				<li key={`${service._id}${'thirdRowSelect'}`}>
					{service.name}
					<i
						className="fas fa-plus"
						role="presentation"
						onKeyDown={() => handleSelectService(service)}
						onClick={() => handleSelectService(service)}
					/>
				</li>
			));
		}
		return null;
	};

	const renderServicesToDisplay = () => {
		if (servicesToDisplay && servicesToDisplay.length) {
			return servicesToDisplay.map((service: Service) => (
				<SelectedService
					key={`${service._id}displayFirstRow`}
					service={service}
					handleDeleteService={handleDeleteService}
					handleDeleteServiceGroup={handleDeleteServiceGroup}
					newService={newService}
					setNewService={setNewService}
				/>
			));
		}
		return null;
	};

	const handleSelectServiceGroup = (e: any, service: Service) => {
		e.stopPropagation();

		if (service && service.children && service.children.length) {
			service.children.forEach((service: Service) => handleSelectService(service));
		} else {
			handleSelectService(service);
		}
	};

	const handleSelectService = async (service: Service) => {
		if (_.indexOf(selectedServices, service._id) === -1) {
			const values: string[] = _.cloneDeep(getValues('services')) || [];
			values.push(service._id);

			await setValue('services', values);

			// check who is parent to highlight in service display
			const selectedService: Service | undefined = _.find(servicesList, { _id: service._id });
			if (selectedService) {
				const parent: Service | null = findServiceParent(selectedService);
				if (parent) {
					setNewService(parent._id);
				}
			}
		}
	};

	const handleDeleteServiceGroup = (e: any, service: Service) => {
		e.stopPropagation();
		if (service && service.children) {
			service.children.forEach((service: Service) => handleDeleteService(service._id));
		} else {
			handleDeleteService(service._id);
		}
	};

	const handleDeleteService = (id: string) => {
		let values: string[] = _.cloneDeep(getValues('services')) || [];
		values = _.pull(values, id);
		setValue('services', values);
	};

	return (
		<>
			{(servicesToDisplay && servicesToDisplay.length && (
				<div className="row chips-container">
					<div className="col-sm-12 col-lg-12 col-xl-12 pt-3">
						<label>Zakres Twoich usług</label>
						<p className="mb-3">
							Kliknij w <i className="fas fa-times" /> by usunąć dodane kategorie lub ich całe grupy.
						</p>

						{renderServicesToDisplay()}

						<hr className="darkgray" />
					</div>
				</div>
			)) ||
				null}

			<div className="row">
				<div className="invalid-form col-sm-12 col-lg-12 col-xl-12">
					<label>Dodaj usługi</label>
					<p className="mt-3">
						Kliknij w <i className="mx-1 fas fa-plus" /> by dodać konkretne kategorie lub ich całe grupy do
						swoich usług.
					</p>
					{error && <div className="invalid-feedback">{error.message}</div>}
					<hr className="darkgray" />
					<div className="service-selector d-flex flex-wrap w-100">
						<div id="category-level1" className="col-sm-12 col-md-4 col-lg-4 px-0">
							<select
								onChange={e => handleSetSelectedMainService(JSON.parse(e.target.value))}
								className="form-select txt-solid"
								aria-label="Preferowany język"
							>
								{renderServicesFirstRow()}
							</select>
						</div>
						<div id="category-level2" className="col-sm-12 col-md-4 col-lg-4  p-0">
							<ul className="subcategory-list">{renderServicesSecondRow()}</ul>
						</div>
						<div id="category-level3" className="col-sm-12 col-md-4 col-lg-4  p-0">
							<ul className="subcategory-details">{renderServicesThirdRow()}</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Services;
