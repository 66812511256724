import { nanoid } from '@reduxjs/toolkit';
import { getFormattedAddress } from 'app/utils';
import gpsIcon from 'assets/images/btn-gps.svg';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import {
	useLazyGetLocationByCoordinatesQuery,
	useLazyGetLocationDetailsQuery,
	useLazyGetLocationsQuery,
} from 'services/location.service';
import { Address } from 'services/types';

interface GoogleAutocompleteComponentProps {
	id: string;
	error?:
		| {
				country?: FieldError | undefined;
				city?: FieldError | undefined;
				postCode?: FieldError | undefined;
				street?: FieldError | undefined;
				houseNumber?: FieldError | undefined;
				coordinates?: any;
				location?: any;
		  }
		| string;
	defaultValue?: string;
	onChange: (address: Address) => void;
}

const GoogleAutocompleteComponent: React.FC<GoogleAutocompleteComponentProps> = ({
	id,
	error,
	defaultValue,
	onChange,
}) => {
	const [token] = useState(nanoid(10));
	const [query, setQuery] = useState('');
	const [showOptions, setShowOptions] = useState(false);
	const [optionsHovered, setOptionsHovered] = useState(false);
	const [locationsLoading, setLocationsLoading] = useState(false);
	const [getLocations, { data: locationsResult }] = useLazyGetLocationsQuery();
	const [getLocationDetails, { data: detailsResult }] = useLazyGetLocationDetailsQuery();
	const [getLocationByCoordinates] = useLazyGetLocationByCoordinatesQuery();

	const { addToast } = useToasts();

	let amountDelayTimer: any;
	const updateQuery = (text: string) => {
		clearTimeout(amountDelayTimer);
		amountDelayTimer = setTimeout(() => {
			setQuery(text);
		}, 1000);
	};

	const getResults = async () => {
		if (query.length > 0) {
			setLocationsLoading(true);
			await getLocations({ query, token }).unwrap();
			setLocationsLoading(false);
			setShowOptions(true);
		} else {
			setShowOptions(false);
		}
	};

	useEffect(() => {
		getResults();
	}, [query]);

	useEffect(() => {
		if (detailsResult && detailsResult.data) {
			onChange(detailsResult.data);
			setShowOptions(false);
		}
	}, [detailsResult]);

	const getUserLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(async position => {
				try {
					const locationResponse = await getLocationByCoordinates({
						lat: position.coords.latitude,
						lon: position.coords.longitude,
					}).unwrap();
					const formattedAddress = getFormattedAddress(locationResponse.data);
					const input: any = document.getElementById(id);
					if (input) {
						input.value = formattedAddress;
					}
					onChange(locationResponse.data);
				} catch (err) {
					addToast('Nie można pobrać lokalizacji');
				}
			});
		} else {
			addToast('Nie można pobrać lokalizacji');
		}
	};

	const renderError = () => {
		if (!_.isEmpty(error)) {
			if (_.isString(error)) {
				return <div className="invalid-feedback">{error}</div>;
			}
			if (_.isObject(error)) {
				const message = ['Wpisz adres'];
				error.city?.message && message.push(error.city.message);
				error.street?.message && message.push(error.street.message);
				error.houseNumber?.message && message.push(error.houseNumber.message);
				return <div className="invalid-feedback">{message.join('. ')}</div>;
			}
		}
		return null;
	};

	const getLocationDetailsQueryParams = async (inputValue: string, params: string) => {
		const addressDetails = inputValue.split(',');

		if (addressDetails.length === 3) {
			if (addressDetails[0] && addressDetails[0].split(' ')[0]) {
				params += `&route=${addressDetails[0].split(' ')[0]}`;
			}

			if (addressDetails[0] && addressDetails[0].split(' ')[1]) {
				params += `&streetNumber=${addressDetails[0].split(' ')[1]}`;
			}

			if (addressDetails[1]) {
				params += `&locality=${addressDetails[1]}`;
			}
		} else if (addressDetails.length === 2) {
			params += `&locality=${addressDetails[0]}`;
		}

		return params;
	};

	const selectAddress = async (location: { name: string; value: string }) => {
		const input: any = document.getElementById(id);
		if (input) {
			input.value = location.name;
		}

		const params = await getLocationDetailsQueryParams(location.name, `place_id=${location.value}`);
		getLocationDetails({ params });
	};

	const renderResults = () => {
		if (locationsResult && !_.isEmpty(locationsResult.data)) {
			return locationsResult.data?.map((location: { name: string; value: string }) => (
				<li
					key={location.value}
					onFocus={() => setOptionsHovered(true)}
					onMouseOver={() => setOptionsHovered(true)}
					onMouseOut={() => setOptionsHovered(false)}
					onBlur={() => setOptionsHovered(true)}
					onClick={() => selectAddress(location)}
					onKeyPress={() => selectAddress(location)}
					role="presentation"
				>
					{location.name}
				</li>
			));
		}
		return <li className="text-center">Brak wyników</li>;
	};

	return (
		<div className="autocomplete-wrapper">
			<div className="input-group mb-3">
				{locationsLoading && (
					<div className="autocomplete-loading">
						<div className="spinner" />
					</div>
				)}
				<input
					id={id}
					onChange={e => updateQuery(e.target.value)}
					type="text"
					className="form-control"
					placeholder="Wpisz adres"
					aria-label="Lokalizacja"
					aria-describedby="input-group-button-right"
					onFocus={() => setShowOptions(true)}
					onBlur={() => !optionsHovered && setShowOptions(false)}
					autoComplete="off"
					defaultValue={defaultValue || ''}
				/>
				<button
					type="button"
					className="btn btn-primary button-right"
					id="input-group-button-right"
					onClick={getUserLocation}
				>
					<img src={gpsIcon} alt="gps" />
					Lokalizuj
				</button>
			</div>
			{showOptions && locationsResult && (
				<div className="ah-select">
					<ul>{renderResults()}</ul>
				</div>
			)}
			{error && renderError()}
		</div>
	);
};

export default GoogleAutocompleteComponent;
