import * as yup from 'yup';

export const reportCarSchema = yup.object({
	manufacturer: yup.string().required('Pole jest wymagane'),
	model: yup.string().required('Pole jest wymagane'),
	year: yup.string().required('Pole jest wymagane'),
	vin: yup.string(),
	engine: yup.string().required('Pole jest wymagane'),
	fuelType: yup.string().required('Pole jest wymagane'),
	transmission: yup.string().required('Pole jest wymagane'),
	chassisType: yup.string().required('Pole jest wymagane'),
	insurance: yup.string(),
});

export default yup
	.object({
		kind: yup.string().required('Pole jest wymagane'),
		address: yup
			.object({
				country: yup.string(),
				city: yup.string(),
				postCode: yup.string(),
				street: yup.string(),
				houseNumber: yup.string(),
				coordinates: yup.array().of(yup.number()).required('Koordynaty są wymagane'),
			})
			.required('Pole jest wymagane'),
		deliveryAddress: yup.object().when('needsCarrier', {
			is: (needsCarrier: boolean) => !!needsCarrier,
			then: yup
				.object({
					country: yup.string(),
					city: yup.string(),
					postCode: yup.string(),
					street: yup.string(),
					houseNumber: yup.string(),
					coordinates: yup.array().of(yup.number()).required('Koordynaty są wymagane'),
				})
				.required('Pole jest wymagane'),
			otherwise: yup.object({
				country: yup.string(),
				city: yup.string(),
				postCode: yup.string(),
				street: yup.string(),
				houseNumber: yup.string(),
				location: yup.array().of(yup.number()),
			}),
		}),
		car: reportCarSchema.required('Pole jest wymagane'),
		dueDate: yup.object({
			kind: yup.string().default('range'),
			value: yup
				.object({
					from: yup.date().required('Pole jest wymagane'),
					to: yup
						.date()
						.min(yup.ref('from'), "Data 'do' nie może być wcześniejsza niż data 'od'")
						.required('Pole jest wymagane'),
				})
				.required('Pole jest wymagane'),
			hour: yup
				.object({
					from: yup.string().required('Pole jest wymagane'),
					to: yup.string().required('Pole jest wymagane'),
				})
				.required('Pole jest wymagane'),
		}),
		description: yup.string(),
		needsCarrier: yup.boolean(),
		searchRadius: yup.number().required('Pole jest wymagane').min(1, 'Pole jest wymagane'),
		paymentType: yup.string().required('Pole jest wymagane'),
	})
	.required();
