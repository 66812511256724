import React from 'react';

interface ButtonComponentProps {
	className?: string;
	text?: string;
	type?: 'button' | 'submit';
	onClick?: () => void;
	isLoading?: boolean;
	disabled?: boolean;
	additional?: Record<string, string>;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
	className,
	text = '',
	type,
	onClick = () => null,
	isLoading,
	disabled,
	additional = {}
}: ButtonComponentProps) => {
	switch (type) {
		case 'button':
			return (
				<button
					type="button"
					className={`${className || ''} position-relative align-items-center d-flex`}
					onClick={onClick}
					onKeyPress={onClick}
					disabled={disabled || isLoading}
					{...additional}
				>
					<span className={`${isLoading ? 'button-loading' : ''}`}>{text}</span>
					{isLoading && <div className="spinner dark-spinner spinnercenter" />}
				</button>
			);

		case 'submit':
			return (
				<button
					type="submit"
					className={`${className || ''} position-relative align-items-center d-flex justify-content-center`}
					onClick={onClick}
					onKeyPress={onClick}
					disabled={disabled || isLoading}
					{...additional}
				>
					<span className={`${isLoading ? 'button-loading' : ''}`}>{text}</span>
					{isLoading && <div className="spinner dark-spinner spinnercenter" />}
				</button>
			);
		default:
			return null;
	}
};

export default ButtonComponent;
