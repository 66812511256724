import {
	getChassisTypeName,
	getFormattedAddress,
	getFuelTypeName,
	getManufacturerName,
	getPaymentTypeName,
	getReportKindName,
	getServiceBreadCrumb,
	getTransmissionName,
} from 'app/utils';
import separatorIcon from 'assets/images/x-sep.svg';
import Breadcrumb from 'components/Breadcrumb.component';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { OfferStatus, Report, ReportType } from 'services/types';

interface ReportBoardListCardComponentProps {
	report: Partial<Report>;
	onDetailsClick: (reportData: Partial<Report>) => void;
	isActive: boolean;
}

const ReportBoardListCard: React.FC<ReportBoardListCardComponentProps> = ({
	report,
	onDetailsClick,
	isActive,
}: ReportBoardListCardComponentProps) => {
	const renderOfferStatusLabel = () => {
		const status = _.get(report, 'companyOffer.status');
		switch (status) {
			case OfferStatus.WAITING:
				return (
					<button className="btn btn-cornertop offer-sent mr-3" type="button">
						Oferta wysłana
					</button>
				);
			case OfferStatus.ACCEPTED:
				return (
					<button className="btn btn-cornertop offer-success mr-3" type="button">
						Oferta przyjęta
					</button>
				);
			case OfferStatus.REJECTED:
				return (
					<button className="btn btn-cornertop offer-reject mr-3" type="button">
						Oferta odrzucona
					</button>
				);
			default:
				return null;
		}
	};

	const renderDueDate = () => {
		switch (report.dueDate?.kind) {
			case 'range': {
				return (
					<div className="date-container d-flex flex-wrap ">
						<div className="date-from txt-solid">
							{moment(report.dueDate?.value?.from).format('DD.MM.YYYY')}
						</div>
						<div className="separator">
							<img src={separatorIcon} alt="separator" />
						</div>
						<div className="date-to txt-solid">
							{moment(report.dueDate?.value?.to).format('DD.MM.YYYY')}
						</div>
					</div>
				);
			}
			case 'asap':
			default:
				return (
					<div className="date-container d-flex flex-wrap ">
						<div className="date-from txt-solid">Jak najszybciej</div>
					</div>
				);
		}
	};

	const renderAdditionalInfo = () => {
		switch (report.kind) {
			case ReportType.CAR_CARRIER: {
				return (
					<>
						<div className="car-container col-sm-12 d-flex flex-wrap">
							<label className="green">Miejsce początkowe</label>
							<div className="start-point txt-solid">{getFormattedAddress(report.address!)}</div>
						</div>
						<div className="car-container col-sm-12 d-flex flex-wrap">
							<label className="green">Miejsce końcowe</label>
							<div className="start-point txt-solid">{getFormattedAddress(report.deliveryAddress!)}</div>
						</div>
					</>
				);
			}
			case ReportType.VISIT: {
				return <Breadcrumb services={report.services} />;
			}
			case ReportType.SOS:
			default: {
				return (
					<>
						<label className="green">Płatność</label>
						<div className="date-container d-flex flex-wrap">
							<div className="date-from txt-solid">{getPaymentTypeName(report.paymentType!)}</div>
						</div>
					</>
				);
			}
		}
	};

	return (
		<div
			className={`offer pt-3 pr-0 pb-3 pl-3 mb-4 d-flex flex-wrap justify-content-between ${
				isActive ? 'active' : ''
			}`}
		>
			<div className="row  col-sm-12 col-md-4 col-lg-4 d-flex align-content-start flex-wrap">
				<h3 className="green mb-0">{getReportKindName(report.kind!)}</h3>
				<p className="subheading">{moment(report.createdAt).fromNow()}</p>

				<label className="green">Termin usługi</label>
				{renderDueDate()}
				{renderAdditionalInfo()}
			</div>
			<div className="col-sm-12 col-md-8 col-lg-8 myborder-left d-flex flex-wrap justify-content-end">
				<div className="row">
					<div className="car-container col-sm-12 d-flex flex-wrap align-items-start">
						<label className="green">Samochód</label>
						{report.car?.manufacturer && (
							<div className="car-make txt-solid">{getManufacturerName(report.car.manufacturer)}</div>
						)}
						{report.car?.model && <div className="car-model txt-solid">{report.car.model}</div>}
						{report.car?.year && <div className="car-model txt-solid">{report.car.year}</div>}
					</div>
					<div className="car-container col-sm-12 d-flex flex-wrap">
						<label className="green">Dodatkowe</label>
						{report.car?.engine && <div className="car-cc txt-solid">{report.car.engine}</div>}
						{report.car?.fuelType && (
							<div className="car-petrol txt-solid">{getFuelTypeName(report.car.fuelType)}</div>
						)}
						{report.car?.chassisType && (
							<div className="car-body txt-solid">{getChassisTypeName(report.car.chassisType)}</div>
						)}
						{report.car?.transmission && (
							<div className="car-gearbox txt-solid">{getTransmissionName(report.car.transmission)}</div>
						)}
					</div>
					<div className="col-sm-7 ">
						<label className="green">Dodatkowe informacje</label>
						<p className="comment limited">{report.description || '-'}</p>
					</div>
					{report.isMobileService && (
						<div className="col-sm-7">
							<label className="green">Czy usługa mobilna?</label>
							<div className="car-vin txt-solid ">tak</div>
						</div>
					)}
				</div>
				{renderOfferStatusLabel()}
				<button
					className="btn btn-primary btn-corner"
					id="sidebarCollapse"
					type="button"
					onClick={() => onDetailsClick(report)}
				>
					Zobacz szczegóły
				</button>
			</div>
		</div>
	);
};

export default ReportBoardListCard;
