import { useAppDispatch, useAppSelector } from 'app/hooks';
import { handleRedirectToMainRoute } from 'app/utils';
// Custom styles
import 'assets/styles/style.scss';
import Footer from 'components/Footer.component';
import CustomToast from 'components/Toast.component';
import { setCredentials } from 'features/auth/authSlice';
import _ from 'lodash';
import UsersList from 'pages/admin/UsersList.component';
import ActivateAccount from 'pages/auth/ActivateAccount.component';
import LoginForm from 'pages/auth/LoginForm.component';
import NewPassword from 'pages/auth/NewPassword.component';
import NoPage from 'pages/auth/NoPage.component';
import RegisterForm from 'pages/auth/RegisterForm.component';
import ResetPassword from 'pages/auth/ResetPassword.component';
import Garage from 'pages/garage/Garage.component';
import UserProfile from 'pages/profile/UserProfile.component';
import AddReport from 'pages/reports/AddReport.component';
import CarCarrierReportForm from 'pages/reports/CarCarrierReportForm.component';
import MyReport from 'pages/reports/MyReport.component';
import MyReports from 'pages/reports/MyReports.component';
import ReportBoard from 'pages/reports/reportBoard/ReportBoard.component';
import SosReportForm from 'pages/reports/SosReportForm.component';
import VisitReportForm from 'pages/reports/VisitReportForm.component';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useIsAuthQuery } from 'services/application.service';
import { UserType } from 'services/types';

const App: React.FC = () => {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const history = useHistory();
	const { data: isAuth, isLoading: isAuthLoading, error: isAuthError } = useIsAuthQuery();
	const loggedUser = useAppSelector(state => state.auth.user);
	const userType = _.get(loggedUser, 'type');

	useEffect(() => {
		window.addEventListener('popstate', () => {
			if (!loggedUser && !isSafeRoute()) {
				history.replace(`/login`);
			}
		});
	}, [history]);

	useEffect(() => {
		if (isAuth && isAuth.success) {
			dispatch(setCredentials(isAuth));
		}
	}, [isAuth]);

	useEffect(() => {
		if (isAuthError && !isAuthLoading && !isSafeRoute()) {
			history.replace('/login');
		}
	}, [isAuthError, isAuthLoading]);

	useEffect(() => {
		if (loggedUser && loggedUser.type) {
			handleRedirectToMainRoute(_.get(loggedUser, 'type'), history);
		}
	}, [loggedUser]);

	const isSafeRoute = () =>
		location.pathname.includes('resetPassword') ||
		location.pathname.includes('register') ||
		location.pathname.includes('activateAccount');

	return (
		<>
			<ToastProvider components={{ Toast: CustomToast }} autoDismiss>
				<div className="main">
					<div className="wrapper">
						<Switch>
							<Route exact path="/resetPassword">
								<ResetPassword />
							</Route>
							<Route exact path="/resetPassword/:token">
								<NewPassword />
							</Route>
							<Route exact path="/activateAccount/:token">
								<ActivateAccount />
							</Route>
							<Route exact path="/login">
								<LoginForm />
							</Route>
							<Route exact path="/register">
								<RegisterForm />
							</Route>
							<Route exact path="/profile">
								<UserProfile />
							</Route>
							{userType === UserType.ADMIN && (
								<Route exact path="/admin">
									<UsersList />
								</Route>
							)}
							{userType === UserType.COMPANY && (
								<Route exact path="/board">
									<ReportBoard />
								</Route>
							)}
							{userType === UserType.USER && (
								<>
									<Route exact path="/reports">
										<MyReports />
									</Route>
									<Route exact path="/garage">
										<Garage />
									</Route>
									<Route exact path="/report">
										<AddReport />
									</Route>
									<Route exact path="/report/sos">
										<SosReportForm />
									</Route>
									<Route exact path="/report/carrier">
										<CarCarrierReportForm />
									</Route>
									<Route exact path="/report/visit">
										<VisitReportForm />
									</Route>
									<Route exact path="/reports/:id">
										<MyReport />
									</Route>
								</>
							)}
							<Route component={NoPage} path="*" />;
						</Switch>
					</div>
				</div>
			</ToastProvider>
			<Footer />
		</>
	);
};

export default App;
