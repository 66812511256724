import { useAppDispatch, useAppSelector } from 'app/hooks';
import { handleGoToMainWebsite } from 'app/utils';
import { useGoogleLogout } from 'react-google-login';
import logoHorizontal from 'assets/images/logo-horizontal.svg';
import logoWhSygnet from 'assets/images/logo-wh-sygnet.svg';
import { setCredentials } from 'features/auth/authSlice';
import _ from 'lodash';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { User, UserType } from 'services/types';
import { GOOGLE_CLIENT_ID } from 'app/keys';

interface HeaderComponentProps {
	topbarWidth?: string;
	sidebarWidth?: string;
}

const Header: React.FC<HeaderComponentProps> = ({ topbarWidth, sidebarWidth }: HeaderComponentProps) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useAppDispatch();
	const [openSidebar, setOpenSidebar] = useState(false);
	const { signOut: googleSignOut } = useGoogleLogout({ clientId: GOOGLE_CLIENT_ID });

	const user: User | null = useAppSelector(state => state.auth.user);
	const userType = _.get(user, 'type');

	const handleLogout = (): void => {
		googleSignOut();
		dispatch(setCredentials({ token: null, user: null, serviceDictionary: null }));
		localStorage.removeItem('token');
		history.replace('/login');
	};

	return (
		<nav id="topbar">
			<button
				id="menuCollapse"
				type="button"
				onClick={() => setOpenSidebar(!openSidebar)}
				className={`menu-btn ${openSidebar && 'active'}`}
			>
				<span />
				<span />
				<span />
			</button>

			<div id="mobileMenu" className={`${openSidebar && 'active'}`}>
				<div className="text-center">
					<img src={logoWhSygnet} alt="sygnet" className="menu-logo-sygnet" />
				</div>
				<ul className="menu-links">
					{userType === UserType.USER && (
						<Link to="/report">
							<li className={location.pathname === '/report' ? 'menu-link-active' : ''}>
								Dodaj zgłoszenie
							</li>
						</Link>
					)}
					{userType === UserType.USER && (
						<Link to="/reports">
							<li className={location.pathname === '/reports' ? 'menu-link-active' : ''}>
								Moje zgłoszenia
							</li>
						</Link>
					)}
					{userType === UserType.COMPANY && (
						<Link to="/board">
							<li className={location.pathname === '/board' ? 'menu-link-active' : ''}>
								Tablica zgłoszeń
							</li>
						</Link>
					)}
					{userType === UserType.USER && (
						<Link to="/garage">
							<li className={location.pathname === '/garage' ? 'menu-link-active' : ''}>Garaż</li>
						</Link>
					)}
					<Link to="/profile">
						<li className={location.pathname === '/profile' ? 'menu-link-active' : ''}>Profil</li>
					</Link>
					{userType === UserType.ADMIN && (
						<Link to="/admin">
							<li className={location.pathname === '/admin' ? 'menu-link-active' : ''}>Admin</li>
						</Link>
					)}
					<li role="presentation" onClick={handleLogout} onKeyPress={handleLogout}>
						Wyloguj
					</li>
				</ul>
			</div>
			<div className={`w${topbarWidth || 70} topbar-left`}>
				<div
					className="top-logo"
					role="presentation"
					onClick={handleGoToMainWebsite}
					onKeyPress={handleGoToMainWebsite}
				>
					<img src={logoHorizontal} alt="logo" />
				</div>
			</div>
			<div className={`w${sidebarWidth || 30} sidebar-header d-flex flex-wrap`}>
				<Link to="/profile">
					<h1 className="hello-user">
						Witaj <span>{_.words(user?.name)[0]}</span>!
					</h1>
				</Link>
			</div>
		</nav>
	);
};

export default Header;
