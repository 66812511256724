import { yupResolver } from '@hookform/resolvers/yup';
import logoWh from 'assets/images/logo-wh.svg';
import ButtonComponent from 'components/Button.component';
import TextInputComponent from 'components/TextInput.component';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useResetPasswordMutation } from 'services/application.service';
import resetPasswordSchema from './resetPassword.schema';

interface ResetPasswordFormValues {
	email: string;
}

const ResetPassword: React.FC = () => {
	const history = useHistory();
	const { addToast } = useToasts();
	const [resetPassword, { isLoading }] = useResetPasswordMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<ResetPasswordFormValues>({
		resolver: yupResolver(resetPasswordSchema),
		reValidateMode: 'onChange',
	});

	const handleResetPassword = async (data: ResetPasswordFormValues): Promise<void> => {
		try {
			await resetPassword({
				email: data.email,
			}).unwrap();

			addToast('Mail z instrukcją został wysłany');
			history.replace('/login');
		} catch (err) {
			addToast('Wystąpił błąd');
		}
	};

	return (
		<div id="content" className="d-flex flex-wrap p-0">
			<div className="login-cover bg-car2 w50">
				<div className="top-logo">
					<img src={logoWh} alt="logo" />
				</div>
			</div>
			<div className="autohelp-form w50">
				<div className="info-bar">
					<span>Nie masz konta?</span>
					<Link to="/register">
						<div>Zarejestruj się!</div>
					</Link>
				</div>
				<div className="form-container">
					<h1 className="p-5 bold">
						Dbanie o samochód
						<br /> nigdy nie było <br />
						<span>tak proste.</span>
					</h1>

					<form onSubmit={handleSubmit(handleResetPassword)}>
						<div className="box login-form">
							<div className="row">
								<div
									className={`${
										errors.email?.message && 'invalid-form'
									} col-sm-12 col-md-6 col-lg-4 col-xl-4`}
								>
									<TextInputComponent
										id="email"
										type="text"
										placeholder="Twój adres e-mail"
										register={register}
										error={errors.email?.message}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12 col-lg-6 col-xl-4 pt-sm-0 pt-md-3">
									<ButtonComponent
										type="submit"
										className="btn btn-primary btn-submit"
										isLoading={isLoading}
										text="Przypomnij hasło"
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
