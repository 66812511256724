import React from 'react';

interface SideBarComponentProps {
	upperContent?: React.ReactNode;
	content: React.ReactNode;
	bottomContent?: React.ReactNode;
	onClose: () => void;
	isOpen: boolean;
}

const SideBar: React.FC<SideBarComponentProps> = ({ upperContent, content,bottomContent, isOpen, onClose }: SideBarComponentProps) => (
	<div id="sidebar" className={`w30 ${isOpen && 'active'}`}>
		<div className="sidebar-content">
			<button className="navbar-btn" id="sidebarClose" type="button" onClick={() => onClose()}>
				<span />
				<span />
				<span />
			</button>
			{upperContent}
			<div className="details col-sm-12">{content}</div>
			{bottomContent}
		</div>
	</div>
);

export default SideBar;
