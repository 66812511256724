import * as yup from 'yup';

export const userSchema = yup
	.object({
		languages: yup.array().of(yup.string()).required('Pole jest wymagane'),
		email: yup.string().email('Niepoprawny format e-mail').required('Pole jest wymagane'),
		password: yup.string().required('Pole jest wymagane'),
		password2: yup
			.string()
			.required('Pole jest wymagane')
			.oneOf([yup.ref('password'), null], 'Hasła muszą być identyczne'),
		phone: yup.string().length(9, 'Podano niepoprawny numer').required('Pole jest wymagane'),
		name: yup
			.string()
			.matches(/^[aA-zZąćęłńóśźż ]+$/i, 'Podano niepoprawną wartość')
			.required('Pole jest wymagane'),
		avatar: yup.string().default('')
	})
	.required();

export const userEditSchema = yup
	.object({
		languages: yup.array().of(yup.string()).required('Pole jest wymagane'),
		email: yup.string().email('Niepoprawny format e-mail').required('Pole jest wymagane'),
		password: yup.string(),
		password2: yup.string().oneOf([yup.ref('password'), null], 'Hasła muszą być identyczne'),
		phone: yup.string().length(9, 'Podano niepoprawny numer').required('Pole jest wymagane'),
		name: yup
			.string()
			.matches(/^[aA-zZąćęłńóśźż ]+$/i, 'Podano niepoprawną wartość')
			.required('Pole jest wymagane'),
		avatar: yup.string().default('')
	})
	.required();

export const ownerSchema = yup
	.object({
		companyName: yup.string().required('Pole jest wymagane'),
		name: yup
			.string()
			.matches(/^[aA-zZąćęłńóśźż ]+$/i, 'Podano niepoprawną wartość')
			.required('Pole jest wymagane'),
		languages: yup.array().of(yup.string()).required('Pole jest wymagane'),
		description: yup.string(),
		nip: yup.string().length(10, 'Podano niepoprawny NIP').required('Pole jest wymagane'),
		email: yup.string().email('Niepoprawny format e-mail').required('Pole jest wymagane'),
		phone: yup.string().required('Pole jest wymagane'),
		address: yup
			.object({
				country: yup.string(),
				city: yup.string(),
				postCode: yup.string(),
				street: yup.string(),
				houseNumber: yup.string(),
				coordinates: yup.array().of(yup.number()).required('Koordynaty są wymagane'),
			})
			.required('Pole jest wymagane'),
		password: yup.string().required('Pole jest wymagane'),
		password2: yup
			.string()
			.required('Pole jest wymagane')
			.oneOf([yup.ref('password'), null], 'Hasła muszą być identyczne'),
		avatar: yup.string().default('')
	})
	.required();

export const ownerEditSchema = yup
	.object({
		companyName: yup.string().required('Pole jest wymagane'),
		name: yup
			.string()
			.matches(/^[aA-zZąćęłńóśźż ]+$/i, 'Podano niepoprawną wartość')
			.required('Pole jest wymagane'),
		languages: yup.array().of(yup.string()).required('Pole jest wymagane'),
		description: yup.string(),
		nip: yup.string().length(10, 'Podano niepoprawny NIP').required('Pole jest wymagane'),
		email: yup.string().email('Niepoprawny format e-mail').required('Pole jest wymagane'),
		phone: yup.string().required('Pole jest wymagane'),
		password: yup.string(),
		password2: yup.string().oneOf([yup.ref('password'), null], 'Hasła muszą być identyczne'),
		avatar: yup.string().default('')

	})
	.required();
