/* eslint-disable react/jsx-indent-props */
import { yupResolver } from '@hookform/resolvers/yup';
import { handleApiErrors } from 'app/utils';
import separatorIcon from 'assets/images/x-sep.svg';
import ButtonComponent from 'components/Button.component';
import TextareaComponent from 'components/Textarea.component';
import TextInputComponent from 'components/TextInput.component';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useToasts } from 'react-toast-notifications';
import { useChangeOfferStatusMutation, useCreateOfferMutation } from 'services/report.service';
import { ChangeOfferStatusRequest, Report, ReportType } from 'services/types';
import schema from './offer.schema';

interface ReporBoardFormComponentProps {
	report: Partial<Report> | null;
	onSidebarClose: () => void;
}

const OfferForm: React.FC<ReporBoardFormComponentProps> = ({
	report,
	onSidebarClose,
}: ReporBoardFormComponentProps) => {
	const [createOffer, { isLoading }] = useCreateOfferMutation();
	const { addToast } = useToasts();
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<Record<string, any>>({
		resolver: yupResolver(schema),
		defaultValues: {
			report: report?._id,
		},
		reValidateMode: 'onChange',
	});
	const handleSaveOffer = async (data: Record<string, any>) => {
		try {
			data.date = moment(`${data.date} ${data.hour}`, 'YYYY-MM-DD HH:mm').format('X');
			if (data.pickupDate) {
				data.pickupDate = moment(`${data.pickupDate} ${data.pickupHour}`, 'YYYY-MM-DD HH:mm').format('X');
			}
			await createOffer(data).unwrap();
			addToast('Wysłano ofertę');
			onSidebarClose();
		} catch (err) {
			addToast('Wystąpił błąd');
			handleApiErrors(setError, err);
		}
	};

	return (
		<form className="lower-form resizeable" onSubmit={handleSubmit(handleSaveOffer)}>
			<h4 className="text-white bold mb-0">Twoja oferta</h4>
			<div className="d-flex align-items-start flex-wrap p-0">
				<div className={`col-md-12 col-lg-6 p-1 ${errors.date?.message ? 'invalid-form' : ''}`}>
					<TextInputComponent
						id="date"
						label="Data rozpoczęcia usługi"
						type="date"
						placeholder="Data rozpoczęcia usługi"
						register={register}
						error={errors.date?.message}
					/>
				</div>
				<div className={`col-md-12 col-lg-6 p-1 ${errors.hour?.message ? 'invalid-form' : ''}`}>
					<TextInputComponent
						id="hour"
						label="Godzina rozpoczęcia usługi"
						type="time"
						placeholder="Godzina rozpoczęcia usługi"
						register={register}
						error={errors.hour?.message}
					/>
				</div>
				<div className={`col-md-12 col-lg-6 p-1 ${errors.pickupDate?.message ? 'invalid-form' : ''}`}>
					<TextInputComponent
						id="pickupDate"
						label="Data odbioru"
						type="date"
						placeholder="Data odbioru"
						register={register}
						error={errors.pickupDate?.message}
					/>
				</div>
				<div className={`col-md-12 col-lg-6 p-1 ${errors.pickupTime?.message ? 'invalid-form' : ''}`}>
					<TextInputComponent
						id="pickupTime"
						label="Godzina odbioru"
						type="time"
						placeholder="Godzina odbioru"
						register={register}
						error={errors.pickupTime?.message}
					/>
				</div>
			</div>
			<div className="d-flex align-items-end p-0">
				<div className={`col-md-12 col-lg-6 p-1 ${errors.price?.message ? 'invalid-form' : ''}`}>
					<TextInputComponent
						id="price"
						label="Proponowana cena"
						type="text"
						placeholder="Proponowana cena"
						register={register}
						error={errors.price?.message}
					/>
				</div>
			</div>
			<div className="d-flex align-items-end p-0">
				<div className={`col-sm-12 p-1 ${errors.description?.message ? 'invalid-form' : ''}`}>
					<TextareaComponent
						id="description"
						label="Dodatkowe"
						placeholder="Wpisz komentarz"
						register={register}
						error={errors.description?.message}
						rows={2}
						cols={4}
					/>
				</div>
			</div>
			<ButtonComponent
				text="Złóż ofertę"
				type="submit"
				className="btn btn-primary"
				isLoading={isLoading}
				additional={{ 'data-bs-toggle': 'modal', 'data-bs-target': '#modal-offer-confirm' }}
			/>
		</form>
	);
};

const CarCarrierDetails: React.FC<ReporBoardFormComponentProps> = ({
	report,
	onSidebarClose,
}: ReporBoardFormComponentProps) => (
	<div className="lower-form">
		<h4 className="text-white bold mb-0">Kontakt z kierowcą</h4>
		<hr className="light my-3" />
		<div className="text-white">Skontaktuj się z kierowcą i omów szczegóły usługi!</div>
		<div className="d-flex align-items-end p-0">
			<div className="col-sm-12 p-1 d-flex flex-wrap">
				<label className="text-black">Imię i nazwisko</label>
				<div className="text-white txt-solid">{_.get(report, 'creator.name', '-')}</div>
			</div>
		</div>
		<div className="d-flex align-items-end p-0">
			<div className="col-sm-12 p-1 d-flex flex-wrap">
				<label className="text-black">Numer telefonu</label>
				<div className="text-white txt-solid">{_.get(report, 'creator.phone', '-')}</div>
			</div>
		</div>
		<button type="button" className="btn btn-primary" onClick={() => onSidebarClose()}>
			Zamknij
		</button>
	</div>
);

const OfferDetails: React.FC<ReporBoardFormComponentProps> = ({
	report,
	onSidebarClose,
}: ReporBoardFormComponentProps) => {
	const [changeOfferStatus] = useChangeOfferStatusMutation();
	const { addToast } = useToasts();

	const handleCompleteOffer = async () => {
		if (report?._id && report.companyOffer?._id) {
			const data: ChangeOfferStatusRequest = {
				reportId: report._id,
				offerId: report.companyOffer._id,
				status: 'completed',
			};

			try {
				await changeOfferStatus(data).unwrap();
				window.location.reload();
			} catch (err) {
				addToast('Wystąpił błąd');
			}
		}
	};
	return (
		<div className="lower-form">
			<h4 className="text-white bold mb-0">Twoja oferta</h4>
			<div className="d-flex align-items-end p-0">
				<div className="col-sm-12 p-1 d-flex flex-wrap">
					<label className="text-black">Termin rozpoczęcia usługi</label>
					<div className="text-white txt-solid">
						{moment(report?.companyOffer?.date).format('DD.MM.YYYY')}
					</div>
					<div className="separator">
						<img src={separatorIcon} alt="separator" />
					</div>
					<div className="text-white txt-solid">{moment(report?.companyOffer?.date).format('HH:mm')}</div>
				</div>
			</div>
			<div className="d-flex align-items-end flex-wrap p-0">
				<div className="col-sm-12 p-1 d-flex flex-wrap">
					<label className="text-black">Termin odbioru</label>
					<div className="text-white txt-solid">07.01.2022</div>
					<div className="separator">
						<img src={separatorIcon} alt="separator" />
					</div>
					<div className="text-white txt-solid">16:00</div>
				</div>
			</div>
			<div className="d-flex align-items-end p-0">
				<div className="col-md-12 col-lg-6 p-1">
					<label className="text-black">Proponowana cena</label>
					<div className="text-white txt-solid">{_.get(report, 'companyOffer.price', '-')}</div>
				</div>
			</div>
			<div className="d-flex align-items-end p-0">
				<div className="col-sm-12 p-1">
					<label className="text-black">Dodatkowe</label>
					<div className="text-white txt-solid comment">{_.get(report, 'companyOffer.description', '-')}</div>
				</div>
			</div>
			<button type="button" className="btn btn-primary" onClick={() => onSidebarClose()}>
				Zamknij
			</button>
			{report?.companyOffer?.status === 'accepted' && (
				<button type="button" className="btn btn-primary ml-2" onClick={() => handleCompleteOffer()}>
					Zakończ zlecenie
				</button>
			)}
		</div>
	);
};

const ReporBoardForm: React.FC<ReporBoardFormComponentProps> = ({
	report,
	onSidebarClose,
}: ReporBoardFormComponentProps) => {
	if (!report) return null;
	if (report.companyOffer) {
		if (report.kind === ReportType.CAR_CARRIER) {
			return <CarCarrierDetails report={report} onSidebarClose={onSidebarClose} />;
		}
		return <OfferDetails report={report} onSidebarClose={onSidebarClose} />;
	}
	return <OfferForm report={report} onSidebarClose={onSidebarClose} />;
};

export default ReporBoardForm;
