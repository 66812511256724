import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'app/hooks';
import _ from 'lodash';
import languages from 'assets/dicts/languages.dict.json';
import { handleApiErrors } from 'app/utils';
import ButtonComponent from 'components/Button.component';
import TextInputComponent from 'components/TextInput.component';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useCreateUserMutation, useUpdateUserMutation } from 'services/application.service';
import { userEditSchema, userSchema } from './registerForm.schema';
import AvatarCropper from './AvatarCropper.component';
import resizeImage from '../../features/auth/resizeImage';
import { defaultAvatar } from '../../assets/images/default-avatar';

interface RegisterFormUserValues {
	email: string;
	password?: string;
	password2?: string;
	name: string;
	phone: string;
	languages: string[];
	avatar: string;
}

const RegisterFormUser: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const { addToast } = useToasts();
	const [registerUser, { isLoading: isRegisterLoading }] = useCreateUserMutation();
	const [updateUser, { isLoading: isUpdateLoading }] = useUpdateUserMutation();
	const [isEdit, setIsEdit] = useState(false);
	const user = useAppSelector(state => state.auth.user);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (location && location.pathname === '/profile') {
			setIsEdit(true);
		}
	}, [location]);

	const {
		register,
		handleSubmit,
		setError,
		reset,
		formState: { errors },
		getValues,
		setValue,
		trigger,
	} = useForm<RegisterFormUserValues>({
		mode: 'onSubmit',
		defaultValues: user || {},
		resolver: yupResolver(isEdit ? userEditSchema : userSchema),
		reValidateMode: 'onChange',
	});

	useEffect(() => {
		if (user) reset(user);
	}, [user]);

	const handleSubmitRegisterUserForm = async (data: RegisterFormUserValues): Promise<void> => {
		try {
			if (isEdit) {
				if (data.password === '') {
					delete data.password;
					delete data.password2;
				}
				const user = await updateUser(data).unwrap();
				if (user.success) {
					addToast('Konto zostało zaktualizowane');
				}
			} else {
				const user = await registerUser(data).unwrap();
				if (user.success) {
					history.replace('/login');
					addToast('Konto zostało założone');
				}
			}
		} catch (err: any) {
			handleApiErrors(setError, err);
			addToast('Błąd autoryzacji');
		}
	};

	const handleSelectLanguage = (languageCode: string) => {
		const selectedLanguages = getValues('languages') || [];
		const languageIndex: number = _.findIndex(selectedLanguages, langCode => langCode === languageCode);

		if (languageIndex === -1) {
			selectedLanguages.push(languageCode);
		}

		setValue('languages' as any, selectedLanguages);
		trigger('languages' as any);
	};

	const handleRemoveLanguage = (languageCode: string) => {
		const selectedLanguages = getValues('languages')?.filter(lang => lang !== languageCode);

		setValue('languages' as any, selectedLanguages);
		trigger('languages' as any);
	};

	const getLanguage = (languageCode: string) => _.find(languages, lang => lang.value === languageCode);

	const handleAvatarCrop = (avatar: string | null) => {
		if (avatar)
			resizeImage(avatar, 150, 150).then((resizedImage: any) => {
				setValue('avatar' as any, resizedImage);
				trigger('avatar' as any);
				setShowModal(false);
			});
		else setShowModal(false);
	};

	const handleClickPrivacy = () => {
		window.location.href = 'https://auto-help24.pl/wp-content/uploads/2022/08/Polityka_Prywatnosci.pdf';
	};
	const handleClickTerms = () => {
		window.location.href = 'https://auto-help24.pl/wp-content/uploads/2022/08/Regulamin_serwisu.pdf';
	};

	return (
		<div id="box1" className="box register-form mt-0">
			<form onSubmit={handleSubmit(handleSubmitRegisterUserForm)}>
				{isEdit && (
					<div className="row d-flex align-items-end">
						<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 avatar-col">
							<img
								role="presentation"
								src={getValues('avatar') || defaultAvatar}
								className="avatar"
								alt=""
								onClick={() => setShowModal(prev => !prev)}
							/>
							{showModal && <AvatarCropper onCrop={handleAvatarCrop} />}
						</div>
					</div>
				)}
				<div className="row row-cols-2 d-flex align-items-end">
					<div className={`${errors.name?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
						<TextInputComponent
							id="name"
							label="Podstawowe dane"
							type="text"
							placeholder="Twoje imię i nazwisko"
							register={register}
							error={errors.name?.message}
						/>
					</div>
					<div
						className={`${
							(errors.languages as any)?.message && 'invalid-form'
						} col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-wrap`}
					>
						{getValues('languages') &&
							getValues('languages').map((languageCode: string) => (
								<div className="subcategory-list" key={`${languageCode}-language-selected-option`}>
									<div role="presentation" className="selected-option mr-3">
										<span> {getLanguage(languageCode)?.label}</span>
										<i
											className="fas fa-times ml-1"
											role="presentation"
											onClick={() => handleRemoveLanguage(languageCode)}
										/>
									</div>
								</div>
							))}
						<select onChange={(e: any) => handleSelectLanguage(e.target.value)} className="w-100">
							<option value="">Wybierz</option>
							{languages.map((language: { value: string; label: string }) => (
								<option value={language.value} key={language.value}>
									{language.label}
								</option>
							))}
						</select>
						{(errors.languages as any)?.message && (
							<div className="invalid-feedback">{(errors.languages as any)?.message}</div>
						)}
					</div>
				</div>

				<div className="row d-flex align-items-end">
					<div className={`${errors.email?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
						<TextInputComponent
							id="email"
							label="Dane kontaktowe"
							type="text"
							placeholder="Twój adres e-mail"
							register={register}
							error={errors.email?.message}
						/>
					</div>
					<div className={`${errors.phone?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
						<TextInputComponent
							id="phone"
							type="text"
							placeholder="Twój numer telefonu"
							register={register}
							error={errors.phone?.message}
						/>
					</div>
				</div>
				<div className="row d-flex align-items-end">
					<div
						className={`${errors.password?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}
					>
						<TextInputComponent
							id="password"
							label="Hasło"
							type="password"
							placeholder="Twoje hasło"
							register={register}
							error={errors.password?.message}
						/>
					</div>
					<div
						className={`${
							(errors.password2?.message || errors.password?.message) && 'invalid-form'
						} col-sm-12 col-md-6 col-lg-4 col-xl-4`}
					>
						<TextInputComponent
							id="password2"
							type="password"
							placeholder="Powtórz hasło"
							register={register}
							error={errors.password2?.message}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-12 col-md-6 col-xl-4 pt-3">
						<ButtonComponent
							type="submit"
							className="btn btn-submit btn-primary"
							text={isEdit ? 'Zapisz zmiany' : 'Załóż profil'}
							isLoading={isRegisterLoading || isUpdateLoading}
						/>
					</div>
				</div>

				<div className="row">
					<div
						className="col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-3"
						role="presentation"
						onClick={handleClickPrivacy}
						onKeyDown={handleClickPrivacy}
					>
						Polityka prywatności
					</div>
				</div>
				<div className="row">
					<div
						className="col-sm-12 col-md-6 col-lg-4 col-xl-4 "
						role="presentation"
						onClick={handleClickTerms}
						onKeyDown={handleClickTerms}
					>
						Regulamin serwisu
					</div>
				</div>
			</form>
		</div>
	);
};

export default RegisterFormUser;
