import markerUserIcon from 'assets/images/map-pin-you.svg';
import GoogleMapReact from 'google-map-react';
import React from 'react';

interface MapComponentProps {
	height?: number;
	lat?: number;
	lon?: number;
}

interface MarkerProps {
	lat: number;
	lng: number;
	handleMarkerClick?: any;
}

// eslint-disable-next-line
const Marker: React.FC<MarkerProps> = ({ lat, lng, handleMarkerClick }) => {
	return (
		<img
			src={markerUserIcon}
			alt="marker"
			role="presentation"
			onClick={handleMarkerClick}
			onKeyDown={handleMarkerClick}
		/>
	);
};

const MapComponent: React.FC<MapComponentProps> = ({ height, lat, lon }: MapComponentProps) => {
	const mapOptions = {
		styles: [
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [
					{
						color: '#e9e9e9',
					},
					{
						lightness: 17,
					},
				],
			},
			{
				featureType: 'landscape',
				elementType: 'geometry',
				stylers: [
					{
						color: '#f5f5f5',
					},
					{
						lightness: 20,
					},
				],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 17,
					},
				],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 29,
					},
					{
						weight: 0.2,
					},
				],
			},
			{
				featureType: 'road.arterial',
				elementType: 'geometry',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 18,
					},
				],
			},
			{
				featureType: 'road.local',
				elementType: 'geometry',
				stylers: [
					{
						color: '#ffffff',
					},
					{
						lightness: 16,
					},
				],
			},
			{
				featureType: 'poi',
				elementType: 'geometry',
				stylers: [
					{
						color: '#f5f5f5',
					},
					{
						lightness: 21,
					},
				],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [
					{
						color: '#dedede',
					},
					{
						lightness: 21,
					},
				],
			},
			{
				elementType: 'labels.text.stroke',
				stylers: [
					{
						visibility: 'on',
					},
					{
						color: '#ffffff',
					},
					{
						lightness: 16,
					},
				],
			},
			{
				elementType: 'labels.text.fill',
				stylers: [
					{
						saturation: 36,
					},
					{
						color: '#333333',
					},
					{
						lightness: 40,
					},
				],
			},
			{
				elementType: 'labels.icon',
				stylers: [
					{
						visibility: 'off',
					},
				],
			},
			{
				featureType: 'transit',
				elementType: 'geometry',
				stylers: [
					{
						color: '#f2f2f2',
					},
					{
						lightness: 19,
					},
				],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.fill',
				stylers: [
					{
						color: '#fefefe',
					},
					{
						lightness: 20,
					},
				],
			},
			{
				featureType: 'administrative',
				elementType: 'geometry.stroke',
				stylers: [
					{
						color: '#fefefe',
					},
					{
						lightness: 17,
					},
					{
						weight: 1.2,
					},
				],
			},
		],
	};

	return (
		<div className="map-embed" style={{ height: height || 225 }}>
			<GoogleMapReact
				bootstrapURLKeys={{ key: 'AIzaSyAMCcSiNNEEgshWIr17_H2Tln7Wgr7c1Zg' }}
				center={{
					lat: lat || 52.0693234,
					lng: lon || 19.4102734,
				}}
				defaultZoom={12}
				yesIWantToUseGoogleMapApiInternals
				options={mapOptions}
			>
				<Marker lat={lat || 52.0693234} lng={lon || 19.4102734} />
			</GoogleMapReact>
		</div>
	);
};

export default MapComponent;
