import React from 'react';

interface SelectComponentProps {
	id: string;
	placeholder?: string;
	label?: string;
	error?: string | undefined;
	register: any;
	options: { value: string; label: string }[];
	emptyValue?: string;
}

const SelectComponent: React.FC<SelectComponentProps> = ({
	id,
	placeholder,
	label,
	options,
	error,
	register,
	emptyValue,
}) => (
	<>
		{label && <label>{label}</label>}
		<select {...register(id)} placeholder={placeholder}>
			<option value={emptyValue || ''}>Wybierz</option>
			{options.map(option => (
				<option value={option.value} key={option.value}>
					{option.label}
				</option>
			))}
		</select>
		{error && <div className="invalid-feedback">{error}</div>}
	</>
);

export default SelectComponent;
