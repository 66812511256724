import React, { useState } from 'react';
import { User } from 'services/types';
import { useAppSelector } from 'app/hooks';
import Header from 'components/Header.component';
import RegisterFormUser from 'pages/auth/RegisterFormUser.component';
import RegisterFormOwner from 'pages/auth/RegisterFormOwner.component';
import RatingsModal from 'components/RatingsModal.component';
import { Rating } from 'react-simple-star-rating';
import ButtonComponent from 'components/Button.component';
import { useGetRatingsQuery } from 'services/report.service';
import _ from 'lodash';

const UserProfile: React.FC = () => {
	const user: User | null = useAppSelector(state => state.auth.user);
	const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);
	if (!user) return null;
	const { data: ratings } = useGetRatingsQuery({ id: user?._id });

	const handleOpenRatingsModal = () => {
		setIsRatingsModalOpen(true);
	};

	const calculateAverageScore = () => {
		if (ratings?.documents) {
			return parseInt(_.meanBy(ratings.documents, 'vote').toFixed(1), 10);
		}

		return 0;
	};

	const renderProperForm = () => {
		if (!user) return null;
		if (user.type === 'user') return <RegisterFormUser />;
		return <RegisterFormOwner />;
	};

	const renderReviews = () => {
		if (user?.type === 'company') {
			return (
				<div className="user-rating-wrapper">
					<h4 className="green bold mb-0">Średnia ocena</h4>
					<Rating ratingValue={calculateAverageScore() * 20} readonly />
					<div className="row">
						<div className="col-sm-12 col-md-6 col-xl-4 pt-3">
							<ButtonComponent
								type="submit"
								className="btn btn-submit btn-primary"
								text="Zobacz opinie"
								onClick={() => handleOpenRatingsModal()}
							/>
						</div>
					</div>
				</div>
			);
		}

		return null;
	};
	return (
		<>
			{ratings?.documents && (
				<RatingsModal
					onCancel={() => setIsRatingsModalOpen(false)}
					isOpen={isRatingsModalOpen}
					ratings={ratings?.documents}
				/>
			)}

			<Header />
			<div id="content" className="d-flex flex-wrap p-0">
				<div className="w100 profile-container d-flex flex-wrap flex-fill p-4">
					<div className="col-sm-12 col-md-2 col-lg-2 p-sm-0 p-md-5 text-right myborder-right light-bg">
						<h3 className="dual-heading">
							MÓJ<span>PROFIL</span>
						</h3>
					</div>
					<div className="col-sm-12 col-md-10 p-0 p-md-5">
						{renderReviews()}
						<h4 className="green bold mb-0 mt-2">Dane</h4>
						{renderProperForm()}
					</div>
				</div>
			</div>
		</>
	);
};

export default UserProfile;
