import React from 'react';

import overcloudLogo from 'assets/images/overcloud.svg';

interface FooterProps {
	showFootMenu?: boolean;
}

const Footer: React.FC<FooterProps> = () => (
	<footer className="container-fluid">
		<div className="row">
			<div className="left-footer col-md-6">
				<h3 className="bold">
					Dbanie o samochód
					<br />
					nigdy nie było
					<br />
					<span>tak proste.</span>
				</h3>
			</div>
			<div className="col-md-6 d-flex flex-column justify-content-end align-items-between">
				<div className="copyrights d-flex justify-content-between align-items-center">
					<div className="text-white">
						www.auto-help24.pl <span>©2022</span>
					</div>
					<div>
						<a href="https://over-cloud.pl" target="_blank" rel="noreferrer" className="text-white">
							realizacja <img src={overcloudLogo} alt="logo" />
						</a>{' '}
					</div>
				</div>
			</div>
		</div>
	</footer>
);

export default Footer;
