import { configureStore } from '@reduxjs/toolkit';
import authReducer from 'features/auth/authSlice';
import { usersApi } from 'services/admin.service';
import { appApi } from 'services/application.service';
import { garageApi } from 'services/garage.service';
import { locationApi } from 'services/location.service';
import { reportsApi } from 'services/report.service';

export const store = configureStore({
	reducer: {
		auth: authReducer,
		[appApi.reducerPath]: appApi.reducer,
		[locationApi.reducerPath]: locationApi.reducer,
		[reportsApi.reducerPath]: reportsApi.reducer,
		[garageApi.reducerPath]: garageApi.reducer,
		[usersApi.reducerPath]: usersApi.reducer
	},
	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(appApi.middleware).concat(reportsApi.middleware).concat(usersApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
