import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, parseListParams, ListParams } from 'app/api';
import { UserDocumentResponse, UsersListResponse, User } from './types';

const section = 'admin';

export const usersApi = createApi({
	baseQuery,
	reducerPath: 'usersApi',
	tagTypes: ['Users'],
	endpoints: builder => ({
		getUsers: builder.query<UsersListResponse, ListParams>({
			query: params => ({
				url: `${section}/${parseListParams('user', params)}`,
			}),
			providesTags: ['Users'],
		}),
		getUser: builder.query<UserDocumentResponse, string>({
			query: id => ({
				url: `${section}/user/${id}`,
			}),
			providesTags: ['Users'],
		}),
		updateUser: builder.mutation<UserDocumentResponse, Partial<User>>({
			query: data => ({
				url: `${section}/user/${data._id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Users'],
		}),
		verifyUser: builder.mutation<UserDocumentResponse, string>({
			query: _id => ({
				url: `${section}/verifyUser/${_id}`,
				method: 'PUT',
				body: { verified: true },
			}),
			invalidatesTags: ['Users'],
		}),
		deleteUser: builder.mutation<UserDocumentResponse, string>({
			query: _id => ({
				url: `${section}/user/${_id}`,
				method: 'DELETE',
				body: { active: false },
			}),
			invalidatesTags: ['Users'],
		}),
	}),
});

export const {
	useGetUsersQuery,
	useGetUserQuery,
	useLazyGetUserQuery,
	useLazyGetUsersQuery,
	useUpdateUserMutation,
	useDeleteUserMutation,
	useVerifyUserMutation,
} = usersApi;
