import React from 'react';
import _ from 'lodash';
import GoogleMapReact from 'google-map-react';
import { Coordinates } from 'services/types';
import markerUserIcon from 'assets/images/map-pin-you.svg';
import markerMechanicIcon from 'assets/images/map-pin-mechanic.svg';
import markerSosIcon from 'assets/images/map-pin-sos.svg';

interface MarkerProps {
	lat: number;
	lng: number;
	type: string;
	handleMarkerClick?: any;
}

interface SideMapProps {
	coordinates?: Coordinates[];
	userCoordinates?: Coordinates[];
	handleMarkerClick?: any;
}

const mapOptions = {
	styles: [
		{
			featureType: 'water',
			elementType: 'geometry',
			stylers: [
				{
					color: '#e9e9e9',
				},
				{
					lightness: 17,
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f5f5f5',
				},
				{
					lightness: 20,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					lightness: 17,
				},
			],
		},
		{
			featureType: 'road.highway',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					lightness: 29,
				},
				{
					weight: 0.2,
				},
			],
		},
		{
			featureType: 'road.arterial',
			elementType: 'geometry',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					lightness: 18,
				},
			],
		},
		{
			featureType: 'road.local',
			elementType: 'geometry',
			stylers: [
				{
					color: '#ffffff',
				},
				{
					lightness: 16,
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f5f5f5',
				},
				{
					lightness: 21,
				},
			],
		},
		{
			featureType: 'poi.park',
			elementType: 'geometry',
			stylers: [
				{
					color: '#dedede',
				},
				{
					lightness: 21,
				},
			],
		},
		{
			elementType: 'labels.text.stroke',
			stylers: [
				{
					visibility: 'on',
				},
				{
					color: '#ffffff',
				},
				{
					lightness: 16,
				},
			],
		},
		{
			elementType: 'labels.text.fill',
			stylers: [
				{
					saturation: 36,
				},
				{
					color: '#333333',
				},
				{
					lightness: 40,
				},
			],
		},
		{
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'geometry',
			stylers: [
				{
					color: '#f2f2f2',
				},
				{
					lightness: 19,
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.fill',
			stylers: [
				{
					color: '#fefefe',
				},
				{
					lightness: 20,
				},
			],
		},
		{
			featureType: 'administrative',
			elementType: 'geometry.stroke',
			stylers: [
				{
					color: '#fefefe',
				},
				{
					lightness: 17,
				},
				{
					weight: 1.2,
				},
			],
		},
	],
};

// eslint-disable-next-line
const Marker: React.FC<MarkerProps> = ({ lat, lng, type, handleMarkerClick }) => {
	const getIcon = () => {
		switch (type) {
			case 'user':
				return markerUserIcon;
			case 'mechanic':
				return markerMechanicIcon;
			case 'sos':
				return markerSosIcon;
			default:
				return markerMechanicIcon;
		}
	};

	return (
		<img
			src={getIcon()}
			alt="marker"
			role="presentation"
			onClick={handleMarkerClick}
			onKeyDown={handleMarkerClick}
		/>
	);
};

const SideMap: React.FC<SideMapProps> = ({ coordinates, userCoordinates, handleMarkerClick }) => {
	const renderWorkshopsCoordinates = () => {
		if (coordinates?.length) {
			return coordinates.map((position: Coordinates) => {
				if (position.offerId) {
					return (
						<Marker
							lat={position.lat}
							lng={position.lng}
							type="mechanic"
							key={_.uniqueId('marker')}
							handleMarkerClick={() => handleMarkerClick(position.offerId)}
						/>
					);
				}

				return <Marker lat={position.lat} lng={position.lng} type="mechanic" key={_.uniqueId('marker')} />;
			});
		}
		return null;
	};
	return userCoordinates ? (
		<GoogleMapReact
			bootstrapURLKeys={{ key: 'AIzaSyAMCcSiNNEEgshWIr17_H2Tln7Wgr7c1Zg' }}
			defaultCenter={{
				lat: _.get(userCoordinates, '[0]', 0),
				lng: _.get(userCoordinates, '[1]', 0),
			}}
			defaultZoom={11}
			yesIWantToUseGoogleMapApiInternals
			options={mapOptions}
		>
			<Marker lat={_.get(userCoordinates, '[0]', 0)} lng={_.get(userCoordinates, '[1]', 0)} type="user" />
			{renderWorkshopsCoordinates()}
		</GoogleMapReact>
	) : null;
};

export default SideMap;
