import React from 'react';

interface CheckboxComponentProps {
	id: string;
	label?: string;
	error?: string | undefined;
	register: any;
	disabled?: boolean;
	customClass?: string;
}

const CheckboxComponent: React.FC<CheckboxComponentProps> = ({ id, label, error, register, disabled, customClass }) => (
	<div className={customClass || 'checkbox col-sm-12 p-0'}>
		<label>
			<input type="checkbox" {...register(id)} id={id} disabled={disabled} />
			<span className="cr">
				<i className="cr-icon fa fa-square" />
			</span>
			{label}
		</label>
		{error && <div className="invalid-feedback">{error}</div>}
	</div>
);

export default CheckboxComponent;
