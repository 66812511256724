import React, { useState, useEffect } from 'react';
import Header from 'components/Header.component';
import whSygnetLogo from 'assets/images/logo-wh-sygnet.svg';
import { useLazyGetReportsQuery } from 'services/report.service';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router';
import MyReportsCard from './MyReportsCard.component';

const MyReports: React.FC = () => {
	const { search } = useLocation();
	const carId = new URLSearchParams(search).get('carId');
	const limit = 10;
	const [pageCount, setPageCount] = useState(1);
	const [offset, setOffset] = useState(0);
	const [getReports, { data }] = useLazyGetReportsQuery();

	useEffect(() => {
		if (data) {
			setPageCount(Math.ceil(_.get(data, 'total', 0) / limit));
		}
	}, [data]);

	useEffect(() => {
		if (carId) {
			getReports({ 'sort[offerAcceptedAt]': 1, ...{ limit, offset, 'car.carId': carId } });
		} else {
			getReports({ 'sort[offerAcceptedAt]': 1, ...{ limit, offset } });
		}
	}, [carId]);

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		const newOffset = (event.selected * limit) % _.get(data, 'total', 0);
		setOffset(newOffset);
		getReports({ 'sort[offerAcceptedAt]': 1, ...{ limit, offset } });
	};

	const renderCurrentReportsList = () => {
		if (data && !_.isEmpty(data.documents)) {
			return data.documents.map((report, index) => {
				const showSeparator = index > 0 && report.offerAcceptedAt && !data.documents[index - 1].offerAcceptedAt;
				return (
					<React.Fragment key={report._id}>
						{showSeparator && <hr className="darkgray mb-3" />}
						<MyReportsCard report={report} />
					</React.Fragment>
				);
			});
		}
		return (
			<div className="noresults text-center">
				<img src={whSygnetLogo} className="col-5 col-md-2 my-3" alt="no-elements" />
				<h5 className="pr-3 pl-0 my-3">Brak zgłoszeń.</h5>
			</div>
		);
	};

	return (
		<>
			<Header />
			<div id="content" className="d-flex flex-wrap p-0">
				<div className="w100 profile-container d-flex flex-wrap flex-fill p-4">
					<div className="col-sm-12 col-md-3 col-lg-2 p-sm-0 pr-md-3 text-right myborder-right light-bg">
						<h3 className="dual-heading mb-3 ">
							MOJE<span>ZGŁOSZENIA</span>
						</h3>
					</div>

					<div className="myvisits-container col-sm-12 col-md-9 col-lg-10 p-sm-0 pl-md-4 light-bg">
						{renderCurrentReportsList()}
						{pageCount > 1 && (
							<ReactPaginate
								nextLinkClassName="btn btn-outline-primary border-0"
								previousLinkClassName="btn btn-outline-primary border-0"
								pageLinkClassName="btn btn-outline-primary border-0"
								containerClassName="pagination mb-4"
								activeLinkClassName="active"
								breakLabel="..."
								nextLabel=">"
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								pageCount={pageCount}
								previousLabel="<"
								renderOnZeroPageCount={() => null}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default MyReports;
