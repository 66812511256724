import manufacturers from 'assets/dicts/manufacturer.dict.json';
import chassisTypes from 'assets/dicts/chassisType.dict.json';
import transmissionTypes from 'assets/dicts/transmission.dict.json';
import fuelTypes from 'assets/dicts/fuelType.dict.json';
import translations from 'assets/dicts/translations.dict.json';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/pl';
import { Address, PaymentType, ReportType, UserCar } from 'services/types';
import { useAppSelector } from './hooks';

export const handleApiErrors = (setError: any, err: any) => {
	if (err.data && err.data.errors) {
		for (const [key, value] of Object.entries(err.data.errors)) {
			setError(key as any, { message: _.get(translations, `${value}`, value) as string });
		}
	}
};
export const getChassisTypeClass = (chassisType: string) => {
	switch (chassisType) {
		case 'sedan':
		case 'coupe':
			return 'sedan';
		case 'hatchback':
			return 'hatchback';
		case 'estate':
		case 'suv':
			return 'kombi';
		case 'convertible':
			return 'cabrio';
		case 'minivan':
			return 'van';
		case 'pickup':
			return 'pickup';
		case 'other':
		default:
			return 'sedan';
	}
};
export const getManufacturerName = (manufacturer: string) => {
	const value = _.find(manufacturers, option => option.value === manufacturer);
	return value ? value.label : 'Inny';
};
export const getChassisTypeName = (chassisType: string) => {
	const value = _.find(chassisTypes, option => option.value === chassisType);
	return value ? value.label : 'Inny';
};
export const getFuelTypeName = (fuelType: string) => {
	const value = _.find(fuelTypes, option => option.value === fuelType);
	return value ? value.label : 'Inny';
};
export const getTransmissionName = (transmission: string) => {
	const value = _.find(transmissionTypes, option => option.value === transmission);
	return value ? value.label : 'Inny';
};

export const parseNestedErrors = (errors: Record<string, any>, fieldName: string): string => {
	const message: string[] = [];
	if (_.has(errors, fieldName)) {
		_.each(_.get(errors, fieldName, {}), value => {
			if (_.get(value, 'message')) {
				message.push(`${_.get(value, 'message', '')}`);
			} else if (_.isString(value)) {
				message.push(value);
			}
		});
	}
	return message.join('. ');
};

export const getReportKindName = (kind: string) => {
	switch (kind) {
		case ReportType.VISIT:
			return 'Wizyta';
		case ReportType.CAR_CARRIER:
			return 'Laweta';
		case ReportType.SOS:
			return 'Pomoc SOS';
		default:
			return 'Inny';
	}
};

export const getPaymentTypeName = (paymentType: string) => {
	if (paymentType === PaymentType.CASH) {
		return 'Gotówka';
	}
	if (paymentType === PaymentType.CONTACTLESS) {
		return 'Bezgotówkowa';
	}
	return 'Inna';
};

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const getFormattedAddress = (address: Partial<Address>) => {
	if (!address) return '-';
	if (address && !address.street && !address.houseNumber) return address.city;
	return `${address.street || ''} ${address.houseNumber || ''}, ${address.city || ''}`;
};

export const getServiceBreadCrumb = (service: string) => {
	const path = [];
	const dicts = useAppSelector(state => state.auth.serviceDictionary);
	if (!_.isEmpty(dicts)) {
		const baseDict = _.find(dicts, dict => dict._id === service);
		if (baseDict) {
			path.push(baseDict.name);
			let parent = baseDict.parent || null;
			while (parent) {
				const p = parent; // xd eslint pozdro
				const parentDict = _.find(dicts, dict => dict._id === p);
				if (parentDict) {
					path.unshift(parentDict.name);
					parent = parentDict.parent || null;
				} else {
					parent = null;
				}
			}
		}
	}
	return path;
};

export const calculateElapsedTime = (time: string) => {
	const now = moment(new Date());
	const start = moment(time);
	return start.from(now);
};

export const handleRedirectToMainRoute = (userType: string, history: any) => {
	switch (userType) {
		case 'user':
			history.push('/report');
			break;
		case 'company':
			history.push('/board');
			break;
		case 'admin':
			history.push('/admin');
			break;
		default:
			history.push('/profile');
	}
};

export const handleGoToMainWebsite = () => {
	window.location.href = 'https://auto-help24.pl/';
};

export const getFormattedCarInfo = (car: Partial<UserCar> | undefined) => {
	if (car && car.manufacturer && car.model && car.year) {
		return `${getManufacturerName(car.manufacturer || '')} ${car.model} ${car.year}`;
	}
	return null;
};
