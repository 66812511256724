import {
	getChassisTypeName,
	getFormattedAddress,
	getFuelTypeName,
	getManufacturerName,
	getPaymentTypeName,
	getServiceBreadCrumb,
	getTransmissionName,
} from 'app/utils';
import separatorIcon from 'assets/images/x-sep.svg';
import MapComponent from 'components/Map.component';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Report, ReportType } from 'services/types';

interface ReportBoardDetailsComponentProps {
	report: Partial<Report> | null;
}

const ReportBoardDetails: React.FC<ReportBoardDetailsComponentProps> = ({
	report,
}: ReportBoardDetailsComponentProps) => {
	if (!report) return null;

	const renderBreadcrumb = () => {
		const mainCategory = _.get(report, 'services[0]');
		if (mainCategory) {
			const breadCrumbArray = getServiceBreadCrumb(mainCategory);
			return (
				<div className="category-container col-sm-12 p-0 d-flex flex-wrap">
					<label className="w-100">Kategoria problemu</label>
					{breadCrumbArray[0] && <div className="cat-1level txt-solid">{breadCrumbArray[0]}</div>}
					{breadCrumbArray[1] && (
						<>
							<div className="separator">
								<img alt="separator" src={separatorIcon} />
							</div>
							<div className="cat-2level txt-solid">{breadCrumbArray[1]}</div>
						</>
					)}
					{breadCrumbArray[2] && (
						<>
							<div className="separator">
								<img alt="separator" src={separatorIcon} />
							</div>
							<div className="cat-2level txt-solid">{breadCrumbArray[2]}</div>
						</>
					)}
				</div>
			);
		}
		return (
			<div className="category-container d-flex">
				<div className="cat-1level txt-solid">-</div>
			</div>
		);
	};

	const renderDueDate = () => {
		switch (report.dueDate?.kind) {
			case 'range': {
				return (
					<div className="row">
						<div className="col-md-12 col-lg-6 px-0 d-flex flex-wrap">
							<label>Termin usługi</label>
							<div className="date-container d-flex flex-wrap justify-content-between">
								<div className="date-from txt-solid">
									{moment(report.dueDate?.value?.from).format('DD.MM.YYYY')}
								</div>
								<div className="separator">
									<img alt="separator" src={separatorIcon} />
								</div>
								<div className="date-to txt-solid">
									{moment(report.dueDate?.value?.to).format('DD.MM.YYYY')}
								</div>
							</div>
						</div>
						<div className="col-md-12 col-lg-6 px-0 d-flex flex-wrap">
							<label className="w-100">Preferowane godziny</label>
							<div className="hour-container d-flex flex-wrap">
								<div className="date-from txt-solid">{report.dueDate?.hour?.from || '-'}</div>
								<div className="separator">
									<img alt="separator" src={separatorIcon} />
								</div>
								<div className="date-to txt-solid">{report.dueDate?.hour?.to || '-'}</div>
							</div>
						</div>
					</div>
				);
			}
			case 'asap':
			default:
				return (
					<div className="row">
						<div className="col-md-12 col-lg-6 px-0 d-flex flex-wrap">
							<label>Termin usługi</label>
							<div className="date-container d-flex flex-wrap justify-content-between">
								<div className="date-from txt-solid">Jak najszybciej</div>
							</div>
						</div>
						<div className="col-md-12 col-lg-6 px-0 d-flex flex-wrap">
							<label className="w-100">Preferowane godziny</label>
							<div className="hour-container d-flex flex-wrap">
								<div className="date-from txt-solid">Jak najszybciej</div>
							</div>
						</div>
					</div>
				);
		}
	};

	return (
		<>
			<div className="row">{report.kind === ReportType.VISIT && renderBreadcrumb()}</div>
			{report.kind !== ReportType.VISIT && (
				<div className="row">
					<div className="col-md-12 col-lg-6 px-0 d-flex flex-wrap">
						<label>Data dodania</label>
						<div className="date-container d-flex flex-wrap justify-content-between">
							<div className="date-from txt-solid">{moment(report.createdAt).fromNow()}</div>
						</div>
					</div>
					<div className="col-md-12 col-lg-6 p-0 d-flex flex-wrap ">
						<label>Płatność</label>
						<div className="txt-solid">{getPaymentTypeName(report.paymentType!)}</div>
					</div>
				</div>
			)}
			{report.kind === ReportType.VISIT && renderDueDate()}
			{report.kind !== ReportType.VISIT && (
				<div className="row">
					<div className="col-sm-12 px-0 d-flex flex-wrap">
						<label>Lokalizacja</label>
						<div className="txt-solid">{getFormattedAddress(report.address!)}</div>
						<div className="gps-container d-flex flex-wrap  w-100">
							<MapComponent
								lat={_.get(report, 'address.location.coordinates[0]')}
								lon={_.get(report, 'address.location.coordinates[1]')}
							/>
						</div>
					</div>
				</div>
			)}
			{report.deliveryAddress && (
				<div className="row">
					<div className="col-sm-12 px-0 d-flex flex-wrap">
						<label>Lokalizacja docelowa</label>
						<div className="txt-solid">{getFormattedAddress(report.deliveryAddress)}</div>
						<div className="gps-container d-flex flex-wrap  w-100">
							<MapComponent
								lat={_.get(report, 'deliveryAddress.location.coordinates[0]')}
								lon={_.get(report, 'deliveryAddress.location.coordinates[1]')}
							/>
						</div>
					</div>
				</div>
			)}
			{report.kind === ReportType.VISIT && (
				<div className="row">
					<div className="col-sm-4 px-0 d-flex flex-wrap">
						<label className="w-100">Płatność</label>
						<div className="txt-solid">{getPaymentTypeName(report.paymentType!)}</div>
					</div>
				</div>
			)}
			<div className="row">
				<div className="col-sm-12 p-0">
					<label className="green">Dodatkowe informacje</label>
					<p className="comment limited txt-solid">{report.description || '-'}</p>
				</div>
			</div>
			<hr className="dark" />
			<div className="row">
				<div className="car-container col-sm-12 p-0 d-flex flex-wrap">
					<label className="w-100">Samochód</label>
					{report.car?.manufacturer && (
						<div className="car-make txt-solid">{getManufacturerName(report.car.manufacturer)}</div>
					)}
					{report.car?.model && <div className="car-model txt-solid">{report.car.model}</div>}
					{report.car?.year && <div className="car-model txt-solid">{report.car.year}</div>}
				</div>
				<div className="car-container col-sm-12 p-0 d-flex flex-wrap">
					<label className="w-100">Dodatkowe</label>
					{report.car?.engine && <div className="car-cc txt-solid">{report.car.engine}</div>}
					{report.car?.fuelType && (
						<div className="car-petrol txt-solid">{getFuelTypeName(report.car.fuelType)}</div>
					)}
					{report.car?.chassisType && (
						<div className="car-body txt-solid">{getChassisTypeName(report.car.chassisType)}</div>
					)}
					{report.car?.transmission && (
						<div className="car-gearbox txt-solid">{getTransmissionName(report.car.transmission)}</div>
					)}
				</div>

			</div>
			<div className="row">
				<div className="car-container col-md-12 col-lg-6 p-0 d-flex flex-wrap">
					<label className="w-100">VIN</label>
					<div className="car-vin txt-solid ">5YJSA7E45KF301118</div>
				</div>
				<div className="car-container col-md-12 col-lg-6 p-0 d-flex flex-wrap">
					<label className="w-100">Ubezpieczenie</label>
					<div className="car-oc txt-solid">OC</div>
					<div className="car-ac txt-solid">AC</div>
					<div className="car-other txt-solid">Assistance</div>
				</div>
			</div>
		</>
	);
};

export default ReportBoardDetails;
