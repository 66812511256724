import logoWh from 'assets/images/logo-wh.svg';
import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useActivateAccountMutation } from 'services/application.service';

const ActivateAccount: React.FC = () => {
	const history = useHistory();
	const { token } = useParams<{ token: string }>();

	useEffect(() => {
		if (token) {
			handleActivateAccount();
		}
	}, [token]);

	const { addToast } = useToasts();
	const [activateAccount] = useActivateAccountMutation();

	const handleActivateAccount = async (): Promise<void> => {
		try {
			await activateAccount(token).unwrap();

			addToast('Konto zostało aktywowane');
			history.replace('/login');
		} catch (err) {
			addToast('Wystąpił błąd');
		}
	};

	return (
		<div id="content" className="d-flex flex-wrap p-0">
			<div className="login-cover bg-car2 w50">
				<div className="top-logo">
					<img src={logoWh} alt="logo" />
				</div>
			</div>
			<div className="autohelp-form w50 justify-content-start">
				<div className="info-bar">
					<span>Nie masz konta?</span>
					<Link to="/register">
						<div>Zarejestruj się!</div>
					</Link>
				</div>
				<div className="form-container">
					<h1 className="p-5 bold">
						Dbanie o samochód
						<br /> nigdy nie było <br />
						<span>tak proste.</span>
					</h1>
				</div>
			</div>
		</div>
	);
};

export default ActivateAccount;
