import { yupResolver } from '@hookform/resolvers/yup';
import { handleGoToMainWebsite, handleRedirectToMainRoute } from 'app/utils';
import FacebookLogin from '@greatsumini/react-facebook-login';
import GoogleLogin, { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import { gapi } from 'gapi-script';
import logoWh from 'assets/images/logo-wh.svg';
import ButtonComponent from 'components/Button.component';
import TextInputComponent from 'components/TextInput.component';
import { setCredentials } from 'features/auth/authSlice';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { User } from 'services/types';
import { useLoginMutation, useSocialLoginMutation } from 'services/application.service';
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from 'app/keys';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import schema from './loginForm.schema';

interface LoginFormValues {
	email: string;
	password: string;
}

const LoginForm: React.FC = () => {
	const history = useHistory();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginFormValues>({
		resolver: yupResolver(schema),
		reValidateMode: 'onChange',
	});
	const { addToast } = useToasts();
	const dispatch = useAppDispatch();
	const [login, { isLoading: isLoginLoading }] = useLoginMutation();
	const [socialLogin] = useSocialLoginMutation();

	const user: User | null = useAppSelector(state => state.auth.user);

	useEffect(() => {
		if (user && user.type) {
			handleRedirectToMainRoute(_.get(user, 'type'), history);
		}
	}, [user]);

	const handleSubmitLoginForm = async (data: LoginFormValues): Promise<void> => {
		try {
			const user = await login({
				email: data.email,
				password: data.password,
			}).unwrap();

			dispatch(setCredentials(user));
			handleRedirectToMainRoute(_.get(user, 'type'), history);
		} catch (err: any) {
			if (err.data?.errors?.error === 'user-not-verified') {
				addToast('Trwa weryfikacja konta');
			} else {
				addToast('Błąd autoryzacji');
			}
		}
	};

	const isGoogleLoginResponse = (object: any): object is GoogleLoginResponse => object;

	const onSuccessGoogle = async (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
		if (isGoogleLoginResponse(res)) {
			if (res.profileObj) {
				const user = await socialLogin(res.profileObj).unwrap();
				if (user.success) {
					dispatch(setCredentials(user));
					handleRedirectToMainRoute(_.get(user, 'type'), history);
				}
			}
		}
	};
	const onFailGoogle = (response: any) => {
		console.error(response);
	};

	useEffect(() => {
		const initClient = () => {
			gapi.auth2.init({
				clientId: GOOGLE_CLIENT_ID,
				scope: '',
			});
		};
		gapi.load('client:auth2', initClient);
	});

	const onSuccessFacebook = async (res: any) => {
		if (res.email && res.id && res.name) {
			const data = {
				email: res.email,
				name: res.name,
				fbId: res.id,
			};
			const user = await socialLogin(data).unwrap();
			if (user.success) {
				dispatch(setCredentials(user));
				handleRedirectToMainRoute(_.get(user, 'type'), history);
			}
		}
	};
	const onFailFacebook = (response: any) => {
		console.error(response);
	};

	return (
		<div id="content" className="d-flex flex-wrap p-0">
			<div className="login-cover bg-car2 w50">
				<div
					className="top-logo"
					onClick={handleGoToMainWebsite}
					onKeyPress={handleGoToMainWebsite}
					role="presentation"
				>
					<img src={logoWh} alt="logo" />
				</div>
			</div>
			<div className="autohelp-form w50">
				<div className="info-bar">
					<span>Nie masz konta?</span>
					<Link to="/register">
						<div>Zarejestruj się!</div>
					</Link>
				</div>
				<div className="form-container">
					<h1 className="p-5 bold">
						Dbanie o samochód
						<br /> nigdy nie było <br />
						<span>tak proste.</span>
					</h1>

					<form onSubmit={handleSubmit(handleSubmitLoginForm)}>
						<div className="box login-form">
							<div className="row">
								<div
									className={`${
										errors.email?.message && 'invalid-form'
									} col-sm-12 col-md-6 col-lg-4 col-xl-3`}
								>
									<TextInputComponent
										id="email"
										label="Adres e-mail"
										type="email"
										placeholder="Twój adres e-mail"
										register={register}
										error={errors.email?.message}
									/>
								</div>
							</div>
							<div className="row">
								<div
									className={`${
										errors.password?.message && 'invalid-form'
									} col-sm-12 col-md-6 col-lg-4 col-xl-3`}
								>
									<TextInputComponent
										id="password"
										label="Hasło"
										type="password"
										placeholder="Twoje hasło"
										register={register}
										error={errors.password?.message}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12 col-md-6 col-xl-4 pt-3">
									<ButtonComponent
										type="submit"
										className="btn btn-submit btn-primary"
										text="Zaloguj się"
										isLoading={isLoginLoading}
									/>
									<Link to="/resetPassword">
										<div className="pass-reminder">Nie pamiętasz hasła?</div>
									</Link>
								</div>
							</div>
							<div className="row">
								<div className="col-sm-12 pt-3">
									<p>Jesteś klientem i potrzebujesz szybkiej pomocy? zaloguj się jedną z opcji</p>
									<GoogleLogin
										clientId={GOOGLE_CLIENT_ID}
										buttonText="Login"
										onSuccess={onSuccessGoogle}
										onFailure={onFailGoogle}
										cookiePolicy="single_host_origin"
										isSignedIn
										style={{ width: '100%' }}
									/>
									<FacebookLogin
										appId={FACEBOOK_APP_ID}
										onFail={error => {
											onFailFacebook(error);
										}}
										onProfileSuccess={response => {
											onSuccessFacebook(response);
										}}
										style={{
											backgroundColor: '#4267b2',
											color: '#fff',
											fontSize: '16px',
											padding: '10px 22px',
											border: 'none',
											borderRadius: '4px',
											marginLeft: '16px',
										}}
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default LoginForm;
