import React, { useState } from 'react';
import _ from 'lodash';
import separator from 'assets/images/x-sep.svg';
import { Offer, Rating as RatingType } from 'services/types';
import moment from 'moment';
import MapComponent from 'components/Map.component';
import ConfirmModalComponent from 'components/ConfirmModal.component';
import RatingModal from 'components/RatingModal.component';
import { Rating } from 'react-simple-star-rating';
import RatingsModal from 'components/RatingsModal.component';
import { useGetRatingsQuery, useRateUserMutation } from 'services/report.service';
import { useAppSelector } from 'app/hooks';

interface MyReportOfferDetailsProps {
	selectedOffer: Offer | null;
	handleAcceptRejectOffer: (decision: string) => void;
	isOfferAccepted: boolean;
}

const MyReportOfferDetails: React.FC<MyReportOfferDetailsProps> = ({
	selectedOffer,
	handleAcceptRejectOffer,
	isOfferAccepted,
}) => {
	if (!selectedOffer) return null;

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
	const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
	const [isRatingsModalOpen, setIsRatingsModalOpen] = useState(false);

	const [rateUser, {data: rateUserData}] = useRateUserMutation();
	const { data: ratings } = useGetRatingsQuery({ id: selectedOffer.creator._id });

	const user = useAppSelector(state => state.auth.user);

	const renderAddress = () =>
		`${_.get(selectedOffer, 'creator.address.street')} ${_.get(
			selectedOffer,
			'creator.address.houseNumber',
		)}, ${_.get(selectedOffer, 'creator.address.city')} ${_.get(selectedOffer, 'creator.address.postCode')}`;

	const rejectOffer = () => {
		handleAcceptRejectOffer('rejected');
		setIsDeleteModalOpen(false);
	};
	const acceptOffer = () => {
		handleAcceptRejectOffer('accepted');
		setIsConfirmModalOpen(false);
	};

	const handleOpenRatingModal = () => {
		setIsRatingModalOpen(true);
	};
	const handleOpenRatingsModal = () => {
		setIsRatingsModalOpen(true);
	};

	const handleRateOffer = async (vote: number, description?: string) => {
		if (user) {
			const data: Partial<RatingType> = {
				vote,
				description,
				userName: user?.name,
				user: selectedOffer.creator._id,
				offerId: selectedOffer._id,
			};
			await rateUser(data);
		}

		setIsRatingModalOpen(false);
	};

	return (
		<>
			<ConfirmModalComponent
				onCancel={() => setIsConfirmModalOpen(false)}
				onConfirm={() => acceptOffer()}
				text="Czy napewno chcesz zaakceptować ofertę?"
				isOpen={isConfirmModalOpen}
			/>
			<ConfirmModalComponent
				onCancel={() => setIsDeleteModalOpen(false)}
				onConfirm={() => rejectOffer()}
				text="Czy napewno chcesz odrzucić ofertę?"
				isOpen={isDeleteModalOpen}
			/>
			<RatingModal
				onCancel={() => setIsRatingModalOpen(false)}
				onConfirm={handleRateOffer}
				isOpen={isRatingModalOpen}
			/>

			{ratings?.documents && (
				<RatingsModal
					onCancel={() => setIsRatingsModalOpen(false)}
					isOpen={isRatingsModalOpen}
					ratings={ratings.documents}
				/>
			)}

			<h4 className="text-white bold mb-0">Oferta</h4>
			<div className="details col-sm-12">
				<div className="row">
					<div className="datefrom-container col-sm-12 p-0 d-flex flex-wrap ">
						<label className="w-100">Data usługi</label>
						<div className="date-from txt-solid">
							{moment(_.get(selectedOffer, 'date')).format('DD.MM.YYYY')}
						</div>
						<div className="separator">
							<img src={separator} alt="separator" />
						</div>
						<div className="date-to txt-solid">{moment(_.get(selectedOffer, 'date')).format('HH:mm')}</div>
					</div>
				</div>
				<div className="row">
					<div className="price-container col-sm-12 p-0 d-flex flex-wrap ">
						<label className="w-100">Proponowana cena</label>
						<div className="price txt-solid bigger">{_.get(selectedOffer, 'price')}</div>
					</div>
				</div>
				<div className="row">
					<div className="comment-container col-sm-12 p-0 d-flex flex-wrap ">
						<label className="w-100">Dodatkowe informacje</label>
						<div className="comment limited txt-solid ">{_.get(selectedOffer, 'description')}</div>
					</div>
				</div>
				{selectedOffer.averageRating && (
					<div className="row">
						<div className="comment-container col-sm-12 p-0 d-flex flex-wrap ">
							<label className="w-100">Ocena warsztatu</label>
							<Rating ratingValue={parseInt(selectedOffer.averageRating, 10) * 20} readonly />
						</div>
						<div
							className="col-sm-12 pl-0 py-0 m-0"
							role="presentation"
							onClick={() => handleOpenRatingsModal()}
							onKeyDown={() => handleOpenRatingsModal()}
						>
							<div
								style={{ border: '3px solid white' }}
								className="green-footer mt-2 align-self-stretch d-flex flex-wrap justify-content-center align-items-center text-center px-4 py-2"
							>
								Zobacz opinie
							</div>
						</div>
					</div>
				)}

				<div className="row">
					<div className="col-sm-12 px-0 d-flex flex-wrap">
						<label>Adres warsztatu</label>
						<div className="txt-solid mb-3">{renderAddress()}</div>
						<div className="gps-container d-flex flex-wrap  w-100">
							<MapComponent
								lat={_.get(selectedOffer, 'creator.address.location.coordinates[0]')}
								lon={_.get(selectedOffer, 'creator.address.location.coordinates[1]')}
							/>
						</div>
					</div>

					{!isOfferAccepted && selectedOffer.status !== 'completed' ? (
						<div className="row  mx-0 mt-3 p-0">
							<div className="col-sm-12 accept-reject-btns px-0 d-flex flex-wrap justify-content-between">
								<div className="col-sm-6 pl-md-0">
									<button
										type="button"
										className="w-100 p-0 btn btn-white-accept"
										data-bs-toggle="modal"
										data-bs-target="#modal-offer-accept"
										onClick={() => setIsConfirmModalOpen(true)}
									>
										Akceptuj
									</button>
								</div>
								<div className="col-sm-6 pr-md-0">
									<button
										type="button"
										className="w-100 p-0 btn btn-white-reject"
										data-bs-toggle="modal"
										data-bs-target="#modal-offer-reject"
										onClick={() => setIsDeleteModalOpen(true)}
									>
										Odrzuć
									</button>
								</div>
							</div>
						</div>
					) : (
						<div className="row  mx-0 mt-3 p-0">W tym zgłoszeniu wybrałeś już ofertę</div>
					)}
				</div>
				{selectedOffer.status === 'completed' && !selectedOffer.rating && !rateUserData && (
					<div className="row">
						<div
							className="col-sm-12 pl-0 py-0 m-0"
							role="presentation"
							onClick={() => handleOpenRatingModal()}
							onKeyDown={() => handleOpenRatingModal()}
						>
							<div
								style={{ border: '3px solid white' }}
								className="green-footer mt-2 align-self-stretch d-flex flex-wrap justify-content-center align-items-center text-center px-4 py-2"
							>
								Oceń usługę
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default MyReportOfferDetails;
