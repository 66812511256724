import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { User, ServiceDict } from 'services/types';
import type { RootState } from '../../app/store';

type AuthState = {
    user: User | null;
    token: string | null;
    serviceDictionary: ServiceDict[] | null;
};

const saveAuthToken = (token: string | null): void => {
    if (token) {
        localStorage.setItem('token', token);
    } else {
        localStorage.removeItem('token');
    }
};

const initialState: AuthState = {
    user: null,
    token: null,
    serviceDictionary: null
};

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, { payload: { user, token, serviceDictionary } }: PayloadAction<AuthState>) => {
            saveAuthToken(token);
            state.user = user;
            state.token = token;
            state.serviceDictionary = serviceDictionary;
        },
    },
});

export const { setCredentials } = slice.actions;

export const selectCurrentUser = (state: RootState) => state.auth.user;

export default slice.reducer;
