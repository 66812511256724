import { calculateElapsedTime } from 'app/utils';
import mailIcon from 'assets/images/ico-mail.svg';
import mapIcon from 'assets/images/ico-map.svg';
import phoneIcon from 'assets/images/ico-phone.svg';
import mechanicIcon from 'assets/images/ico-table-mechanic.svg';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import * as Scroll from 'react-scroll';
import { Offer } from 'services/types';

interface MyReportOfferCardProps {
	offer: Offer;
	setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedOffer: React.Dispatch<React.SetStateAction<Offer | null>>;
	offerActive: boolean;
}

const MyReportOfferCard: React.FC<MyReportOfferCardProps> = ({
	offer,
	setSidebarOpen,
	setSelectedOffer,
	offerActive,
}) => {
	const handleOpenOfferDetails = () => {
		// select offer
		setSelectedOffer(offer);
		// open sidebar
		setSidebarOpen(true);
	};

	const renderAddress = () =>
		`${_.get(offer, 'creator.address.street')} ${_.get(offer, 'creator.address.houseNumber')}, ${_.get(
			offer,
			'creator.address.city',
		)} ${_.get(offer, 'creator.address.postCode')}`;

	const offerStatusStyle = () => {
		switch (offer.status) {
			case 'accepted':
				return 'accepted';
			case 'rejected':
				return 'other-is-accepted';
			case 'waiting':
				return '';
			default:
				return '';
		}
	};

	return (
		<Scroll.Element name={offer._id}>
			<div
				className={`offer ${offerStatusStyle()} ${
					offerActive && 'offer-selected'
				}  pt-3 pr-0 pb-3 pl-3 mb-4 d-flex flex-wrap justify-content-between`}
			>
				<div className="row col-sm-12 col-md-6 d-flex align-content-start flex-wrap">
					<div>
						<img src={mechanicIcon} alt="icon" className="mechanic float-left" />
						<h5 className="mb-0">{_.get(offer, 'creator.companyName', '-')}</h5>
						<p className="mb-3 limited">{_.get(offer, 'creator.description', '-')}</p>
					</div>
					<div id="contact-container" className="d-flex flex-wrap">
						<div className="address col-sm-12 p-0">
							<img src={mapIcon} alt="icon" />
							{renderAddress()}
						</div>
						<div className="phone col-sm-12 p-0">
							<img src={phoneIcon} alt="icon" />
							<a href={`tel:${_.get(offer, 'creator.phone', '#')}`}>
								{_.get(offer, 'creator.phone', '-')}
							</a>
						</div>
						<div className="mail col-sm-12 p-0">
							<img src={mailIcon} alt="icon" />
							<a href={`mailto:${_.get(offer, 'creator.mail', '#')}`}>
								{_.get(offer, 'creator.mail', '-')}
							</a>
						</div>
						<div className="extras myborder-top pt-2 col-sm-12 p-0 d-flex flex-wrap gap-2">
							{_.get(offer, 'creator.cardPayments', null) && (
								<div>
									<i className="fas fa-check mr-1 pr-1" />
									Płatność kartą
								</div>
							)}
							{_.get(offer, 'creator.carCarrier', null) && (
								<div>
									<i className="fas fa-check mr-1 pr-1" />
									Transport lawetą
								</div>
							)}
							{_.get(offer, 'creator.mobileRepairs', null) && (
								<div>
									<i className="fas fa-check mr-1 pr-1" />
									Naprawy z dojazdem
								</div>
							)}
							{_.get(offer, 'creator.offersInvoice', null) && (
								<div>
									<i className="fas fa-check mr-1 pr-1" />
									Faktura VAT
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="row col-sm-12 col-md-6 myborder-left d-flex flex-wrap justify-content-end">
					<h3 className="green mb-2">Oferta</h3>
					<div className="offering-container col-12 d-flex flex-wrap align-content-start">
						<label className="green">Data usługi</label>
						<div className="date-from txt-solid">{moment(_.get(offer, 'date')).format('DD.MM.YYYY')}</div>
						<div className="time-from txt-solid">{moment(_.get(offer, 'date')).format('HH:mm')}</div>
					</div>
					<div className="break" />
					<div className="offering-container col-12 d-flex flex-wrap align-content-start">
						<label className="green">Uwagi</label>
						<div className="txt-solid comment">{_.get(offer, 'description', '-')}</div>
					</div>
					<div className="offering-container col-12 d-flex flex-wrap align-content-start">
						<label className="green">Proponowana cena</label>
						<div className="txt-solid bigger">{_.get(offer, 'price', '-')} zł</div>
					</div>
					<div className="div-cornertop subheading">
						{calculateElapsedTime(_.get(offer, 'createdAt', '-'))}
					</div>
					<button
						type="button"
						className="btn btn-primary btn-corner"
						id="sidebarCollapse"
						onClick={handleOpenOfferDetails}
					>
						Zobacz szczegóły
					</button>
				</div>
			</div>
		</Scroll.Element>
	);
};

export default MyReportOfferCard;
