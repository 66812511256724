import { useAppSelector } from 'app/hooks';
import React, { useState } from 'react';
import { User, UserCar } from 'services/types';
import btnCarIcon from 'assets/images/btn-car.svg';
import manufacturers from 'assets/dicts/manufacturer.dict.json';
import { getManufacturerName } from 'app/utils';

interface CarSelectComponentProps {
	onCarSelect: (car: UserCar) => void;
	onManufacturerSelect: (manufacturer: string) => void;
}

const CarSelectComponent: React.FC<CarSelectComponentProps> = ({ onCarSelect, onManufacturerSelect }) => {
	const user: User | null = useAppSelector(state => state.auth.user);
	const [showOptions, setShowOptions] = useState(false);
	const [text, setText] = useState('');

	const handleManufacturerSelect = (manufacturer: string) => {
		onManufacturerSelect(manufacturer);
		setText(getManufacturerName(manufacturer));
		setShowOptions(false);
	};

	const handleCarSelect = (car: UserCar) => {
		onCarSelect(car);
		setText(`${getManufacturerName(car.manufacturer)} ${car.model} ${car.year}`);
		setShowOptions(false);
	};

	const userCars = user?.cars?.map(car => (
		<li
			className="txt-solid green"
			key={car._id}
			onClick={() => handleCarSelect(car)}
			onKeyPress={() => handleCarSelect(car)}
			role="presentation"
		>
			<img src={btnCarIcon} alt="car" className="mr-2" style={{ color: 'green' }} />
			{`${getManufacturerName(car.manufacturer)} ${car.model} ${car.year}`};
		</li>
	));

	return (
		<div className="input-group">
			<label className="text-black">Wybierz samochód</label>
			<div
				className="form-select pt-2"
				onClick={() => setShowOptions(!showOptions)}
				onKeyPress={() => setShowOptions(!showOptions)}
				role="presentation"
			>
				{text || 'Wybierz'}
			</div>
			{showOptions && (
				<div className="ah-select">
					{userCars && <ul>{userCars}</ul>}
					<hr className="darkgray m-0" />
					<hr className="darkgray m-0" />
					<ul>
						{manufacturers.map(manufacturer => (
							<li
								key={manufacturer.value}
								onClick={() => handleManufacturerSelect(manufacturer.value)}
								onKeyPress={() => handleManufacturerSelect(manufacturer.value)}
								role="presentation"
							>
								{manufacturer.label}
							</li>
						))}
					</ul>
				</div>
			)}
		</div>
	);
};

export default CarSelectComponent;
