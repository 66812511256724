import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export interface ListParams {
    [field: string]: string | number | string[];
}

// export const baseUrl = 'http://localhost:3001/';
export const baseUrl = 'https://api-help24.overcloud.usermd.net/';

export const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: headers => {
        const token = getAuthToken();
        if (token) {
            headers.set('authorization', `JWT:${token}`);
        }
        return headers;
    },
});

export const getAuthToken = (): string | null => localStorage.getItem('token');

export const parseListParams = (url: string, params: ListParams): string => {
    const parsed: string[] = [];
    Object.keys(params).forEach((param: string) => {
        parsed.push(`${param}=${params[param]}`);
    });
    return parsed.length > 0 ? `${url}?${parsed.join('&')}` : url;
};
