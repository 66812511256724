import logoWh from 'assets/images/logo-wh.svg';
import React from 'react';

const NoPage: React.FC = () => (
	<div id="content" className="d-flex flex-wrap p-0">
		<div className="login-cover bg-car2 w50">
			<div className="top-logo">
				<img src={logoWh} alt="logo" />
			</div>
		</div>
		<div className="autohelp-form w50" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
			<h2>Taka strona nie istnieje.</h2>
		</div>
	</div>
);

export default NoPage;
