import * as yup from 'yup';

export default yup
        .object({
                report: yup.string().required("Pole jest wymagane"),
                description: yup.string(),
                price: yup.string().required("Pole jest wymagane"),
                date: yup.string().required("Pole jest wymagane"),
                hour: yup.string().required("Pole jest wymagane"),
                pickupDate: yup.string(),
                pickupTime: yup.string()
        })
        .required();
