import { IonApp } from '@ionic/react';
import plLocale from "assets/moment/pl";
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { store } from './app/store';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

moment.locale("pl", plLocale as any);

ReactDOM.render(
	<Provider store={store}>
		<IonApp>
			<Router>
				<App />
			</Router>
		</IonApp>
	</Provider>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
