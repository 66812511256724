import React, { useState, useEffect, useRef } from 'react';
import Header from 'components/Header.component';
import whSygnetLogo from 'assets/images/logo-wh-sygnet.svg';
import {
	useGetUsersQuery,
	useDeleteUserMutation,
	useUpdateUserMutation,
	useVerifyUserMutation,
} from 'services/admin.service';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import ConfirmModalComponent from 'components/ConfirmModal.component';
import { useToasts } from 'react-toast-notifications';
import { User } from 'services/types';
import UsersListCard from './UsersListCard.component';

const UsersList: React.FC = () => {
	const limit = 10;
	const [pageCount, setPageCount] = useState(1);
	const [offset, setOffset] = useState(0);
	const [listType, setListType] = useState(1);
	const paginationRef = useRef<any>(null);
	const { addToast } = useToasts();
	const [updateUser] = useUpdateUserMutation();
	const [deleteUser] = useDeleteUserMutation();
	const [verifyUser] = useVerifyUserMutation();

	const [updateModalOpen, setUpdateModalOpen] = useState(false);
	const [updateData, setUpdateData] = useState<Partial<User> | null>(null);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [deleteData, setDeleteData] = useState<Partial<User> | null>(null);

	const handleDeleteUser = async () => {
		try {
			if (deleteData?._id) {
				await deleteUser(deleteData._id!).unwrap();
				setDeleteModalOpen(false);
				addToast('Usunięto użytkownika');
			}
		} catch (err) {
			addToast('Błąd usuwania użytkownika');
		}
	};

	const handleChangeActive = async () => {
		try {
			if (updateData?._id) {
				await updateUser({ _id: updateData._id, active: !updateData.active }).unwrap();
				setUpdateModalOpen(false);
				addToast(`${updateData.active ? 'Zablokowano' : 'Odblokowano'} użytkownika`);
			}
		} catch (err) {
			addToast('Błąd usuwania użytkownika');
		}
	};

	const getListTypeQuery = () => {
		switch (listType) {
			case 1:
				return { 'type[in]': 'user,company' };
			case 2:
				return { 'type[in]': 'user' };
			case 3:
				return { 'type[in]': 'company' };
			default:
				return { 'type[in]': 'user,company' };
		}
	};

	const { data, refetch } = useGetUsersQuery({ 'sort[active]': 1, ...{ limit, offset }, ...getListTypeQuery() });

	useEffect(() => {
		if (data) {
			setPageCount(Math.ceil(_.get(data, 'total', 0) / limit));
		}
	}, [data]);

	const handlePageClick = (event: any) => {
		const newOffset = (event.selected * limit) % _.get(data, 'total', 0);
		setOffset(newOffset);
		refetch();
	};

	const handleChangeListType = (newType: number) => {
		setOffset(0);
		listType === newType ? setListType(1) : setListType(newType);
		if (paginationRef.current && _.isFunction(_.get(paginationRef, 'current.handlePageChange'))) {
			paginationRef.current.handlePageChange(0);
		}
	};

	const handleActivateUser = async (user: Partial<User>) => {
		try {
			if (user._id) {
				await verifyUser(user._id).unwrap();
			}
		} catch (err) {
			addToast('Błąd usuwania użytkownika');
		}
	};

	const renderCurrentUsersList = () => {
		if (data && !_.isEmpty(data.documents)) {
			return data.documents.map(user => (
				<UsersListCard
					user={user}
					key={user._id}
					onDeleteClick={handleOpenDeleteModal}
					onUpdateClick={handleOpenUpdateModal}
					onActivateClick={handleActivateUser}
				/>
			));
		}
		return (
			<div className="noresults text-center">
				<img src={whSygnetLogo} className="col-5 col-md-2 my-3" alt="no-elements" />
				<h5 className="pr-3 pl-0 my-3">Brak użytkowników.</h5>
			</div>
		);
	};

	const handleOpenUpdateModal = (userData: Partial<User>) => {
		setUpdateData(userData);
		setUpdateModalOpen(true);
	};

	const handleOpenDeleteModal = (userData: Partial<User>) => {
		setDeleteData(userData);
		setDeleteModalOpen(true);
	};

	const handleCloseUpdateModal = () => {
		setUpdateData(null);
		setUpdateModalOpen(false);
	};

	const handleCloseDeleteModal = () => {
		setDeleteData(null);
		setDeleteModalOpen(false);
	};

	return (
		<>
			<Header />
			<ConfirmModalComponent
				onCancel={handleCloseDeleteModal}
				onConfirm={handleDeleteUser}
				text="Czy napewno chcesz usunąć użytkownika?"
				isOpen={deleteModalOpen}
			/>
			<ConfirmModalComponent
				onCancel={handleCloseUpdateModal}
				onConfirm={handleChangeActive}
				text="Czy napewno chcesz wykonać operację?"
				isOpen={updateModalOpen}
			/>
			<div id="content" className="d-flex flex-wrap p-0">
				<div className="col-sm-12 filters px-3 pt-4 d-flex flex-wrap align-items-center">
					<div className="col-sm-12 col-md-4 col-lg-3 ">
						<h3 className="dual-heading border-0">
							LISTA<span>UŻYTKOWNIKÓW</span>
						</h3>
					</div>
					<div className="d-flex col-sm-12 col-md-8 col-lg-9 flex-wrap align-items-end p-0 collapse show">
						<div className="filter-group myborder-left p-0 w-100 d-flex flex-wrap px-md-3">
							<label>Filtry</label>
							<div className="checkbox mr-5 ">
								<label>
									<input
										type="checkbox"
										checked={listType === 2}
										onChange={() => handleChangeListType(2)}
									/>
									<span className="cr">
										<i className="cr-icon fa fa-square" />
									</span>
									Użytkownicy
								</label>
							</div>
							<div className="checkbox mr-5">
								<label>
									<input
										type="checkbox"
										checked={listType === 3}
										onChange={() => handleChangeListType(3)}
									/>
									<span className="cr">
										<i className="cr-icon fa fa-square" />
									</span>
									Usługodawcy
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="w100 users-container d-flex flex-column px-3">
					{renderCurrentUsersList()}
					{pageCount > 1 && (
						<ReactPaginate
							ref={paginationRef}
							nextLinkClassName="btn btn-outline-primary border-0"
							previousLinkClassName="btn btn-outline-primary border-0"
							pageLinkClassName="btn btn-outline-primary border-0"
							containerClassName="pagination mb-4"
							activeLinkClassName="active"
							breakLabel="..."
							nextLabel=">"
							onPageChange={handlePageClick}
							pageRangeDisplayed={3}
							pageCount={pageCount}
							previousLabel="<"
							renderOnZeroPageCount={() => null}
						/>
					)}
				</div>
			</div>
		</>
	);
};

export default UsersList;
