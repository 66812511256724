export default function resizeImage(imgToResize: any, width: number, height:number) {
    return new Promise((resolve) => {
		const img:HTMLImageElement = new Image()
		img.src = imgToResize
		img.onload = () => {
			const canvas:HTMLCanvasElement = document.createElement('canvas')
			canvas.width = width
			canvas.height = height

			const ctx:CanvasRenderingContext2D|null = canvas.getContext('2d')
			ctx?.drawImage(img, 0, 0, width, height)
			resolve(canvas.toDataURL())
		}
	})
}