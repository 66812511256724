import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ConfirmModalComponent from 'components/ConfirmModal.component';
import { Report, ReportDueDateKindType } from 'services/types';
import _ from 'lodash';

import {
	getChassisTypeName,
	getFormattedAddress,
	getFuelTypeName,
	getManufacturerName,
	getPaymentTypeName,
	getReportKindName,
	getTransmissionName,
} from 'app/utils';
import { useDeleteReportMutation } from 'services/report.service';
import { useToasts } from 'react-toast-notifications';
import Breadcrumb from 'components/Breadcrumb.component';

interface MyReportsCardComponentProps {
	report: Partial<Report>;
}

const MyReportsCard: React.FC<MyReportsCardComponentProps> = ({ report }: MyReportsCardComponentProps) => {
	const [deleteReport] = useDeleteReportMutation();
	const [modalOpen, setModalOpen] = useState(false);
	const { addToast } = useToasts();

	const handleDeleteReport = async () => {
		try {
			await deleteReport(report._id!).unwrap();
			setModalOpen(false);
			addToast('Anulowano zgłoszenie');
		} catch (err) {
			addToast('Błąd usuwania zgłoszenia');
		}
	};

	const getLinkLabel = () => {
		if (report.offerAcceptedAt) {
			return 'Zobacz szczegóły';
		}
		return report.offerCount ? `Zobacz ${report.offerCount} ofert` : 'Brak ofert';
	};

	const getDateInfo = () => {
		const dueDate = _.get(report, 'dueDate');

		if (dueDate && dueDate.kind === ReportDueDateKindType.ASAP) {
			return 'Jak najszybciej';
		}
		if (dueDate && dueDate.kind === ReportDueDateKindType.RANGE) {
			const dateFrom = `${moment(dueDate.value.from).format('DD.MM.YYYY')} ${dueDate.hour.from}`;
			const dateTo = `${moment(dueDate.value.to).format('DD.MM.YYYY')} ${dueDate.hour.to}`;
			return `${dateFrom} - ${dateTo}`;
		}

		return '';
	};

	return (
		<div
			className={`visit-details  mb-3 col-sm-12 p-0 d-flex flex-wrap justify-content-between ${
				report.offerAcceptedAt ? 'history-visit' : ''
			}`}
		>
			<ConfirmModalComponent
				onCancel={() => setModalOpen(false)}
				onConfirm={handleDeleteReport}
				text="Czy napewno chcesz anulować zgłoszenie?"
				isOpen={modalOpen}
			/>
			<div className="col-md-12 col-lg-2 px-0 pt-3 d-flex flex-md-row flex-lg-column justify-content-between">
				<h3 className="green bold">{getReportKindName(report.kind || '')}</h3>
				<div>
					<label className="green">Wysłano</label>
					<p className="subheading txt-solid">
						{moment(report.createdAt).format('DD.MM.YYYY')}
						<span className="ml-3">{moment(report.createdAt).format('HH:mm')}</span>
					</p>
				</div>
				{report.offerAcceptedAt && (
					<div>
						<label className="green">Data akceptacji oferty</label>
						<p className="subheading txt-solid">
							{moment(report.offerAcceptedAt).format('DD.MM.YYYY')}{' '}
							<span className="ml-3">{moment(report.offerAcceptedAt).format('HH:mm')}</span>
						</p>
					</div>
				)}
				<Link to={`/reports/${report._id!}`}>
					<div className="green-footer px-4 py-2 txt-solid m-0">{getLinkLabel()}</div>
				</Link>
			</div>
			<div className="col-sm-12 col-md-10 col-lg-9 d-flex myborder-left pl-3 flex-wrap align-items-start p-0 collapse show">
				<div className="col-md-12 col-lg-6 p-0 px-md-2 d-flex flex-wrap">
					<div className="col-sm-12 col-md-6 col-lg-6 p-0 px-md-2">
						<label className="green">Termin usługi</label>
						<div className="date-container d-flex flex-wrap ">
							<div className="date-from txt-solid">{getDateInfo()}</div>
						</div>
						<label className="green">Płatność</label>
						<div className="txt-solid">{getPaymentTypeName(report.paymentType || '')}</div>
					</div>
					<div className="col-sm-12 col-md-6 col-lg-6 px-md-2 d-flex flex-wrap  align-content-start">
						<div className="col-sm-12 px-0">
							<label className="green">Miejsce usługi</label>
							<div className="d-flex flex-wrap ">
								<div className="txt-solid">
									{report.address ? getFormattedAddress(report.address) : '-'}
								</div>
							</div>
							<label className="green">Miejsce docelowe</label>
							<div className="d-flex flex-wrap ">
								<div className="txt-solid">
									{report.deliveryAddress ? getFormattedAddress(report.deliveryAddress) : '-'}
								</div>
							</div>
						</div>
					</div>
					<div className="col-sm-12 px-0">
						<Breadcrumb services={report.services} />
					</div>
				</div>
				<div className="col-md-12 col-lg-6 p-0 px-md-2 d-flex flex-wrap myborder-left">
					<div className="col-sm-12 col-md-6 p-0 px-md-2 d-flex flex-wrap align-content-start ">
						<div className="car-container col-sm-12 d-flex flex-wrap align-items-start">
							<label className="green">Samochód</label>
							{report.car?.manufacturer && (
								<div className="car-make txt-solid">{getManufacturerName(report.car.manufacturer)}</div>
							)}
							{report.car?.model && <div className="car-model txt-solid">{report.car.model}</div>}
							{report.car?.year && <div className="car-model txt-solid">{report.car.year}</div>}
						</div>
						<div className="car-container col-sm-12 d-flex flex-wrap">
							<label className="green">Dodatkowe</label>
							{report.car?.engine && <div className="car-cc txt-solid">{report.car.engine}</div>}
							{report.car?.fuelType && (
								<div className="car-petrol txt-solid">{getFuelTypeName(report.car.fuelType)}</div>
							)}
							{report.car?.chassisType && (
								<div className="car-body txt-solid">{getChassisTypeName(report.car.chassisType)}</div>
							)}
							{report.car?.transmission && (
								<div className="car-gearbox txt-solid">
									{getTransmissionName(report.car.transmission)}
								</div>
							)}
						</div>
						{report.isOverview && (
							<div className="car-container col-sm-12 d-flex flex-wrap">
								<label className="green">Usługa przeglądu</label>
								<div className="car-vin txt-solid ">tak</div>
							</div>
						)}
						{report.isMobileService && (
							<div className="car-container col-sm-12 d-flex flex-wrap">
								<label className="green">Czy usługa mobilna?</label>
								<div className="car-vin txt-solid ">tak</div>
							</div>
						)}
					</div>
					<div className="col-sm-12 col-md-6 p-0 px-md-2">
						<div className="car-container col-sm-12 p-0 d-flex flex-wrap">
							<label className="green">VIN</label>
							{report.car?.vin ? <div className="car-vin txt-solid ">{report.car.vin}</div> : '-'}
						</div>
						<div className="car-container col-sm-12 p-0 d-flex flex-wrap">
							<label className="green">Ubezpieczenie</label>
							{report.car?.insurance ? (
								<div className="car-oc txt-solid">{report.car.insurance}</div>
							) : (
								'-'
							)}
						</div>
					</div>
				</div>
				<div className="col-sm-12 p-0 px-md-2">
					<label className="green">Dodatkowe informacje</label>
					<div className="comments limited txt-solid">{report.description || '-'}</div>
				</div>
			</div>
			<div className="col-sm-12 col-md-2 col-lg-1 d-flex justify-content-end p-0">
				{!report.offerAcceptedAt && (
					<button
						type="button"
						className="btn btn-mobile btn-outline-secondary border-0 px-3 my-2"
						onClick={() => setModalOpen(true)}
					>
						Anuluj
					</button>
				)}
			</div>
		</div>
	);
};

export default MyReportsCard;
