import React, { useState, useEffect } from 'react';

interface TextInputComponentProps {
	id: string;
	placeholder?: string;
	label?: string;
	type: string;
	error?: string | undefined;
	register: any;
}

const TextInputComponent: React.FC<TextInputComponentProps> = ({ id, placeholder, label, type, error, register }) => {
	const [inputType, setInputType] = useState('text');

	useEffect(() => {
		setInputType(type);
	}, [type]);

	const renderEyeIcon = () => {
		if (type === 'password') {
			return (
				<i
					id="pass-status"
					className={`fa ${inputType === 'password' ? 'fa-eye' : 'fa-eye-slash'}`}
					aria-hidden="true"
					onClick={() => setInputType(inputType === 'text' ? 'password' : 'text')}
				/>
			);
		}
		return null;
	};

	return (
		<>
			{label && <label>{label}</label>}
			<input
				{...register(id)}
				type={inputType}
				placeholder={placeholder}
				autoComplete="new-password"
				onKeyPress={event => {
					if (id === 'phone' && !/[0-9]/.test(event.key)) {
						event.preventDefault();
					}
				}}
			/>
			{renderEyeIcon()}
			{error && <div className="invalid-feedback">{error}</div>}
		</>
	);
};

export default TextInputComponent;
