import React from 'react';
import moment from 'moment';
import mapIcon from 'assets/images/ico-map.svg';
import phoneIcon from 'assets/images/ico-phone.svg';
import mailIcon from 'assets/images/ico-mail.svg';
import { User } from 'services/types';
import { getManufacturerName } from 'app/utils';

interface UsersListCardComponentProps {
	user: Partial<User>;
	onDeleteClick: (deleteData: Partial<User>) => void;
	onUpdateClick: (updateData: Partial<User>) => void;
	onActivateClick: (updateData: Partial<User>) => void;
}

const UsersListCard: React.FC<UsersListCardComponentProps> = ({
	user,
	onDeleteClick,
	onUpdateClick,
	onActivateClick,
}: UsersListCardComponentProps) => {
	const renderAdditionalInfo = () => {
		if (user.type === 'user') {
			return (
				<>
					<label className="green">Samochody</label>
					<ul className="p-0">
						{user.cars?.length ? (
							user.cars?.map(car => (
								<li className="txt-solid" key={car._id}>{`${getManufacturerName(car.manufacturer)} ${
									car.model
								} ${car.year}`}</li>
							))
						) : (
							<li className="txt-solid">-</li>
						)}
					</ul>
				</>
			);
		}
		return <p>{user.name}</p>;
	};

	return (
		<div className="user p-sm-0 p-md-3 d-flex flex-wrap justify-content-between">
			<div className="user-details p-0 col-sm-12">
				<div className="col-sm-12 px-0 d-flex flex-wrap">
					<div className="user-container col-sm-12 col-md-4 col-lg-3 d-flex flex-wrap">
						<div>
							<label className="green">Informacje</label>

							<h5 className="mb-0">{user.type === 'user' ? user.name : user.companyName}</h5>
							{renderAdditionalInfo()}
						</div>
					</div>
					<div className="col-sm-12 col-md-2 col-lg-2">
						<div id="contact-container" className="d-flex flex-wrap">
							<label className="green">Kontakt</label>
							{user.address && (
								<div className="address col-sm-12 p-0">
									<img alt="address" src={mapIcon} className="float-left" />{' '}
									{`${user.address.street} ${user.address.houseNumber}`},
									<br /> {`${user.address.postCode} ${user.address.city}`}
								</div>
							)}
							{user.phone && (
								<div className="phone col-sm-12 p-0">
									<img alt="phone" src={phoneIcon} />
									<a href="tel:#">{user.phone}</a>
								</div>
							)}
							{user.email && (
								<div className="mail col-sm-12 p-0">
									<img alt="email" src={mailIcon} />
									<a href="mailto:#">{user.email}</a>
								</div>
							)}
						</div>
					</div>
					<div className="col-6 col-md-2 col-lg-2">
						<label className="green">Data rejestracji</label>
						<div className="txt-solid">
							{moment(user.createdAt).format('DD.MM.YYYY')}{' '}
							<span className="ml-3">{moment(user.createdAt!).format('HH:mm')}</span>
						</div>
						<label className="green">Ostatnie logowanie</label>
						{user.lastLogin ? (
							<div className="txt-solid">
								{moment(user.lastLogin).format('DD.MM.YYYY')}{' '}
								<span className="ml-3">{moment(user.lastLogin).format('HH:mm')}</span>
							</div>
						) : (
							<div className="txt-solid">-</div>
						)}
						{user.type === 'company' && (
							<>
								<label className="green">Zweryfikowany</label>
								{user.verified ? (
									<div className="txt-solid">Tak</div>
								) : (
									<div className="txt-solid">Nie</div>
								)}
							</>
						)}
					</div>
					<div className="col-6 col-md-2  col-lg-2">
						<label className="green">Logowań w miesiącu</label>
						<div className="txt-solid">{user.lastMonthLoginsCount ? user.lastMonthLoginsCount : '-'}</div>
						<label className="green">Wysłanych zgłoszeń</label>
						<div className="txt-solid">{user.offersCount || user.reportsCount || 0}</div>
					</div>
					<div className="col-sm-12 col-md-2 col-lg-3 justify-content-end align-items-center d-flex gap-2">
						{user.type === 'company' && !user.verified && (
							<button
								onClick={() => onActivateClick(user)}
								type="button"
								className="btn btn-mobile btn-primary my-3"
								data-bs-toggle="modal"
								data-bs-target="#modal-block"
							>
								Zweryfikuj
							</button>
						)}

						<button
							onClick={() => onUpdateClick(user)}
							type="button"
							className="btn btn-mobile btn-primary my-3"
							data-bs-toggle="modal"
							data-bs-target="#modal-block"
						>
							{user.active ? 'Zablokuj' : 'Odblokuj'}
						</button>
						<button
							onClick={() => onDeleteClick(user)}
							type="button"
							className="btn btn-mobile px-2 btn-white-reject border-0 my-3"
							data-bs-toggle="modal"
							data-bs-target="#modal-delete"
						>
							Usuń
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UsersListCard;
