import * as yup from 'yup';

const newPasswordSchema = yup
	.object({
		password: yup.string().required('Pole jest wymagane'),
		password2: yup
			.string()
			.required('Pole jest wymagane')
			.oneOf([yup.ref('password'), null], 'Hasła muszą być identyczne'),
	})
	.required();

export default newPasswordSchema;
