import { getReportKindName } from 'app/utils';
import whSygnetLogo from 'assets/images/logo-wh-sygnet.svg';
import Header from 'components/Header.component';
import SideBar from 'components/Sidebar.component';
import SideMap from 'components/SideMap.component';
import _ from 'lodash';
import moment from 'moment';
import { useAppSelector } from 'app/hooks';
import React, { useEffect, useRef, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useGetReportsQuery } from 'services/report.service';
import { Report, ReportType, User } from 'services/types';
import ReportBoardDetails from './ReportBoardDetails.component';
import ReportBoardFilters from './ReportBoardFilters.component';
import ReporBoardForm from './ReportBoardForm.component';
import ReportBoardListCard from './ReportBoardListCard.component';

const ReportBoard: React.FC = () => {
	const user: User | null = useAppSelector(state => state.auth.user);
	const limit = 10;
	const paginationRef = useRef<any>(null);
	const [filters, setFilters] = useState<Record<string, any>>({ status: 'new' });
	const [pageCount, setPageCount] = useState(1);
	const [offset, setOffset] = useState(0);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [activeReport, setActiveReport] = useState<Partial<Report> | null>(null);

	const parseFilters = () => {
		const result: Record<string, any> = { offerStatus: filters.status };
		if (filters.onlyOwnServices) {
			result.onlyOwnServices = true;
		}
		if (filters.dateFrom) {
			result.dateFrom = moment(filters.dateFrom, 'YYYY-MM-DD').format('X');
		}
		if (filters.dateTo) {
			result.dateTo = moment(filters.dateTo, 'YYYY-MM-DD').format('X');
		}
		return result;
	};
	const loggedUserLanguages: string[] = user?.languages || [];
	const { data, refetch } = useGetReportsQuery({
		...{ limit, offset, 'languages[in]': loggedUserLanguages },
		...parseFilters(),
	});

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if (data) {
			setPageCount(Math.ceil(_.get(data, 'total', 0) / limit));
		}
	}, [data]);

	useEffect(() => {
		setOffset(0);
		if (paginationRef.current && _.isFunction(_.get(paginationRef, 'current.handlePageChange'))) {
			paginationRef.current.handlePageChange(0);
		}
	}, [filters]);

	// Invoke when user click to request another page.
	const handlePageClick = (event: any) => {
		const newOffset = (event.selected * limit) % _.get(data, 'total', 0);
		setOffset(newOffset);
		refetch();
	};

	const handleDetailsClick = (report: Partial<Report>) => {
		setActiveReport(report);
		setSidebarOpen(true);
	};

	const handleSideBarClose = () => {
		setActiveReport(null);
		setSidebarOpen(false);
	};

	const renderReportsList = () => {
		if (data && !_.isEmpty(data.documents)) {
			return data.documents.map(report => {
				const isActive = activeReport?._id === report._id;
				return (
					<ReportBoardListCard
						report={report}
						key={report._id}
						isActive={isActive}
						onDetailsClick={isActive ? handleSideBarClose : handleDetailsClick}
					/>
				);
			});
		}
		return (
			<div className="noresults text-center">
				<hr className="darkgray" />
				<img src={whSygnetLogo} className="col-5 col-md-2 my-3" alt="no elements" />
				<h5 className="pr-3 pl-0 my-3">Poczekaj na pierwsze zgłoszenia od użytkowników!</h5>
			</div>
		);
	};

	const getMapCoordinates = () => {
		const coordinates: { lat: number; lng: number; type: string }[] = [];
		if (data && !_.isEmpty(data.documents)) {
			_.each(data.documents, report => {
				const reportCoordinates = _.get(report, 'address.location.coordinates');
				if (_.isArray(reportCoordinates)) {
					coordinates.push({
						lat: reportCoordinates[0],
						lng: reportCoordinates[1],
						type: report.kind === ReportType.SOS ? 'sos' : 'mechanic',
					});
				}
			});
		}
		return coordinates;
	};

	return (
		<>
			<Header />
			<SideBar
				upperContent={
					<>
						<h4 className="text-white bold mb-0">Szczegóły</h4>
						{activeReport && (
							<h2 className="text-black heavy mt-0">{getReportKindName(activeReport.kind!)}</h2>
						)}
					</>
				}
				content={<ReportBoardDetails report={activeReport} />}
				bottomContent={<ReporBoardForm report={activeReport} onSidebarClose={handleSideBarClose} />}
				onClose={handleSideBarClose}
				isOpen={sidebarOpen}
			/>
			<div id="content" className="w70 d-flex flex-wrap p-0">
				<div className="col-sm-12 filters px-3 d-flex flex-wrap align-items-center">
					<div className="col-sm-12 col-md-12 px-0 d-flex flex-column justify-content-between">
						<h3 className="dual-heading nobreak text-center pr-3 pl-0 mb-3 ">
							TABLICA <span>ZGŁOSZEŃ</span>
						</h3>
					</div>
					<div id="mobile-filters">
						{/* <button
							type="button"
							className="tab filter-tab"
							data-bs-toggle="collapse"
							data-bs-target="#filters-container"
							aria-expanded="false"
							aria-controls="filters-container"
							/>
						<button
							type="button"
							className="tab map-tab"
							data-bs-toggle="collapse"
							data-bs-target="#map-container"
							aria-expanded="false"
							aria-controls="map-container"
						/> */}
					</div>
					<ReportBoardFilters onSubmit={setFilters} />
				</div>
				<div className="offers-container d-flex flex-column px-3 col-sm-12">
					{renderReportsList()}
					{pageCount > 1 && (
						<ReactPaginate
							ref={paginationRef}
							nextLinkClassName="btn btn-outline-primary border-0"
							previousLinkClassName="btn btn-outline-primary border-0"
							pageLinkClassName="btn btn-outline-primary border-0"
							containerClassName="pagination mb-4"
							activeLinkClassName="active"
							breakLabel="..."
							nextLabel=">"
							onPageChange={handlePageClick}
							pageRangeDisplayed={3}
							pageCount={pageCount}
							previousLabel="<"
							renderOnZeroPageCount={() => null}
						/>
					)}
				</div>
			</div>
			<div id="map-container" className="w30 collapse">
				<SideMap
					coordinates={getMapCoordinates()}
					userCoordinates={_.get(user, 'address.location.coordinates')}
				/>
			</div>
		</>
	);
};

export default ReportBoard;
