import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import whLogo from 'assets/images/logo-wh.svg';

import RegisterFormUser from './RegisterFormUser.component';
import RegisterFormOwner from './RegisterFormOwner.component';

const RegisterForm: React.FC = () => {
	const [selectedForm, setSelectedForm] = useState('user');

	const handleSelectForm = (userType: string): void => {
		setSelectedForm(userType);
	};
	return (
		<div id="content" className="d-flex flex-wrap p-0">
			<div className="login-cover register bg-car2 w30">
				<div className="top-logo">
					<img src={whLogo} alt="logo" />
				</div>
			</div>
			<div className="autohelp-form registration w70">
				<div className="info-bar">
					<span>Masz już konto?</span>
					<Link to="/login">
						<div>Zaloguj się!</div>
					</Link>
				</div>
				<div className="form-container d-flex d-md-block flex-wrap align-content-start">
					<div className="role-select p-0 overflow-hidden">
						<div className="roleselectorcontainer">
							<div className="row m-0">
								<h3 className="text-center txt-solid mt-3">Wybierz swoją rolę</h3>
								<div
									className="d-flex flex-wrap btn-group register-select p-0"
									role="group"
									aria-label="Basic radio toggle button group"
								>
									<input
										type="radio"
										className="btn-check"
										name="btnGroupRadio"
										id="btnRadio1"
										autoComplete="off"
										defaultChecked
										onClick={() => handleSelectForm('user')}
										onKeyPress={() => handleSelectForm('user')}
									/>
									<label
										className="col-12 col-md-6 btn btn-outline-primary border-0"
										htmlFor="btnRadio1"
									>
										Szukam dobrego mechanika
									</label>
									<input
										type="radio"
										className="btn-check"
										name="btnGroupRadio"
										id="btnRadio2"
										autoComplete="off"
										onClick={() => handleSelectForm('owner')}
										onKeyPress={() => handleSelectForm('owner')}
									/>
									<label
										className="col-12 col-md-6 btn btn-outline-primary border-0"
										htmlFor="btnRadio2"
									>
										Jestem właścicielem warsztatu
									</label>
								</div>
							</div>
						</div>
					</div>
					{selectedForm === 'user' && <RegisterFormUser />}
					{selectedForm === 'owner' && <RegisterFormOwner />}
				</div>
			</div>
		</div>
	);
};

export default RegisterForm;
