import React from 'react';
import { getServiceBreadCrumb } from 'app/utils';
import separatorIcon from 'assets/images/x-sep.svg';

interface BreadcrumbComponentProps {
	services?: string[];
}

const Breadcrumb: React.FC<BreadcrumbComponentProps> = ({ services }: BreadcrumbComponentProps) => {
	const renderBreadcrumb = () => {
		if (services && services.length) {
			return services.map(service => {
				const breadCrumbArray = getServiceBreadCrumb(service);
				return (
					<div key={`single-readcrumb-${service}`} className="category-container d-flex">
						{breadCrumbArray[0] && <div className="cat-1level txt-solid">{breadCrumbArray[0]}</div>}
						{breadCrumbArray[1] && (
							<>
								<div className="separator">
									<img alt="separator" src={separatorIcon} />
								</div>
								<div className="cat-2level txt-solid">{breadCrumbArray[1]}</div>
							</>
						)}
						{breadCrumbArray[2] && (
							<>
								<div className="separator">
									<img alt="separator" src={separatorIcon} />
								</div>
								<div className="cat-2level txt-solid">{breadCrumbArray[2]}</div>
							</>
						)}
					</div>
				);
			});
		}

		return (
			<div className="category-container d-flex">
				<div className="cat-1level txt-solid">-</div>
			</div>
		);
	};

	return (
		<div>
			<label className="green">Kategorie zgłoszenia</label>
			{renderBreadcrumb()}
		</div>
	);
};

export default Breadcrumb;
