import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/api';
import {
	GetLocationByCoordinatesRequest,
	GetLocationByCoordinatesResponse,
	GetLocationDetailsRequest,
	GetLocationDetailsResponse,
	GetLocationsRequest,
	GetLocationsResponse,
} from './types';

const section = 'application';

export const locationApi = createApi({
	baseQuery,
	reducerPath: "locationApi",
	endpoints: builder => ({
		getLocations: builder.query<GetLocationsResponse, GetLocationsRequest>({
			query: ({ query, token }) => ({
				url: `${section}/getLocations?query=${query}&token=${token}`,
			}),
		}),
		getLocationDetails: builder.query<GetLocationDetailsResponse, GetLocationDetailsRequest>({
			query: ({ params }) => ({
				url: `${section}/getLocationDetails?${params}`,
			}),
		}),
		getLocationByCoordinates: builder.query<GetLocationByCoordinatesResponse, GetLocationByCoordinatesRequest>({
			query: ({ lat, lon }) => ({
				url: `${section}/getLocationByCoordinates?lat=${lat}&lon=${lon}`,
			}),
		}),
	}),
});

export const {
	useGetLocationsQuery,
	useGetLocationDetailsQuery,
	useLazyGetLocationsQuery,
	useLazyGetLocationDetailsQuery,
	useGetLocationByCoordinatesQuery,
	useLazyGetLocationByCoordinatesQuery,
} = locationApi;
