import _ from 'lodash';
import React, { useEffect } from 'react';
import { Rating } from 'react-simple-star-rating';
import { Rating as RatingType } from 'services/types';

interface RatingModalProps {
	ratings: RatingType[];
	onCancel: any;
	isOpen: boolean;
}

const RatingsModal: React.FC<RatingModalProps> = ({ ratings, onCancel, isOpen }) => {
	useEffect(() => {
		if (isOpen) {
			document.querySelector('body')?.classList.add('modal-open');
			document.querySelector('body')?.classList.add('hide-scroll');
		} else {
			document.querySelector('body')?.classList.remove('modal-open');
			document.querySelector('body')?.classList.remove('hide-scroll');
		}
	}, [isOpen]);

	const renderReviews = () => {
		if (!_.isEmpty(ratings)) {
			return ratings.map((rating: RatingType) => (
				<div className="row mt-2" key={rating._id}>
					<div className="col-sm-12">
						<Rating ratingValue={rating.vote * 20} readonly />
					</div>
					<div className="col-sm-12 text-black">{rating.description || '-'}</div>
				</div>
			));
		}

		return (
			<div className="row mt-2">
				<h3>Brak ocen do wyświetlenia</h3>
			</div>
		);
	};

	return (
		<>
			<div
				className={`modal fade ${isOpen && 'show modal-open'}`}
				id="modal-confirm"
				tabIndex={-1}
				aria-labelledby="modal-confirm"
				aria-hidden="true"
				style={{ display: isOpen ? 'block' : 'none', maxHeight: 600 }}
			>
				<div className="modal-dialog modal modal-dialog-centered text-center">
					<div className="modal-content border-0 rounded-0 d-flex flex-row p-0">
						<div className="col-sm-12 border-0 p-0 ">
							<div className="modal-body pt-5">
								<h4 className="text-bold">Oceny</h4>
							</div>
							<div className="modal-body" style={{ maxHeight: 400, overflow: 'scroll' }}>
								{renderReviews()}
							</div>
							<div className="modal-footer pb-5 d-flex justify-content-center">
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
									onClick={onCancel}
								>
									Zamknij
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isOpen && (
				<div className="modal-backdrop show" onClick={onCancel} onKeyPress={onCancel} role="presentation" />
			)}
		</>
	);
};

export default RatingsModal;
