import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
	getChassisTypeClass,
	getChassisTypeName,
	getFuelTypeName,
	getManufacturerName,
	getTransmissionName,
	handleApiErrors,
} from 'app/utils';
import whSygnetLogo from 'assets/images/logo-wh-sygnet.svg';
import ConfirmModalComponent from 'components/ConfirmModal.component';
import Header from 'components/Header.component';
import SideBar from 'components/Sidebar.component';
import { setCredentials } from 'features/auth/authSlice';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useUpdateGarageMutation } from 'services/garage.service';
import { UserCar } from 'services/types';
import GarageForm from './GarageForm.component';

const Garage: React.FC = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const user = useAppSelector(state => state.auth.user);
	const token = useAppSelector(state => state.auth.token);
	const serviceDictionary = useAppSelector(state => state.auth.serviceDictionary);
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
	const [updateIndex, setUpdateIndex] = useState<number | null>(null);
	const [updateGarage, { isSuccess, data: result }] = useUpdateGarageMutation();
	const { addToast } = useToasts();

	const handleShowModal = (index: number) => {
		setDeleteIndex(index);
		setModalOpen(true);
	};

	const handleHideModal = () => {
		setDeleteIndex(null);
		setModalOpen(false);
	};

	const handleDeleteCar = () => {
		const newCars = JSON.parse(JSON.stringify(user?.cars));
		newCars.splice(deleteIndex, 1);
		updateGarage({ cars: newCars });
		setDeleteIndex(null);
		setModalOpen(false);
	};

	useEffect(() => {
		if (result && isSuccess) {
			dispatch(setCredentials({ user: result.document, token, serviceDictionary }));
			addToast('Zapisano zmiany');
		}
	}, [result]);

	const handleFormSave = (setError: UseFormSetError<Partial<UserCar>>, data: Partial<UserCar>) => {
		try {
			const newCars = JSON.parse(JSON.stringify(user?.cars));
			if (updateIndex !== null) {
				newCars[updateIndex] = data;
				updateGarage({ cars: newCars });
			} else {
				newCars.push(data);
				updateGarage({ cars: newCars });
			}
			setUpdateIndex(null);
			setSidebarOpen(false);
		} catch (err) {
			handleApiErrors(setError, err);
			addToast('Popraw błędy w formularzu');
		}
	};

	const handleEdit = (index: number) => {
		setUpdateIndex(index);
		setSidebarOpen(true);
	};

	const handleCloseSidebar = () => {
		setUpdateIndex(null);
		setSidebarOpen(false);
	};

	const handleShowHistory = (carId: string) => {
		history.push(`/reports?carId=${carId}`);
	};

	const renderList = () => {
		if (!_.isEmpty(user?.cars)) {
			const list = user?.cars?.map((car: UserCar, index) => (
				<div className="car col-sm-12 p-0 d-flex flex-wrap justify-content-between" key={`car${car._id}`}>
					<div className="car-details col-sm-12 col-md-9 col-lg-9 p-0">
						<Link
							to={{ pathname: `/reports`, search: `?carId=${car._id}` }}
							className="car-pic col-md-3 col-lg-2 justify-content-center d-flex p-0"
						>
							<img src="" className={getChassisTypeClass(car.chassisType)} alt="chassis" />
						</Link>
						<div className="col-md-9 col-lg-10 d-flex flex-wrap">
							<div className="car-container col-sm-6 d-flex flex-wrap">
								<label>Samochód</label>
								<div className="car-make txt-solid">{getManufacturerName(car.manufacturer)}</div>
								<div className="car-model txt-solid">{car.model}</div>
								<div className="car-year txt-solid">{car.year}</div>
							</div>
							<div className="car-container col-sm-6 d-flex flex-wrap">
								<label>Dodatkowe</label>
								<div className="car-cc txt-solid">{car.engine}</div>
								<div className="car-petrol txt-solid">{getFuelTypeName(car.fuelType)}</div>
								<div className="car-body txt-solid">{getChassisTypeName(car.chassisType)}</div>
								<div className="car-gearbox txt-solid">{getTransmissionName(car.transmission)}</div>
							</div>
							<div className="car-container col-sm-6 d-flex flex-wrap">
								<label>VIN</label>
								<div className="car-vin txt-solid ">{car.vin || '-'}</div>
							</div>
							<div className="car-container col-sm-6 d-flex flex-wrap">
								<label>Ubezpieczenie</label>
								<div className="car-oc txt-solid">{car.insurance || '-'}</div>
							</div>
						</div>
					</div>
					<div className="col-sm-12 col-md-3 col-lg-3 px-sm-3 px-md-0 d-flex flex-wrap justify-content-end align-items-center py-3 gap-2">
						<button
							type="button"
							id="sidebarCollapse"
							className="btn btn-primary mr-sm-0 mr-md-2 col-md-8 col-sm-12"
							onClick={() => handleEdit(index)}
						>
							Edytuj
						</button>
						<button
							type="button"
							className="btn btn-secondary border-0 mr-sm-0 mr-md-2 col-md-8 col-sm-12"
							data-bs-toggle="modal" 
							data-bs-target="#modal-confirm"
							onClick={() => {
								handleShowHistory(car._id);
							}}
						>
							Historia
						</button>
						<button
							type="button"
							className="btn btn-white-reject border-0 mr-sm-0 mr-md-2 col-md-8 col-sm-12"
							data-bs-toggle="modal"
							data-bs-target="#modal-confirm"
							onClick={() => {
								handleShowModal(index);
							}}
						>
							Usuń
						</button>
					</div>
				</div>
			));

			return (
				<>
					{list}
					<button
						type="button"
						id="sidebarCollapse"
						className="btn btn-primary mt-5"
						onClick={() => setSidebarOpen(true)}
					>
						Dodaj nowy samochód
					</button>
				</>
			);
		}
		return (
			<div className="noresults text-center">
				<img src={whSygnetLogo} className="col-5 col-md-2 my-3" alt="no-elements" />
				<h5 className="pr-3 pl-0 my-3">Brak zapisanych samochodów.</h5>
				<button
					type="button"
					id="sidebarCollapse"
					className="btn btn-primary mt-5 justify-content-center"
					onClick={() => setSidebarOpen(true)}
				>
					Dodaj nowy samochód
				</button>
			</div>
		);
	};

	return (
		<>
			<Header />
			<SideBar
				isOpen={sidebarOpen}
				content={<GarageForm initialValues={_.get(user, `cars[${updateIndex}]`, {})} onSave={handleFormSave} />}
				onClose={handleCloseSidebar}
			/>
			<ConfirmModalComponent
				onCancel={handleHideModal}
				onConfirm={handleDeleteCar}
				text="Czy napewno chcesz usunąć?"
				isOpen={modalOpen}
			/>
			<div id="content" className="d-flex flex-wrap p-0">
				<div className="w100 profile-container d-flex flex-wrap flex-fill p-4">
					<div className="col-sm-12 col-md-2 col-lg-2 p-sm-0 p-md-5 text-right myborder-right light-bg">
						<h3 className="dual-heading mb-3">
							MÓJ<span>GARAŻ</span>
						</h3>
					</div>
					<div className="cars-container col-sm-12 col-md-10 col-lg-10 p-sm-0 p-md-3 light-bg">
						{renderList()}
					</div>
				</div>
			</div>
		</>
	);
};

export default Garage;
