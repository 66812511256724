import React, { useEffect } from 'react';

interface InfoModalProps {
    text: string;
    onConfirm?: any;
    isOpen: boolean;
}

const InfoModalComponent: React.FC<InfoModalProps> = ({ text, onConfirm, isOpen }) => {
    useEffect(() => {
        if (isOpen) {
            document.querySelector('body')?.classList.add('modal-open');
            document.querySelector('body')?.classList.add('hide-scroll');
        } else {
            document.querySelector('body')?.classList.remove('modal-open');
            document.querySelector('body')?.classList.remove('hide-scroll');
        }
    }, [isOpen]);

    return (<>
        <div
	className={`modal fade ${isOpen && 'show modal-open'}`}
	id="modal-confirm"
	tabIndex={-1}
	aria-labelledby="modal-confirm"
	aria-hidden="true"
	style={{ display: isOpen ? 'block' : 'none' }}
        >
            <div className="modal-dialog modal modal-dialog-centered text-center">
                <div className="modal-content border-0 rounded-0 d-flex flex-row p-0">
                    <div className="col-sm-12 border-0 p-0 ">
                        <div className="modal-body pt-5">
                            <h4 className="text-bold">{text}</h4>
                        </div>
                        <div className="modal-footer pb-5 d-flex justify-content-center">
                            <button
	type="button"
	className="btn btn-primary"
	data-bs-dismiss="modal"
	onClick={onConfirm}
                            >
                                Ok
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {isOpen && <div className='modal-backdrop show' onClick={onConfirm} onKeyPress={onConfirm} role="presentation" />}
    </>
    );
};

export default InfoModalComponent;
