import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, parseListParams, ListParams } from 'app/api';
import {
	ReportDocumentResponse,
	ReportsListResponse,
	Report,
	ChangeOfferStatusRequest,
	Offer,
	ChangeOfferStatusResponse,
	Rating,
	RatingsListResponse,
} from './types';

const section = 'report';

export const reportsApi = createApi({
	baseQuery,
	reducerPath: 'reportsApi',
	tagTypes: ['Reports', 'Ratings'],
	endpoints: builder => ({
		getReports: builder.query<ReportsListResponse, ListParams>({
			query: params => ({
				url: `${section}/${parseListParams('report', params)}`,
			}),
			providesTags: ['Reports'],
		}),
		getReport: builder.query<Report, string>({
			query: id => ({
				url: `${section}/report/${id}`,
			}),
			transformResponse: (response: { document: Report }) => response.document,
			providesTags: ['Reports'],
		}),
		createReport: builder.mutation<ReportDocumentResponse, Partial<Report>>({
			query: data => ({
				url: `${section}/report`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Reports'],
		}),
		updateReport: builder.mutation<ReportDocumentResponse, Partial<Report>>({
			query: data => ({
				url: `${section}/report/${data._id}`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['Reports'],
		}),
		deleteReport: builder.mutation<ReportDocumentResponse, string>({
			query: _id => ({
				url: `${section}/report/${_id}`,
				method: 'DELETE',
				body: { active: false },
			}),
			invalidatesTags: ['Reports'],
		}),
		changeOfferStatus: builder.mutation<ChangeOfferStatusResponse, ChangeOfferStatusRequest>({
			query: data => ({
				url: `${section}/changeOfferStatus`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Reports'],
		}),
		createOffer: builder.mutation<ReportDocumentResponse, Partial<Offer>>({
			query: data => ({
				url: `${section}/offer`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Reports'],
		}),
		rateUser: builder.mutation<any, Partial<Rating>>({
			query: data => ({
				url: `rating/rating`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: ['Reports', 'Ratings'],
		}),
		getRatings: builder.query<RatingsListResponse, ListParams>({
			query: data => ({
				url: `rating/rating?user=${data.id}`,
			}),
			providesTags: ['Ratings'],
		}),
	}),
});

export const {
	useGetReportsQuery,
	useGetReportQuery,
	useLazyGetReportQuery,
	useLazyGetReportsQuery,
	useCreateReportMutation,
	useUpdateReportMutation,
	useDeleteReportMutation,
	useChangeOfferStatusMutation,
	useCreateOfferMutation,
	useRateUserMutation,
	useGetRatingsQuery,
} = reportsApi;
