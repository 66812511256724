import React, { useEffect, useState } from 'react';
import { Rating } from 'react-simple-star-rating';

interface RatingModalProps {
	onConfirm?: any;
	onCancel?: any;
	isOpen: boolean;
}

const RatingModal: React.FC<RatingModalProps> = ({ onConfirm, onCancel, isOpen }) => {
	const [rating, setRating] = useState<number>(0);
	const [description, setDescription] = useState<string>('');

	useEffect(() => {
		if (isOpen) {
			document.querySelector('body')?.classList.add('modal-open');
			document.querySelector('body')?.classList.add('hide-scroll');
		} else {
			document.querySelector('body')?.classList.remove('modal-open');
			document.querySelector('body')?.classList.remove('hide-scroll');
		}
	}, [isOpen]);

	const handleRating = (vote: number) => {
		setRating(vote);
	};

	return (
		<>
			<div
				className={`modal fade ${isOpen && 'show modal-open'}`}
				id="modal-confirm"
				tabIndex={-1}
				aria-labelledby="modal-confirm"
				aria-hidden="true"
				style={{ display: isOpen ? 'block' : 'none' }}
			>
				<div className="modal-dialog modal modal-dialog-centered text-center">
					<div className="modal-content border-0 rounded-0 d-flex flex-row p-0">
						<div className="col-sm-12 border-0 p-0 ">
							<div className="modal-body pt-5">
								<h4 className="text-bold">Ocena usługi</h4>
							</div>
							<div className="modal-body">
								<div>
									<Rating onClick={handleRating} ratingValue={rating} /* Available Props */ />
								</div>
								<div className="mt-4">
									<textarea
										rows={3}
										placeholder="Opis"
										onChange={e => setDescription(e.target.value)}
									/>
								</div>
							</div>
							<div className="modal-footer pb-5 d-flex justify-content-center">
								<button
									type="button"
									className="btn btn-primary"
									data-bs-dismiss="modal"
									onClick={() => onConfirm(rating / 20, description)}
									disabled={rating ? false : true}
								>
									Dodaj
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isOpen && (
				<div className="modal-backdrop show" onClick={onCancel} onKeyPress={onCancel} role="presentation" />
			)}
		</>
	);
};

export default RatingModal;
