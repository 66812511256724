import { yupResolver } from '@hookform/resolvers/yup';
import { getChassisTypeClass } from 'app/utils';
import chassisTypes from 'assets/dicts/chassisType.dict.json';
import fuelTypes from 'assets/dicts/fuelType.dict.json';
import transmissionTypes from 'assets/dicts/transmission.dict.json';
import CarSelectComponent from 'components/CarSelect.component';
import SelectComponent from 'components/Select.component';
import TextInputComponent from 'components/TextInput.component';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { UserCar } from 'services/types';
import { reportCarSchema } from './sosReportForm.schema';

interface CarSelectFormComponentProps {
	onSelect: (data: Partial<UserCar>) => void;
	onClose: () => void;
	parentErrors?: {
		_id?: FieldError | undefined;
		manufacturer?: FieldError | undefined;
		model?: FieldError | undefined;
		year?: FieldError | undefined;
		vin?: FieldError | undefined;
		engine?: FieldError | undefined;
		fuelType?: FieldError | undefined;
		transmission?: FieldError | undefined;
		chassisType?: FieldError | undefined;
		insurance?: FieldError | undefined;
	};
}

const CarSelectForm: React.FC<CarSelectFormComponentProps> = ({
	onSelect,
	onClose,
	parentErrors,
}: CarSelectFormComponentProps) => {
	const {
		register,
		handleSubmit,
		setValue,
		setError,
		formState: { errors },
		watch,
	} = useForm<UserCar>({
		resolver: yupResolver(reportCarSchema),
		reValidateMode: 'onChange',
	});
	useEffect(() => {
		if (!_.isEmpty(parentErrors)) {
			_.each(parentErrors, (value, key) => {
				setError(key as any, value as any);
			});
		}
	}, [parentErrors]);

	const handleSelectUserCar = (car: UserCar) => {
		if ('manufacturer' in car) {
			setValue('manufacturer', car.manufacturer);
		}
		if ('model' in car) {
			setValue('model', car.model);
		}
		if ('year' in car) {
			setValue('year', car.year);
		}
		if ('vin' in car) {
			setValue('vin', car.vin);
		}
		if ('engine' in car) {
			setValue('engine', car.engine);
		}
		if ('fuelType' in car) {
			setValue('fuelType', car.fuelType);
		}
		if ('transmission' in car) {
			setValue('transmission', car.transmission);
		}
		if ('chassisType' in car) {
			setValue('chassisType', car.chassisType);
		}
		if ('insurance' in car) {
			setValue('insurance', car.insurance);
		}
		if ('_id' in car) {
			setValue('carId', car._id);
		}
	};

	const chassisType = watch('chassisType');

	const handleSelect = (data: Partial<UserCar>) => {
		onSelect(data);
		onClose();
	};

	return (
		<>
			<div className="car-pic-side desktoponly">
				<img src="" className={getChassisTypeClass(chassisType)} alt="car" />
			</div>
			<form
				className={`col-sm-12 car-form ${!_.isEmpty(errors) ? 'invalid-form' : ''}`}
				// className="col-sm-12 car-form"
				onSubmit={handleSubmit(handleSelect)}
			>
				<h4 className="text-white bold mb-0 pt-3">Dane samochodu</h4>
				<div className="row">
					<div className="col-sm-12 d-flex flex-wrap">
						<CarSelectComponent
							onCarSelect={handleSelectUserCar}
							onManufacturerSelect={(m: string) => setValue('manufacturer', m)}
						/>
						{errors.manufacturer && <div className="invalid-feedback">{errors.manufacturer?.message}</div>}
					</div>
					<div className="col-md-12 col-lg-6 d-flex flex-wrap">
						<TextInputComponent
							id="model"
							label="Model"
							type="text"
							placeholder="Model"
							register={register}
							error={errors.model?.message}
						/>
					</div>
					<div className="col-md-12 col-lg-6 d-flex flex-wrap">
						<TextInputComponent
							id="year"
							label="Rok produkcji"
							type="text"
							placeholder="Rok produkcji"
							register={register}
							error={errors.year?.message}
						/>
					</div>
					<div className="col-md-12 col-lg-6 d-flex flex-wrap">
						<SelectComponent
							label="Rodzaj nadwozia"
							placeholder="Rodzaj nadwozia"
							register={register}
							options={chassisTypes}
							id="chassisType"
							error={errors.chassisType?.message}
						/>
					</div>
					<div className="col-md-12 col-lg-6 d-flex flex-wrap">
						<TextInputComponent
							id="engine"
							label="Pojemność silnika"
							type="text"
							placeholder="Pojemność silnika"
							register={register}
							error={errors.engine?.message}
						/>
					</div>
					<div className="col-md-12 col-lg-6 d-flex flex-wrap">
						<SelectComponent
							label="Skrzynia biegów"
							placeholder="Skrzynia biegów"
							register={register}
							options={transmissionTypes}
							id="transmission"
							error={errors.transmission?.message}
						/>
					</div>
					<div className="col-md-12 col-lg-6 d-flex flex-wrap">
						<SelectComponent
							label="Rodzaj paliwa"
							placeholder="Rodzaj paliwa"
							register={register}
							options={fuelTypes}
							id="fuelType"
							error={errors.fuelType?.message}
						/>
					</div>
					<div className="col-md-12 col-lg-6 d-flex flex-wrap">
						<TextInputComponent
							id="vin"
							label="VIN"
							type="text"
							placeholder="VIN"
							register={register}
							error={errors.vin?.message}
						/>
					</div>
					<div className="col-md-12 col-lg-6 d-flex flex-wrap">
						<TextInputComponent
							id="insurance"
							label="Ubezpieczenie"
							type="text"
							placeholder="Wpisz jakie ubezpieczenie ma pojazd"
							register={register}
							error={errors.insurance?.message}
						/>
					</div>
				</div>
				<button type="submit" className="btn btn-primary-dark mt-3">
					Zapisz
				</button>
			</form>
		</>
	);
};

export default CarSelectForm;
