import { yupResolver } from '@hookform/resolvers/yup';
import { handleApiErrors } from 'app/utils';
import logoWh from 'assets/images/logo-wh.svg';
import ButtonComponent from 'components/Button.component';
import TextInputComponent from 'components/TextInput.component';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useChangePasswordMutation } from 'services/application.service';
import newPasswordSchema from './newPassword.schema';

interface NewPasswordFormValues {
	password: string;
	password2: string;
}

const NewPassword: React.FC = () => {
	const history = useHistory();
	const { token } = useParams<{ token: string }>();
	const { addToast } = useToasts();
	const [changePassword, { isLoading }] = useChangePasswordMutation();

	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm<NewPasswordFormValues>({
		resolver: yupResolver(newPasswordSchema),
		reValidateMode: 'onChange',
	});

	const handleChangePassword = async (data: NewPasswordFormValues): Promise<void> => {
		try {
			await changePassword({
				password: data.password,
				token,
			}).unwrap();

			addToast('Hasło zostało zmienione');
			history.replace('/login');
		} catch (err) {
			handleApiErrors(setError, err);
			addToast('Wystąpił błąd');
		}
	};

	return (
		<div id="content" className="d-flex flex-wrap p-0">
			<div className="login-cover bg-car2 w50">
				<div className="top-logo">
					<img src={logoWh} alt="logo" />
				</div>
			</div>
			<div className="autohelp-form w50">
				<div className="info-bar">
					<span>Nie masz konta?</span>
					<Link to="/register">
						<div>Zarejestruj się!</div>
					</Link>
				</div>
				<div className="form-container">
					<h1 className="p-5 bold">
						Dbanie o samochód
						<br /> nigdy nie było <br />
						<span>tak proste.</span>
					</h1>

					<form onSubmit={handleSubmit(handleChangePassword)}>
						<div className="box login-form">
							<div className="row">
								<div
									className={`${
										errors.password?.message && 'invalid-form'
									} col-sm-12 col-md-6 col-lg-4 col-xl-4`}
								>
									<TextInputComponent
										id="password"
										type="text"
										placeholder="Podaj hasło"
										register={register}
										error={errors.password?.message}
									/>
								</div>
							</div>
							<div className="row">
								<div
									className={`${
										errors.password2?.message && 'invalid-form'
									} col-sm-12 col-md-6 col-lg-4 col-xl-4`}
								>
									<TextInputComponent
										id="password2"
										type="text"
										placeholder="Powtórz hasło"
										register={register}
										error={errors.password2?.message}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12 col-lg-6 col-xl-4 pt-sm-0 pt-md-3">
									<ButtonComponent
										type="submit"
										className="btn btn-primary btn-submit"
										isLoading={isLoading}
										text="Zmień hasło"
									/>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default NewPassword;
