import { yupResolver } from '@hookform/resolvers/yup';
import { getChassisTypeClass } from 'app/utils';
import chassisTypes from 'assets/dicts/chassisType.dict.json';
import fuelTypes from 'assets/dicts/fuelType.dict.json';
import manufacturers from 'assets/dicts/manufacturer.dict.json';
import transmissionTypes from 'assets/dicts/transmission.dict.json';
import SelectComponent from 'components/Select.component';
import TextInputComponent from 'components/TextInput.component';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useForm, UseFormSetError } from 'react-hook-form';
import { UserCar } from 'services/types';
import schema from './garageForm.schema';

interface GarageFormComponentProps {
	initialValues: Partial<UserCar>;
	onSave: (setError: UseFormSetError<Partial<UserCar>>, data: Partial<UserCar>) => void;
}

const GarageForm: React.FC<GarageFormComponentProps> = ({ initialValues, onSave }: GarageFormComponentProps) => {
	const {
		register,
		handleSubmit,
		setValue,
		reset,
		setError,
		watch,
		formState: { errors },
	} = useForm<GarageFormComponentProps['initialValues']>({
		resolver: yupResolver(schema),
		defaultValues: initialValues,
		reValidateMode: 'onChange',
	});

	useEffect(() => {
		_.each(initialValues, (value, key) => {
			setValue(key as any, value);
		});
		return () => {
			reset();
		};
	}, [initialValues]);

	const submitProxy = (data: Partial<UserCar>) => {
		onSave(setError, data);
	};

	const chassisType = watch("chassisType");

	return (
		<>
			<div className="car-pic-side desktoponly">
				<img src="" className={getChassisTypeClass(chassisType || "sedan")} alt="car" />
			</div>
			<form className="col-sm-12 car-form" onSubmit={handleSubmit(submitProxy)}>
				<h4 className="text-white bold mb-0 pt-3">{_.isEmpty(initialValues) ? 'Dodaj' : 'Edytuj'} samochód</h4>
				{/* <!-- <h4 className="text-white bold mb-0 pt-5">Dodaj samochód</h4> --> */}
				<div className="row">
					<div className={`col-sm-12 d-flex flex-wrap manufacturer-select ${errors.manufacturer?.message ? 'invalid-form' : ''}`}>
						<SelectComponent
							label="Marka samochodu"
							placeholder="Marka samochodu"
							register={register}
							options={manufacturers}
							id="manufacturer"
							error={errors.manufacturer?.message}
						/>
					</div>
					<div
						className={`col-md-12 col-lg-6 d-flex flex-wrap ${errors.model?.message ? 'invalid-form' : ''}`}
					>
						<TextInputComponent
							id="model"
							label="Model"
							type="text"
							placeholder="Model"
							register={register}
							error={errors.model?.message}
						/>
					</div>
					<div
						className={`col-md-12 col-lg-6 d-flex flex-wrap ${errors.year?.message ? 'invalid-form' : ''}`}
					>
						<TextInputComponent
							id="year"
							label="Rok produkcji"
							type="text"
							placeholder="Rok produkcji"
							register={register}
							error={errors.year?.message}
						/>
					</div>
					<div
						className={`col-md-12 col-lg-6 d-flex flex-wrap ${
							errors.chassisType?.message ? 'invalid-form' : ''
						}`}
					>
						<SelectComponent
							label="Rodzaj nadwozia"
							placeholder="Rodzaj nadwozia"
							register={register}
							options={chassisTypes}
							id="chassisType"
							error={errors.chassisType?.message}
						/>
					</div>
					<div
						className={`col-md-12 col-lg-6 d-flex flex-wrap ${
							errors.engine?.message ? 'invalid-form' : ''
						}`}
					>
						<TextInputComponent
							id="engine"
							label="Pojemność silnika"
							type="text"
							placeholder="Pojemność silnika"
							register={register}
							error={errors.engine?.message}
						/>
					</div>
					<div
						className={`col-md-12 col-lg-6 d-flex flex-wrap ${
							errors.transmission?.message ? 'invalid-form' : ''
						}`}
					>
						<SelectComponent
							label="Skrzynia biegów"
							placeholder="Skrzynia biegów"
							register={register}
							options={transmissionTypes}
							id="transmission"
							error={errors.transmission?.message}
						/>
					</div>
					<div
						className={`col-md-12 col-lg-6 d-flex flex-wrap ${
							errors.fuelType?.message ? 'invalid-form' : ''
						}`}
					>
						<SelectComponent
							label="Rodzaj paliwa"
							placeholder="Rodzaj paliwa"
							register={register}
							options={fuelTypes}
							id="fuelType"
							error={errors.fuelType?.message}
						/>
					</div>
					<div className={`col-md-12 col-lg-6 d-flex flex-wrap ${errors.vin?.message ? 'invalid-form' : ''}`}>
						<TextInputComponent
							id="vin"
							label="VIN"
							type="text"
							placeholder="VIN"
							register={register}
							error={errors.vin?.message}
						/>
					</div>
					<div
						className={`col-md-12 col-lg-6 d-flex flex-wrap ${
							errors.insurance?.message ? 'invalid-form' : ''
						}`}
					>
						<TextInputComponent
							id="insurance"
							label="Ubezpieczenie"
							type="text"
							placeholder="Wpisz jakie ubezpieczenie ma pojazd"
							register={register}
							error={errors.insurance?.message}
						/>
					</div>
				</div>
				<button type="submit" className="btn btn-primary-dark mt-3">
					Zapisz
				</button>
			</form>
		</>
	);
};

export default GarageForm;
