import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'app/hooks';
import { getFormattedCarInfo, handleApiErrors } from 'app/utils';
import ButtonComponent from 'components/Button.component';
import GoogleAutocompleteComponent from 'components/GoogleAutocomplete.component';
import Header from 'components/Header.component';
import MapComponent from 'components/Map.component';
import SelectComponent from 'components/Select.component';
import SideBar from 'components/Sidebar.component';
import TextareaComponent from 'components/Textarea.component';
import TextInputComponent from 'components/TextInput.component';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useCreateReportMutation } from 'services/report.service';
import { Address, Report, UserCar } from 'services/types';
import schema from './carCarrierReportForm.schema';
import CarSelectForm from './CarSelectForm.component';

const CarCarrierReportForm: React.FC = () => {
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		setError,
		trigger,
		formState: { errors },
	} = useForm<Partial<Report>>({
		resolver: yupResolver(schema),
		defaultValues: {
			kind: 'car_carrier',
			needsCarrier: true,
			dueDate: {
				kind: 'range',
				value: {
					from: moment().add(1, 'day').format('YYYY-MM-DD'),
					to: moment().add(2, 'days').format('YYYY-MM-DD'),
				},
				hour: {
					from: moment().startOf('day').format('HH:mm'),
					to: moment().endOf('day').format('HH:mm'),
				},
			},
		},
		reValidateMode: 'onChange',
	});
	const { addToast } = useToasts();
	const [createReport, { isLoading }] = useCreateReportMutation();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const history = useHistory();
	const loggedUserLanguages: string[] = useAppSelector(state => state.auth.user?.languages || []);

	const handleSaveForm = async (data: Partial<Report>) => {
		try {
			if (data.dueDate?.value?.from) {
				data.dueDate.value.from = moment(data.dueDate.value.from).format('X');
			}
			if (data.dueDate?.value?.to) {
				data.dueDate.value.to = moment(data.dueDate.value.to).format('X');
			}
			data.languages = loggedUserLanguages;
			await createReport(data).unwrap();
			addToast('Dodano zgłoszenie');
			history.push('/reports');
		} catch (err) {
			addToast('Wystąpił błąd');
			handleApiErrors(setError, err);
		}
	};

	const resetCar = () => {
		setValue('car', {
			_id: '',
			manufacturer: '',
			model: '',
			year: '',
			vin: '',
			engine: '',
			fuelType: '',
			transmission: '',
			chassisType: '',
			insurance: '',
			carId: '',
		});
	};

	const carInfo = watch('car');
	const selectedAddress = watch('address');
	const selectedDeliveryAddress = watch('deliveryAddress');

	const handleCarSelect = (car: UserCar) => {
		setValue('car', car);
		trigger('car');
	};

	const handleAddressSelect = (fieldName: string, address: Address) => {
		setValue(fieldName as any, address);
		trigger(fieldName as any);
	};

	return (
		<>
			<Header />
			<SideBar
				content={
					<CarSelectForm
						onSelect={car => handleCarSelect(car as UserCar)}
						onClose={() => setSidebarOpen(false)}
						parentErrors={_.get(errors, 'car')}
					/>
				}
				onClose={() => setSidebarOpen(false)}
				isOpen={sidebarOpen}
			/>
			<div id="content" className="d-flex flex-wrap p-0">
				<div className="w100 addform-container d-flex flex-wrap flex-fill p-3">
					<div className="col-sm-12 col-md-3 col-lg-2 p-sm-0 p-md-4 text-right myborder-right light-bg">
						<h3 className="dual-heading">
							ZAMÓW<span>LAWETĘ</span>
						</h3>
					</div>
					<form
						className="planned-container sos-service col-sm-12 col-md-9 col-lg-10 p-sm-0 px-md-4 py-md-3 light-bg"
						onSubmit={handleSubmit(handleSaveForm)}
					>
						<div className="col-sm-12 p-0 d-flex flex-wrap justify-content-between">
							<div className="planned-details col-sm-12 p-0">
								<h4 className="bold mb-0">Dane zgłoszenia</h4>
								<div className="row justify-content-center">
									<div
										className={`col-sm-12 col-md-8 col-xl-8 ${
											!_.isEmpty(errors.address) ? 'invalid-form' : ''
										}`}
									>
										<label>
											Gdzie jesteś?<span className="green big">*</span>
										</label>
										<span>
											Jeśli nie znajdziesz swojego adresu, wpisz samo miasto, lub przyblioną
											lokalizację.
										</span>
										<GoogleAutocompleteComponent
											id="address"
											onChange={address => handleAddressSelect('address', address)}
											error={errors.address ? errors.address : ''}
										/>
										<MapComponent
											lat={_.get(selectedAddress, 'coordinates[0]')}
											lon={_.get(selectedAddress, 'coordinates[1]')}
										/>
									</div>
									<div className="col-sm-12 col-md-4 px-0 col-lg-4 col-xl-4 d-flex flex-wrap align-content-start">
										<div
											className={`col-6 col-md-12 ${
												errors.searchRadius?.message ? 'invalid-form' : ''
											}`}
										>
											<SelectComponent
												label="Obszar wyszukiwania"
												placeholder="Obszar wyszukiwania"
												register={register}
												emptyValue="0"
												options={[
													{ label: '1km', value: '1' },
													{ label: '2km', value: '2' },
													{ label: '5km', value: '5' },
													{ label: '10km', value: '10' },
													{ label: '20km', value: '20' },
													{ label: '50km', value: '50' },
												]}
												id="searchRadius"
												error={errors.searchRadius?.message}
											/>
										</div>
										<div
											className={`col-6 col-md-12 ${
												errors.paymentType?.message ? 'invalid-form' : ''
											}`}
										>
											<SelectComponent
												label="Płatność"
												placeholder="Płatność"
												register={register}
												options={[
													{ label: 'Bezgotówkowa', value: 'contactless' },
													{ label: 'Gotówka', value: 'cash' },
												]}
												id="paymentType"
												error={errors.paymentType?.message}
											/>
										</div>
										<div className={`col-12 ${errors.car ? 'invalid-form' : ''}`}>
											<label>Samochód</label>
											{getFormattedCarInfo(carInfo) && (
												<div className="car-year txt-solid">
													{getFormattedCarInfo(carInfo)}{' '}
													<span
														onClick={resetCar}
														role="presentation"
														className="ml-2 car-reset-button"
													>
														<i className="fa fa-close text-danger" aria-hidden="true" />
													</span>
												</div>
											)}
											<button
												type="button"
												id="sidebarCollapse"
												className="btn btn-primary my-0 col-12"
												onClick={() => setSidebarOpen(true)}
											>
												Wybierz
											</button>
											{errors.car && (
												<div className="invalid-feedback mt-3">Popraw dane samochodu</div>
											)}
										</div>
									</div>
								</div>
								<div id="wheretogo" className="row">
									<div
										className={`col-sm-12 col-lg-8 col-xl-8 active ${
											!_.isEmpty(errors.deliveryAddress) ? 'invalid-form' : ''
										}`}
									>
										<label>
											Dokąd potrzebny jest transport?<span className="green big">*</span>
										</label>
										<span>
											Jeśli nie znajdziesz swojego adresu, wpisz samo miasto, lub przyblioną
											lokalizację.
										</span>
										<GoogleAutocompleteComponent
											id="deliveryAddress"
											onChange={address => handleAddressSelect('deliveryAddress', address)}
											error={errors.deliveryAddress ? errors.address : ''}
										/>
										<MapComponent
											lat={_.get(selectedDeliveryAddress, 'coordinates[0]')}
											lon={_.get(selectedDeliveryAddress, 'coordinates[1]')}
										/>
									</div>
									<div className="col-sm-12 col-lg-4 col-xl-4" />
								</div>

								<div className="row align-items-end">
									<div
										className={`col-6 col-md-3 col-lg-3 col-xl-3 ${
											errors.dueDate?.value?.from?.message ? 'invalid-form' : ''
										}`}
									>
										<label className="text-black">
											Preferowany termin odbioru<span className="green big">*</span>
										</label>
										<TextInputComponent
											id="dueDate.value.from"
											label="Od"
											type="date"
											placeholder="Preferowany termin wizyty"
											register={register}
											error={errors.dueDate?.value?.from?.message}
										/>
									</div>
									<div
										className={`col-6 col-md-3 col-lg-3 col-xl-3 ${
											errors.dueDate?.value?.to?.message ? 'invalid-form' : ''
										}`}
									>
										<TextInputComponent
											id="dueDate.value.to"
											label="Do"
											type="date"
											placeholder="Preferowany termin wizyty"
											register={register}
											error={errors.dueDate?.value?.to?.message}
										/>
									</div>
									<div
										className={`col-6 col-md-3 col-xl-3 ${
											errors.dueDate?.hour?.from?.message ? 'invalid-form' : ''
										}`}
									>
										<label className="text-black">Preferowane godziny</label>
										<TextInputComponent
											id="dueDate.hour.from"
											label="Od"
											type="time"
											placeholder="Od"
											register={register}
											error={errors.dueDate?.hour?.from?.message}
										/>
									</div>
									<div
										className={`col-6 col-md-3 col-xl-3 ${
											errors.dueDate?.hour?.to?.message ? 'invalid-form' : ''
										}`}
									>
										<TextInputComponent
											id="dueDate.hour.to"
											label="Do"
											type="time"
											placeholder="Do"
											register={register}
											error={errors.dueDate?.hour?.to?.message}
										/>
									</div>
								</div>

								<div className="row">
									<div className={`col-sm-12 ${errors.description?.message ? 'invalid-form' : ''}`}>
										<TextareaComponent
											id="description"
											label="Dodatkowe informacje"
											rows={6}
											cols={3}
											placeholder="Opisz problem"
											register={register}
											error={errors.description?.message}
										/>
									</div>
								</div>
							</div>
						</div>
						<ButtonComponent
							type="submit"
							className="btn btn-primary btn-submit mt-3"
							isLoading={isLoading}
							text="Wyślij zgłoszenie"
						/>
					</form>
				</div>
			</div>
		</>
	);
};

export default CarCarrierReportForm;
