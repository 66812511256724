export interface UserCar {
	_id: string;
	manufacturer: string;
	model: string;
	year: string;
	vin: string;
	engine: string;
	fuelType: string;
	transmission: string;
	chassisType: string;
	insurance: string;
	carId: string;
}

export interface ApiError {
	success: boolean;
	errors: any;
}

export enum UserType {
	ADMIN = 'admin',
	COMPANY = 'company',
	USER = 'user',
}

export interface User {
	_id: string;
	createdAt: string;
	name: string;
	surname?: string;
	companyName?: string;
	email: string;
	password: string;
	nip?: string;
	type: string;
	active: boolean;
	failedLogins?: number;
	professions?: { type: string };
	cars?: UserCar[];
	languages: string[];
	phone: string;
	address: Address;
	lastMonthLoginsCount?: number;
	lastLogin?: string;
	reportsCount?: number;
	offersCount?: number;
	services?: string[];
	mobileRepairs: boolean;
	offersInvoice: boolean;
	cardPayments: boolean;
	carCarrier: boolean;
	verified: boolean;
}

export interface LoginRequest {
	email: string;
	password: string;
}

export interface LoginResponse {
	success: boolean;
	user: User;
	token: string;
	serviceDictionary: ServiceDict[] | null;
}

export interface UpdateGarageRequest {
	cars: UserCar[];
}

export interface UpdateGarageResponse {
	success: boolean;
	document: User;
}

export interface RegisterUserResponse {
	success: boolean;
}

export interface UpdateUserResponse {
	success: boolean;
}

export interface GetLocationsRequest {
	query: string;
	token: string;
}

export interface GetLocationDetailsRequest {
	params: string;
}

export interface GetLocationByCoordinatesRequest {
	lat: number | string;
	lon: number | string;
}

export interface GetLocationsResponse {
	success: boolean;
	data: any;
}

export interface GetLocationsResponseData {
	name: string;
	value: string;
}

export interface GetLocationDetailsResponse {
	success: boolean;
	data: AutocompleteLocationDetails;
}

export interface GetLocationByCoordinatesResponse {
	success: boolean;
	data: AutocompleteLocationDetails;
}

export interface AutocompleteLocationDetails {
	country: string;
	houseNumber: string;
	street: string;
	city: string;
	postCode: string;
	coordinates: [number, number];
}

export enum ReportDueDateKindType {
	ASAP = 'asap',
	RANGE = 'range',
}

export interface Report {
	_id: string;
	isAsap?: boolean;
	isOverview?: boolean;
	isMobileService?: boolean;
	createdAt: string;
	offerAcceptedAt: string;
	offerCount: number;
	reportNumber: string;
	kind: string;
	car: UserCar;
	address: Address;
	deliveryAddress: Address;
	dueDate: {
		kind: string;
		hour: {
			from: string;
			to: string;
		};
		value: {
			from: string;
			to: string;
		};
	};
	paymentType: string;
	searchRadius: number;
	labels: string[];
	description: string;
	needsCarrier: boolean;
	companyOffer?: Offer;
	offers: Offer[];
	services?: string[];
	languages: string[];
}

export interface Address {
	country: string;
	city: string;
	postCode: string;
	street: string;
	houseNumber: string;
	coordinates: [number, number];
	location?: {
		type: string;
		coordinates: [number, number];
	};
}

export interface ReportsListResponse {
	success: boolean;
	documents: Report[];
	total: number;
}

export interface ReportDocumentResponse {
	success: boolean;
	document: Report;
}

export interface Service {
	_id: string;
	name: string;
	children: Service[];
	parent?: string;
}

export interface GetServiceResponse {
	success: boolean;
	documents: Service[];
}

export interface ResetPasswordRequest {
	email: string;
}

export interface ResetPasswordResponse {
	success: boolean;
}

export interface ChangePasswordRequest {
	token: string;
	password: string;
}

export interface ChangePasswordResponse {
	success: boolean;
}

export enum ReportType {
	SOS = 'sos',
	VISIT = 'visit',
	CAR_CARRIER = 'car_carrier',
}

export enum PaymentType {
	CASH = 'cash',
	CONTACTLESS = 'contactless',
}

export interface ServiceDict {
	_id: string;
	name: string;
	parent?: string;
	leaf: boolean;
}

export interface UsersListResponse {
	success: boolean;
	documents: Report[];
	total: number;
}

export interface UserDocumentResponse {
	success: boolean;
	document: Report;
}

export interface Offer {
	_id: string;
	createdAt: string;
	creator: User;
	report: string;
	date: string;
	price: string;
	description: string;
	status: string;
	averageRating: string;
	rating: number;
}

export enum OfferStatus {
	WAITING = 'waiting',
	ACCEPTED = 'accepted',
	REJECTED = 'rejected',
}

export interface ChangeOfferStatusRequest {
	reportId: string;
	offerId: string;
	status: string;
}

export interface ChangeOfferStatusResponse {
	success: boolean;
	report: Report;
}

export interface Coordinates {
	lat: number;
	lng: number;
	offerId?: string;
}

export interface ActivateAccountResponse {
	success: boolean;
}

export interface Rating {
	_id: string;
	userName: string;
	description: string | undefined;
	vote: number;
	user: string;
	offerId: string;
}

export interface RatingsListResponse {
	success: boolean;
	documents: Rating[];
	total: number;
}

export interface NipData {
	address: {
		city: string;
		country: string;
		formattedAddress: string;
		houseNumber: string;
		location: {
			type: string;
			coordinates: [number, number];
		};
		postCode: string;
		street: string;
		coordinates: [number, number];
	};
	companyName: string;
	email?: string;
	phone?: string;
}

export interface NipDataResponse {
	success: boolean;
	document: NipData;
}
