import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector, useDebounce } from 'app/hooks';
import { getFormattedAddress, handleApiErrors } from 'app/utils';
import languages from 'assets/dicts/languages.dict.json';
import ButtonComponent from 'components/Button.component';
import CheckboxComponent from 'components/Checkbox.component';
import GoogleAutocompleteComponent from 'components/GoogleAutocomplete.component';
import MapComponent from 'components/Map.component';
import ServicesComponent from 'components/Services.component';
import TextareaComponent from 'components/Textarea.component';
import TextInputComponent from 'components/TextInput.component';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useCreateUserMutation, useUpdateUserMutation, useGetDataByNIPMutation } from 'services/application.service';
import { Address } from 'services/types';
import { ownerEditSchema, ownerSchema } from './registerForm.schema';
import AvatarCropper from './AvatarCropper.component';
import resizeImage from '../../features/auth/resizeImage';
import { defaultAvatar } from '../../assets/images/default-avatar';

interface RegisterFormOwnerValues {
	companyName: string;
	name: string;
	description: string;
	nip: string;
	languages: string[];
	email: string;
	phone: string;
	password?: string;
	password2?: string;
	services: string[];
	address: Address;
	type: string;
	mobileRepairs: boolean;
	cardPayments: boolean;
	carCarrier: boolean;
	offersInvoice: boolean;
	offersOverview: boolean;
	isMobileService: boolean;
	avatar: string;
}

const RegisterFormOwner: React.FC = () => {
	const history = useHistory();
	const location = useLocation();
	const { addToast } = useToasts();
	const [registerUser, { isLoading: isRegisterLoading }] = useCreateUserMutation();
	const [updateUser, { isLoading: isUpdateLoading }] = useUpdateUserMutation();
	const [getDatabyNip, { data: nipData }] = useGetDataByNIPMutation();
	const [isEdit, setIsEdit] = useState(false);
	const user = useAppSelector(state => state.auth.user);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		if (location && location.pathname === '/profile') {
			setIsEdit(true);
		}
	}, [location]);

	useEffect(() => {
		if (user) reset(user);
	}, [user]);

	const {
		register,
		handleSubmit,
		setValue,
		setError,
		reset,
		trigger,
		getValues,
		watch,
		formState: { errors },
	} = useForm<RegisterFormOwnerValues>({
		mode: 'onSubmit',
		defaultValues: user || {},
		resolver: yupResolver(isEdit ? ownerEditSchema : ownerSchema),
		reValidateMode: 'onChange',
	});
	const selectedAddress = watch('address');
	const nipValue = watch('nip');
	const debouncedNipValue = useDebounce(nipValue, 500);

	useEffect(() => {
		if (debouncedNipValue) {
			getDatabyNip(debouncedNipValue);
		}
	}, [debouncedNipValue]);

	useEffect(() => {
		if (nipData?.document) {
			const {
				address,
				address: { formattedAddress },
				companyName,
				email,
				phone,
			} = nipData.document;

			if (!_.isEmpty(companyName)) {
				setValue('companyName', companyName);
			}

			if (email) {
				setValue('email', email);
			}

			if (phone) {
				setValue('phone', phone);
			}

			if (!_.isEmpty(formattedAddress)) {
				const newAddress: Address = {
					country: address.country,
					city: address.city,
					postCode: address.postCode,
					street: address.street,
					houseNumber: address.houseNumber,
					coordinates: address.location?.coordinates,
				};
				setValue('address', newAddress);
				trigger('address');
			}
		}
	}, [nipData]);

	const handleSubmitRegisterUserForm = async (data: RegisterFormOwnerValues): Promise<void> => {
		try {
			data.type = 'company';

			if (isEdit) {
				if (data.password === '') {
					delete data.password;
					delete data.password2;
				}
				const user = await updateUser(data).unwrap();
				if (user.success) {
					addToast('Konto zostało zaktualizowane');
				}
			} else {
				const user = await registerUser(data).unwrap();
				if (user.success) {
					history.replace('/login');
					addToast('Konto zostało założone');
				}
			}
		} catch (err: any) {
			handleApiErrors(setError, err);
			addToast('Błąd autoryzacji');
		}
	};

	const handleAddressSelect = (fieldName: string, address: Address) => {
		setValue(fieldName as any, address);
		trigger(fieldName as any);
	};

	const handleSelectLanguage = (languageCode: string) => {
		const selectedLanguages = getValues('languages') || [];
		const languageIndex: number = _.findIndex(selectedLanguages, langCode => langCode === languageCode);

		if (languageIndex === -1) {
			selectedLanguages.push(languageCode);
		}

		setValue('languages' as any, selectedLanguages);
		trigger('languages' as any);
	};

	const handleRemoveLanguage = (languageCode: string) => {
		const selectedLanguages = getValues('languages')?.filter(lang => lang !== languageCode);

		setValue('languages' as any, selectedLanguages);
		trigger('languages' as any);
	};

	const getLanguage = (languageCode: string) => _.find(languages, lang => lang.value === languageCode);

	const handleAvatarCrop = (avatar: string | null) => {
		if (avatar)
			resizeImage(avatar, 150, 150).then((resizedImage: any) => {
				setValue('avatar' as any, resizedImage);
				trigger('avatar' as any);
				setShowModal(false);
			});
		else setShowModal(false);
	};
	const handleClickPrivacy = () => {
		window.location.href = 'https://auto-help24.pl/wp-content/uploads/2022/08/Polityka_Prywatnosci.pdf';
	};
	const handleClickTerms = () => {
		window.location.href = 'https://auto-help24.pl/wp-content/uploads/2022/08/Regulamin_serwisu.pdf';
	};

	return (
		<div id="box2" className="box register-form mt-0">
			<form onSubmit={handleSubmit(handleSubmitRegisterUserForm)}>
				{isEdit && (
					<div className="row d-flex align-items-end">
						<div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 avatar-col">
							<img
								role="presentation"
								src={getValues('avatar') || defaultAvatar}
								className="avatar"
								alt="avatar"
								onClick={() => setShowModal(prev => !prev)}
							/>
							{showModal && <AvatarCropper onCrop={handleAvatarCrop} />}
						</div>
					</div>
				)}
				<div className="row row-cols-2 d-flex align-items-end">
					<div
						className={`${
							errors.companyName?.message && 'invalid-form'
						} col-sm-12 col-md-6 col-lg-4 col-xl-4`}
					>
						<TextInputComponent
							id="companyName"
							label="Podstawowe dane"
							type="text"
							placeholder="Nazwa firmy"
							register={register}
							error={errors.companyName?.message}
						/>
					</div>
					<div className={`${errors.name?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
						<TextInputComponent
							id="name"
							type="text"
							placeholder="Twoje imię i nazwisko"
							register={register}
							error={errors.name?.message}
						/>
					</div>
				</div>
				<div className="row row-cols-2 d-flex align-items-end">
					<div className={`${errors.nip?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
						<TextInputComponent
							id="nip"
							type="text"
							placeholder="NIP"
							register={register}
							error={errors.nip?.message}
						/>
					</div>
					<div
						className={`${
							(errors.languages as any)?.message && 'invalid-form'
						} col-sm-12 col-md-6 col-lg-4 col-xl-4 d-flex flex-wrap`}
					>
						{getValues('languages') &&
							getValues('languages').map((languageCode: string) => (
								<div className="subcategory-list" key={`${languageCode}-language-selected-option`}>
									<div role="presentation" className="selected-option mr-3">
										<span> {getLanguage(languageCode)?.label}</span>
										<i
											className="fas fa-times ml-1"
											role="presentation"
											onClick={() => handleRemoveLanguage(languageCode)}
										/>
									</div>
								</div>
							))}
						<select onChange={(e: any) => handleSelectLanguage(e.target.value)} className="w-100">
							<option value="">Wybierz</option>
							{languages.map((language: { value: string; label: string }) => (
								<option value={language.value} key={language.value}>
									{language.label}
								</option>
							))}
						</select>
						{(errors.languages as any)?.message && (
							<div className="invalid-feedback">{(errors.languages as any)?.message}</div>
						)}
					</div>
				</div>
				<div className="row row-cols-2 d-flex align-items-start">
					<div
						className={`${
							errors.description?.message && 'invalid-form'
						} col-sm-12 col-md-8 col-lg-8 col-xl-8`}
					>
						<TextareaComponent
							id="description"
							rows={4}
							cols={4}
							placeholder="Opis"
							register={register}
							error={errors.description?.message}
						/>
					</div>
				</div>
				<div className="row d-flex align-items-end">
					<div className={`${errors.email?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
						<TextInputComponent
							id="email"
							label="Dane kontaktowe"
							type="text"
							placeholder="Twój adres e-mail"
							register={register}
							error={errors.email?.message}
						/>
					</div>
					<div className={`${errors.phone?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}>
						<TextInputComponent
							id="phone"
							type="text"
							placeholder="Twój numer telefonu"
							register={register}
							error={errors.phone?.message}
						/>
					</div>
				</div>
				<div className="row d-flex align-items-end">
					<div
						className={`${errors.password?.message && 'invalid-form'} col-sm-12 col-md-6 col-lg-4 col-xl-4`}
					>
						<TextInputComponent
							id="password"
							label="Hasło"
							type="password"
							placeholder="Twoje hasło"
							register={register}
							error={errors.password?.message}
						/>
					</div>
					<div
						className={`${
							(errors.password2?.message || errors.password?.message) && 'invalid-form'
						} col-sm-12 col-md-6 col-lg-4 col-xl-4`}
					>
						<TextInputComponent
							id="password2"
							type="password"
							placeholder="Powtórz hasło"
							register={register}
							error={errors.password2?.message}
						/>
					</div>
				</div>

				<div className="row">
					<div className={` ${errors.address && 'invalid-form'} col-sm-12 `}>
						<label>Adres</label>
						<span>Jeśli nie znajdziesz swojego adresu, wpisz samo miasto, lub przyblioną lokalizację.</span>
						<GoogleAutocompleteComponent
							defaultValue={user ? getFormattedAddress(user.address) : ''}
							id="address"
							onChange={address => handleAddressSelect('address', address)}
							error={errors.address ? errors.address : ''}
						/>
						<MapComponent
							lat={_.get(selectedAddress, 'coordinates[0]')}
							lon={_.get(selectedAddress, 'coordinates[1]')}
						/>
					</div>
				</div>

				<div className="row align-items-end">
					<div className="col-sm-12 col-md-12 d-flex flex-wrap">
						<label>Inne informacje</label>
						<div className="checkbox col-sm-12 col-md-4 col-lg-6">
							<CheckboxComponent
								id="mobileRepairs"
								register={register}
								label="Naprawy z dojazdem"
								error={errors.mobileRepairs?.message}
							/>
						</div>
						<div className="checkbox col-sm-12 col-md-4 col-lg-6">
							<CheckboxComponent
								id="carCarrier"
								register={register}
								label="Transport lawetą"
								error={errors.carCarrier?.message}
							/>
						</div>
						<div className="checkbox col-sm-12 col-md-4 col-lg-6">
							<CheckboxComponent
								id="cardPayments"
								register={register}
								label="Terminal płatniczy / płatność kartą"
								error={errors.cardPayments?.message}
							/>
						</div>
						<div className="checkbox col-sm-12 col-md-4 col-lg-6">
							<CheckboxComponent
								id="offersInvoice"
								register={register}
								label="Możliwość wystawienia faktury"
								error={errors.offersInvoice?.message}
							/>
						</div>
						<div className="checkbox col-sm-12 col-md-4 col-lg-6">
							<CheckboxComponent
								id="offersOverview"
								register={register}
								label="Przeglądy samochodowe"
								error={errors.offersOverview?.message}
							/>
						</div>
						<div className="checkbox col-sm-12 col-md-4 col-lg-6">
							<CheckboxComponent
								id="isMobileService"
								register={register}
								label="Mobilny mechanik"
								error={errors.isMobileService?.message}
							/>
						</div>
					</div>
				</div>

				<ServicesComponent getValues={getValues} setValue={setValue} watch={watch} />

				<div className="row">
					<div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-3">
						<ButtonComponent
							type="submit"
							className="btn btn-submit btn-primary"
							text={isEdit ? 'Zapisz zmiany' : 'Załóż profil'}
							isLoading={isRegisterLoading || isUpdateLoading}
						/>
					</div>
				</div>

				<div className="row">
					<div
						className="col-sm-12 col-md-6 col-lg-4 col-xl-4 pt-3"
						role="presentation"
						onClick={handleClickPrivacy}
						onKeyDown={handleClickPrivacy}
					>
						Polityka prywatności
					</div>
				</div>
				<div className="row">
					<div
						className="col-sm-12 col-md-6 col-lg-4 col-xl-4 "
						role="presentation"
						onClick={handleClickTerms}
						onKeyDown={handleClickTerms}
					>
						Regulamin serwisu
					</div>
				</div>
			</form>
		</div>
	);
};

export default RegisterFormOwner;
