import { yupResolver } from '@hookform/resolvers/yup';
import CheckboxComponent from 'components/Checkbox.component';
import TextInputComponent from 'components/TextInput.component';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

interface ReportBoardFiltersComponentProps {
	onSubmit: (filters: any) => void;
}

const ReportBoardFilters: React.FC<ReportBoardFiltersComponentProps> = ({
	onSubmit,
}: ReportBoardFiltersComponentProps) => {
	const {
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
		watch,
	} = useForm<Record<string, any>>({
		resolver: yupResolver(
			yup.object({
				dateFrom: yup.string(),
				dateTo: yup.string(),
				onlyOwnServices: yup.boolean(),
				status: yup.string(),
			}),
		),
		reValidateMode: 'onChange',
		defaultValues: {
			status: 'new',
		},
	});

	const dateFrom = watch('dateFrom');
	const dateTo = watch('dateTo');
	const onlyOwnServices = watch('onlyOwnServices');
	const status = watch('status');

	useEffect(() => {
		onSubmit(getValues());
	}, [dateFrom, dateTo, onlyOwnServices, status]);

	const changeStatus = (statusName: string) => {
		if(status !== statusName) {
			setValue("status", statusName);
			onSubmit(getValues());
		}
	}

	return (
		<form
			id="filters-container"
			className="col-sm-12 col-md-12 col-lg-12 flex-wrap align-items-start p-0 collapse show"
			onSubmit={handleSubmit(onSubmit)}
		>
			<div className="col-sm-12 col-md-2 col-lg-2 p-0 px-1">
				<TextInputComponent
					id="dateFrom"
					label="Data od"
					type="date"
					placeholder="dd.mm.rrrr"
					register={register}
					error={errors.dateFrom?.message}
				/>
			</div>
			<div className="col-sm-12 col-md-2 col-lg-2 p-0 px-1">
				<TextInputComponent
					id="dateTo"
					label="Data do"
					type="date"
					placeholder="dd.mm.rrrr"
					register={register}
					error={errors.dateTo?.message}
				/>
			</div>
			<div className="col-sm-12 col-md-3 col-lg-3 filter-group p-0 px-md-3">
				<label>Filtry</label>
				<CheckboxComponent
					customClass="checkbox mr-2"
					id="onlyOwnServices"
					register={register}
					label="Mój zakres usług"
					error={errors.onlyOwnServices?.message}
				/>
			</div>
			<div className="col-sm-12 col-md-5 col-lg-5 filter-group p-0 d-flex flex-wrap">
				<label>Status ofert</label>
				<div className="checkbox mr-3">
					<label>
						<input type="checkbox" checked={status === 'new'} onChange={() => changeStatus("new")}/>
						<span className="cr">
							<i className="cr-icon fa fa-square" />
						</span>
						Nowe
					</label>
				</div>
				<div className="checkbox mr-3">
					<label>
						<input type="checkbox" checked={status === 'waiting'} onChange={() => changeStatus("waiting")}/>
						<span className="cr">
							<i className="cr-icon fa fa-square" />
						</span>
						Wysłane
					</label>
				</div>
				<div className="checkbox mr-3">
					<label>
						<input type="checkbox" checked={status === 'accepted'} onChange={() => changeStatus("accepted")}/>
						<span className="cr">
							<i className="cr-icon fa fa-square" />
						</span>
						Przyjęte
					</label>
				</div>
				<div className="checkbox mr-3">
					<label>
						<input type="checkbox" checked={status === 'rejected'} onChange={() => changeStatus("rejected")}/>
						<span className="cr">
							<i className="cr-icon fa fa-square" />
						</span>
						Odrzucone
					</label>
				</div>
			</div>
			<div className="filter-group p-0 pl-md-3" />
		</form>
	);
};

export default ReportBoardFilters;
