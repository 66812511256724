import * as yup from 'yup';

export default yup
	.object({
		manufacturer: yup.string().required('Marka jest wymagana'),
		model: yup.string().required('Model jest wymagany'),
		year: yup.string().required('Rok produkcji jest wymagana'),
		vin: yup.string(),
		engine: yup.string().required('Rodzaj silnika jest wymagany'),
		fuelType: yup.string().required('Rodzaj paliwa jest wymagany'),
		transmission: yup.string().required('Rodzaj skrzynii biegów jest wymagany'),
		chassisType: yup.string().required('Rodzaj nadwozia jest wymagany'),
		insurance: yup.string(),
	})
	.required();
