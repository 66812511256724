import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/api';
import {
	LoginRequest,
	LoginResponse,
	User,
	RegisterUserResponse,
	UpdateUserResponse,
	Service,
	ResetPasswordRequest,
	ResetPasswordResponse,
	ChangePasswordResponse,
	ChangePasswordRequest,
	ActivateAccountResponse,
	NipDataResponse,
} from './types';

const section = 'application';

export const appApi = createApi({
	baseQuery,
	tagTypes: ['User', 'Services'],
	endpoints: builder => ({
		// any because different login strategies
		socialLogin: builder.mutation<LoginResponse, any>({
			query: credentials => ({
				url: `${section}/socialLogin`,
				method: 'POST',
				body: credentials,
			}),
		}),
		login: builder.mutation<LoginResponse, LoginRequest>({
			query: credentials => ({
				url: `${section}/login`,
				method: 'POST',
				body: credentials,
			}),
		}),
		isAuth: builder.query<LoginResponse, void>({
			query: () => ({
				url: `${section}/isAuth`,
			}),
			providesTags: ['User'],
		}),
		resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordRequest>({
			query: credentials => ({
				url: `${section}/resetPassword`,
				method: 'POST',
				body: credentials,
			}),
		}),
		changePassword: builder.mutation<ChangePasswordResponse, ChangePasswordRequest>({
			query: data => ({
				url: `${section}/resetPassword`,
				method: 'PUT',
				body: data,
			}),
		}),
		createUser: builder.mutation<RegisterUserResponse, Partial<User>>({
			query: data => ({
				url: `${section}/register`,
				method: 'POST',
				body: data,
			}),
		}),
		updateUser: builder.mutation<UpdateUserResponse, Partial<User>>({
			query: data => ({
				url: `${section}/user`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: ['User'],
		}),
		getServicesList: builder.query<[Service], void>({
			query: () => ({
				url: `${section}/dictionary`,
			}),
			transformResponse: (response: { documents: [Service] }) => response.documents,
			providesTags: ['Services'],
		}),
		getServicesTree: builder.query<[Service], void>({
			query: () => ({
				url: `${section}/getServicesTree`,
			}),
			transformResponse: (response: { documents: [Service] }) => response.documents,
			providesTags: ['Services'],
		}),
		activateAccount: builder.mutation<ActivateAccountResponse, string>({
			query: token => ({
				url: `${section}/activateAccount/${token}`,
				method: 'PUT',
			}),
		}),
		getDataByNIP: builder.mutation<NipDataResponse, string>({
			query: nip => ({
				url: `${section}/getDataByNip/${nip}`,
				method: 'GET',
			}),
		}),
	}),
});

export const {
	useLoginMutation,
	useIsAuthQuery,
	useCreateUserMutation,
	useUpdateUserMutation,
	useGetServicesTreeQuery,
	useGetServicesListQuery,
	useResetPasswordMutation,
	useChangePasswordMutation,
	useActivateAccountMutation,
	useSocialLoginMutation,
	useGetDataByNIPMutation,
} = appApi;
