import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector } from 'app/hooks';
import { getFormattedCarInfo, handleApiErrors } from 'app/utils';
import ButtonComponent from 'components/Button.component';
import CheckboxComponent from 'components/Checkbox.component';
import GoogleAutocompleteComponent from 'components/GoogleAutocomplete.component';
import Header from 'components/Header.component';
import MapComponent from 'components/Map.component';
import SelectComponent from 'components/Select.component';
import ServicesComponent from 'components/Services.component';
import SideBar from 'components/Sidebar.component';
import TextareaComponent from 'components/Textarea.component';
import TextInputComponent from 'components/TextInput.component';
import _ from 'lodash';
import moment from 'moment';
import schema from 'pages/reports/visitReportForm.schema';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import { useCreateReportMutation } from 'services/report.service';
import { Address, Report, UserCar } from 'services/types';
import CarSelectForm from './CarSelectForm.component';

const VisitReportForm: React.FC = () => {
	const {
		register,
		handleSubmit,
		setValue,
		watch,
		setError,
		trigger,
		getValues,
		formState: { errors },
	} = useForm<Partial<Report>>({
		resolver: yupResolver(schema),
		defaultValues: {
			kind: 'visit',
			isAsap: false,
			dueDate: {
				kind: 'range',
				value: {
					from: moment().add(1, 'day').format('YYYY-MM-DD'),
					to: moment().add(2, 'days').format('YYYY-MM-DD'),
				},
				hour: {
					from: moment().startOf('day').format('HH:mm'),
					to: moment().endOf('day').format('HH:mm'),
				},
			},
		},
		reValidateMode: 'onChange',
	});
	const { addToast } = useToasts();
	const [createReport, { isLoading }] = useCreateReportMutation();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const history = useHistory();
	const loggedUserLanguages: string[] = useAppSelector(state => state.auth.user?.languages || []);
	const carInfo = watch('car');
	const selectedAddress = watch('address');
	const isAsap = watch('isAsap');
	const isOverview = watch('isOverview');
	const kind = watch('kind');
	const formatDateData = (value: string) => moment(value).format('X');

	useEffect(() => {
		// clear services when user choose isOverview
		if (isOverview === true) {
			setValue('services', undefined);
		}
	}, [isOverview]);

	const handleSaveForm = async (data: Partial<Report>) => {
		try {
			if (data.isAsap && data.dueDate) {
				data.dueDate.kind = 'asap';
			}

			if (data.dueDate?.value?.from) {
				data.dueDate.value.from = formatDateData(data.dueDate.value.from);
			}
			if (data.dueDate?.value?.to) {
				data.dueDate.value.to = formatDateData(data.dueDate.value.to);
			}
			data.languages = loggedUserLanguages;
			await createReport(data).unwrap();
			addToast('Dodano zgłoszenie');
			history.push('/reports');
		} catch (err) {
			addToast('Wystąpił błąd');
			handleApiErrors(setError, err);
		}
	};

	const resetCar = () => {
		setValue('car', {
			_id: '',
			manufacturer: '',
			model: '',
			year: '',
			vin: '',
			engine: '',
			fuelType: '',
			transmission: '',
			chassisType: '',
			insurance: '',
			carId: '',
		});
	};

	const handleCarSelect = (car: UserCar) => {
		setValue('car', car);
		trigger('car');
	};

	const handleAddressSelect = (fieldName: string, address: Address) => {
		setValue(fieldName as any, address);
		trigger(fieldName as any);
	};

	return (
		<>
			<Header />
			<SideBar
				content={
					<CarSelectForm
						onSelect={car => handleCarSelect(car as UserCar)}
						onClose={() => setSidebarOpen(false)}
						parentErrors={_.get(errors, 'car')}
					/>
				}
				onClose={() => setSidebarOpen(false)}
				isOpen={sidebarOpen}
			/>
			<div id="content" className="d-flex flex-wrap p-0">
				<div className="w100 addform-container d-flex flex-wrap flex-fill p-3">
					<div className="col-sm-12 col-md-2 p-sm-0 p-md-4 text-right myborder-right light-bg">
						<h3 className="dual-heading">
							UMÓW<span>WIZYTĘ</span>
						</h3>
					</div>
					<div className="planned-container col-sm-12 col-md-10 p-sm-0 px-md-5 py-md-3 light-bg">
						<form onSubmit={handleSubmit(handleSaveForm)}>
							<div className="col-sm-12 p-0 d-flex flex-wrap justify-content-between">
								<div className="planned-details col-sm-12 p-0">
									<h4 className="bold mb-0">Dane zgłoszenia</h4>
									<div className="row justify-content-center">
										<div
											className={`col-sm-12 col-md-8 col-xl-8 ${
												errors.address && 'invalid-form'
											}`}
										>
											<label>
												Gdzie jesteś?<span className="green big">*</span>
											</label>
											<span>
												Jeśli nie znajdziesz swojego adresu, wpisz samo miasto, lub przyblioną
												lokalizację.
											</span>
											<GoogleAutocompleteComponent
												id="address"
												onChange={address => handleAddressSelect('address', address)}
												error={errors.address ? errors.address : ''}
											/>
											<MapComponent
												lat={_.get(selectedAddress, 'coordinates[0]')}
												lon={_.get(selectedAddress, 'coordinates[1]')}
											/>
										</div>
										<div className="col-sm-12 col-md-4 px-0 col-lg-4 col-xl-4 d-flex flex-wrap align-content-start">
											<div className={`col-6 col-md-12 ${errors.searchRadius && 'invalid-form'}`}>
												<SelectComponent
													label="Obszar wyszukiwania"
													placeholder="Obszar wyszukiwania"
													register={register}
													emptyValue="0"
													options={[
														{ label: '1km', value: '1' },
														{ label: '2km', value: '2' },
														{ label: '5km', value: '5' },
														{ label: '10km', value: '10' },
														{ label: '20km', value: '20' },
														{ label: '50km', value: '50' },
													]}
													id="searchRadius"
													error={errors.searchRadius?.message}
												/>
											</div>
											<div
												className={`col-6 col-md-12  ${
													errors.paymentType?.message && 'invalid-form'
												}`}
											>
												<SelectComponent
													label="Płatność"
													placeholder="Płatność"
													register={register}
													options={[
														{ label: 'Bezgotówkowa', value: 'contactless' },
														{ label: 'Gotówka', value: 'cash' },
													]}
													id="paymentType"
													error={errors.paymentType?.message}
												/>
											</div>
											<div className={`col-12  ${errors.car && 'invalid-form'}`}>
												<label>Samochód</label>
												{getFormattedCarInfo(carInfo) && (
													<div className="car-year txt-solid">
														{getFormattedCarInfo(carInfo)}{' '}
														<span
															onClick={resetCar}
															role="presentation"
															className="ml-2 car-reset-button"
														>
															<i className="fa fa-close text-danger" aria-hidden="true" />
														</span>
													</div>
												)}
												<button
													type="button"
													id="sidebarCollapse"
													className="btn btn-primary my-0 col-12"
													onClick={() => setSidebarOpen(true)}
												>
													Wybierz
												</button>
												{errors.car && (
													<div className="invalid-feedback mt-3">Popraw dane samochodu</div>
												)}
											</div>
											<div className="col-6">
												<label>Inne</label>
												<CheckboxComponent
													id="isAsap"
													register={register}
													label="Jak najszybciej"
													error={errors.isAsap?.message}
												/>
											</div>
											<div className="col-6">
												<label>Inne</label>
												<CheckboxComponent
													id="isOverview"
													register={register}
													label="Przegląd samochodowy"
													error={errors.isOverview?.message}
												/>
											</div>
											<div className="col-12">
												<label>Inne</label>
												<CheckboxComponent
													id="isMobileService"
													register={register}
													label="Mobilna usługa"
													error={errors.isMobileService?.message}
												/>
											</div>
										</div>
									</div>

									{!isAsap && (
										<div id="date-container" className="row collapse show align-items-end">
											<div
												className={`col-6 col-md-3 col-lg-3 col-xl-3  ${
													errors.dueDate?.value?.from && 'invalid-form'
												}`}
											>
												<label className="text-black">
													Preferowany termin <span className="green big">*</span>
												</label>

												<TextInputComponent
													id="dueDate.value.from"
													label="Od"
													type="date"
													placeholder="Preferowany termin wizyty"
													register={register}
													error={errors.dueDate?.value?.from?.message}
												/>
											</div>
											<div
												className={`col-6 col-md-3 col-lg-3 col-xl-3 ${
													errors.dueDate?.value?.to && 'invalid-form'
												}`}
											>
												<TextInputComponent
													id="dueDate.value.to"
													label="Do"
													type="date"
													placeholder="Preferowany termin wizyty"
													register={register}
													error={errors.dueDate?.value?.to?.message}
												/>
											</div>
											<div
												className={`col-6 col-md-3 col-xl-3 ${
													errors.dueDate?.hour?.from && 'invalid-form'
												}`}
											>
												<label className="text-black">Preferowane godziny</label>
												<TextInputComponent
													id="dueDate.hour.from"
													label="Od"
													type="time"
													register={register}
													error={errors.dueDate?.hour?.from?.message}
												/>
											</div>
											<div
												className={`col-6 col-md-3 col-xl-3 ${
													errors.dueDate?.hour?.to && 'invalid-form'
												}`}
											>
												<TextInputComponent
													id="dueDate.hour.to"
													label="Do"
													type="time"
													register={register}
													error={errors.dueDate?.hour?.to?.message}
												/>
											</div>
										</div>
									)}

									{!isOverview && (
										<ServicesComponent
											getValues={getValues}
											setValue={setValue}
											watch={watch}
											error={errors.services}
										/>
									)}

									<div className="row">
										<div className={`col-sm-12 ${errors.description && 'invalid-form'}`}>
											<TextareaComponent
												id="description"
												label="Dodatkowe informacje"
												rows={6}
												cols={3}
												placeholder="Opisz problem"
												register={register}
												error={errors.description?.message}
											/>
										</div>
									</div>
								</div>
							</div>
							<ButtonComponent
								type="submit"
								className="btn btn-primary btn-submit mt-3"
								isLoading={isLoading}
								text="Wyślij zgłoszenie"
							/>
						</form>
					</div>
				</div>
			</div>
		</>
	);
};

export default VisitReportForm;
