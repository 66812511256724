import React from 'react';

interface TextareaComponentProps {
	id: string;
	placeholder?: string;
	label?: string;
	error?: string | undefined;
	register: any;
	rows: number;
	cols: number;
}

const TextareaComponent: React.FC<TextareaComponentProps> = ({
	id,
	placeholder,
	label,
	error,
	register,
	rows,
	cols,
}) => (
	<>
		{label && <label>{label}</label>}
		<textarea {...register(id)} rows={rows} cols={cols} placeholder={placeholder} />
		{error && <div className="invalid-feedback">{error}</div>}
	</>
);

export default TextareaComponent;
