import React, { useState, useRef, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ButtonComponent from '../../components/Button.component';

interface AvatarCropperProps {
	onCrop: (data: string | null) => void;
}
function AvatarDropzone({ selectFile }: any) {
	const { acceptedFiles, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
		accept: { 'image/*': ['.jpg', '.png', '.jpeg', '.gif'] },
		maxFiles: 1,
	});

	const style = useMemo(
		() => ({
			...(isDragActive ? { borderColor: '#ffc107' } : {}),
			...(isDragAccept ? { borderColor: '#198754' } : {}),
			...(isDragReject ? { borderColor: '#dc3545' } : {}),
		}),
		[isDragActive, isDragReject, isDragAccept],
	);

	useEffect(() => {
		if (acceptedFiles.length > 0) {
			selectFile(URL.createObjectURL(acceptedFiles[0]));
		}
	}, [acceptedFiles, selectFile]);

	return (
		<div className="avatar-dropzone" {...getRootProps({ style })}>
			<input {...getInputProps()} />
			{/* <p>Drag and drop avatar image, or click to select it</p> */}
			<p>Przeciągnij i upuść tutaj zdjęcie, lub kliknij żeby zimportować</p>
		</div>
	);
}

const AvatarCropper: React.FC<AvatarCropperProps> = ({ onCrop }) => {
	const [file, setFile] = useState(null);
	const cropperRef = useRef<HTMLImageElement>(null);

	const handleCrop = (action: string) => {
		if (file && action === 'save') {
			const imageElement: any = cropperRef?.current;
			const cropData: string = imageElement?.cropper.getCroppedCanvas().toDataURL();
			onCrop(cropData.length ? cropData : null);
		} else {
			onCrop(null);
			setFile(null);
		}
	};

	return ReactDOM.createPortal(
		<>
			<div className="modal-cropper">
				<AvatarDropzone selectFile={setFile} />
				{file && (
					<Cropper
						src={file}
						background
						className="avatar-cropper"
						ref={cropperRef}
						aspectRatio={1 / 1}
						cropBoxMovable={false}
						cropBoxResizable={false}
						dragMode="move"
						movable
						viewMode={1}
					/>
				)}
				<div className="row">
					{file && (
						<div className="col-6">
							<ButtonComponent
								type="button"
								className="btn btn-primary avatar-save-button"
								text="Zapisz"
								onClick={() => handleCrop('save')}
							/>
						</div>
					)}
					<div className={file ? 'col-6' : 'col-12'}>
						<ButtonComponent
							type="button"
							className="btn btn-danger avatar-cancel-button"
							text="Anuluj"
							onClick={() => handleCrop('cancel')}
						/>
					</div>
				</div>
			</div>
			<div className="modal-cropper__shadow" />
		</>,
		document.querySelector('#modal')!,
	);
};
export default AvatarCropper;
