import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'app/api';
import { UpdateGarageRequest, UpdateGarageResponse } from './types';

const section = 'application';

export const garageApi = createApi({
	baseQuery,
	reducerPath: "garageApi",
	endpoints: builder => ({
		updateGarage: builder.mutation<UpdateGarageResponse, UpdateGarageRequest>({
			query: data => ({
				url: `${section}/user`,
				method: 'PUT',
				body: data
			}),
		}),
	}),
});

export const { useUpdateGarageMutation } = garageApi;
