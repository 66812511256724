import React, { useState, useEffect } from 'react';
import * as Scroll from 'react-scroll';
import { useParams } from 'react-router';
import moment from 'moment';
import _ from 'lodash';
import { useToasts } from 'react-toast-notifications';
import { Offer, Coordinates, ChangeOfferStatusRequest } from 'services/types';
import separator from 'assets/images/x-sep.svg';
import sygnet from 'assets/images/logo-wh-sygnet.svg';
import {
	capitalizeFirstLetter,
	getChassisTypeClass,
	getFuelTypeName,
	getTransmissionName,
	calculateElapsedTime,
	getServiceBreadCrumb,
} from 'app/utils';
import Header from 'components/Header.component';
import { useGetReportQuery, useChangeOfferStatusMutation } from 'services/report.service';
import SideBar from 'components/Sidebar.component';
import SideMap from 'components/SideMap.component';
import MyReportOfferDetails from './MyReportOfferDetails.component';
import MyReportOfferCard from './MyReportOfferCard.component';

const MyReport: React.FC = () => {
	const [changeOfferStatus] = useChangeOfferStatusMutation();
	const { addToast } = useToasts();
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const [toggleDescription, setToggleDescription] = useState(true);
	const [toggleMap, setToggleMap] = useState(false);
	const [isOfferAccepted, setIsOfferAccepted] = useState(false);
	const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
	const [workshopsCoordinates, setWorkshopsCoordinates] = useState<Coordinates[] | any>(null);
	const { id } = useParams<{ id: string }>();
	const { data: report } = useGetReportQuery(id);

	useEffect(() => {
		if (report) {
			getOfferCoordinates();
			checkIfAtLeastOneOfferAccepted();
		}
	}, [report]);

	const handleCloseSidebar = () => {
		setSidebarOpen(false);
		setSelectedOffer(null);
	};

	const parseDate = (date: string) => {
		if (date) return moment(date).format('DD.MM.YYYY');
		return '-';
	};

	const parsePaymentType = (paymentType: string) => {
		switch (paymentType) {
			case 'contactless':
				return 'Bezgotówkowa';
			case 'cash':
				return 'Gotówka';
			default:
				return '-';
		}
	};

	const renderDueDate = () => {
		if (report?.dueDate?.kind === 'asap') {
			return <div className="txt-solid">Jak najszybciej</div>;
		}
		return (
			<>
				<div className="date-from txt-solid">{parseDate(_.get(report, 'dueDate.value.from', null))}</div>
				<div className="separator">
					<img src={separator} alt="separator" />
				</div>
				<div className="date-to txt-solid">{parseDate(_.get(report, 'dueDate.value.to', null))}</div>
				<div className="break" />
				<label className="green">Preferowany czas usługi</label>

				<div className="time-from txt-solid mr-0">{_.get(report, 'dueDate.hour.from', '-')}</div>
				<div className="separator">
					<img src={separator} alt="separator" />
				</div>
				<div className="time-to txt-solid pr-3">{_.get(report, 'dueDate.hour.to', '-')}</div>
			</>
		);
	};

	const renderOffers = () => {
		if (report?.offers?.length) {
			return report.offers.map((offer: Offer) => (
				<MyReportOfferCard
					key={offer._id}
					offer={offer}
					setSidebarOpen={setSidebarOpen}
					setSelectedOffer={setSelectedOffer}
					offerActive={offer._id === selectedOffer?._id}
				/>
			));
		}
		return (
			<div className="noresults text-center">
				<hr className="darkgray" />
				<img src={sygnet} className="col-5 col-md-2 my-3" alt="sygnet" />
				<h5 className="pr-3 pl-0 my-3">Poczekaj na pierwsze oferty od mechaników!</h5>
			</div>
		);
	};

	const checkIfAtLeastOneOfferAccepted = () => {
		if (report?.offers?.length) {
			report.offers.forEach((offer: Offer) => {
				if (offer.status === 'accepted') {
					setIsOfferAccepted(true);
				}
			});
		}
	};

	const getOfferCoordinates = () => {
		if (report?.offers?.length) {
			const coordinates: Coordinates[] = [];
			report.offers.forEach((offer: Offer) => {
				if (offer?.creator?.address?.location?.coordinates.length) {
					coordinates.push({
						offerId: offer._id,
						lat: offer.creator.address.location.coordinates[0],
						lng: offer.creator.address.location.coordinates[1],
					});
				}
			});

			setWorkshopsCoordinates(coordinates);
		}
	};

	const handleAcceptRejectOffer = async (decision: string) => {
		if (report?._id && selectedOffer?._id && decision) {
			const data: ChangeOfferStatusRequest = {
				reportId: report._id,
				offerId: selectedOffer._id,
				status: decision,
			};

			try {
				await changeOfferStatus(data).unwrap();
			} catch (err) {
				addToast('Wystąpił błąd');
			}

			setSelectedOffer(null);
			setSidebarOpen(false);
		}
	};

	const renderServices = () => {
		const breadCrumbArray = getServiceBreadCrumb(_.get(report, 'services[0]'));

		if (breadCrumbArray.length) {
			return (
				<>
					{breadCrumbArray[0] && <div className="cat-1level txt-solid">{breadCrumbArray[0]}</div>}

					{breadCrumbArray[1] && (
						<>
							<div className="separator">
								<img src={separator} alt="separator" />
							</div>
							<div className="cat-2level txt-solid">{breadCrumbArray[1]}</div>
						</>
					)}

					{breadCrumbArray[2] && (
						<>
							<div className="separator">
								<img src={separator} alt="separator" />
							</div>
							<div className="cat-2level txt-solid">{breadCrumbArray[2]}</div>
						</>
					)}
				</>
			);
		}

		return '-';
	};

	const handleMarkerClick = (offerId: string) => {
		// let goToContainer = new Promise<void>((resolve, reject) => {
		// 	Scroll.Events.scrollEvent.register('end', () => {
		// 	  resolve();
		// 	  Scroll.Events.scrollEvent.remove('end');
		// 	});

		// 	Scroll.scroller.scrollTo('scroll-container', {
		// 	  duration: 800,
		// 	  delay: 0,
		// 	  smooth: 'easeInOutQuart'
		// 	});

		//   });

		//   goToContainer.then(() =>
		// 	Scroll.scroller.scrollTo(offerId, {
		// 	  duration: 800,
		// 	  delay: 0,
		// 	  smooth: 'easeInOutQuart',
		// 	  containerId: 'scroll-container'
		// 	}));

		// scroll to offer

		// Scroll.scroller.scrollTo(offerId, {
		// 	duration: 800,
		// 	delay: 0,
		// 	smooth: 'easeInOutQuart',
		// 	// containerId: 'scroll-container',
		// });

		// make offer active
		const offer = _.find(report?.offers, { _id: offerId });
		if (offer) {
			setSelectedOffer(offer);
			setSidebarOpen(true);
		}
	};

	return (
		<>
			<Header />
			<SideBar
				isOpen={sidebarOpen}
				content={
					<MyReportOfferDetails
						selectedOffer={selectedOffer}
						handleAcceptRejectOffer={(decision: string) => handleAcceptRejectOffer(decision)}
						isOfferAccepted={isOfferAccepted}
					/>
				}
				onClose={handleCloseSidebar}
			/>

			<div id="content" className="w70 d-flex flex-wrap p-0">
				<div className="col-sm-12 filters my-requests px-3 d-flex flex-wrap align-items-stretch">
					<div className="col-sm-12 col-md-12 px-0 d-flex flex-column justify-content-between">
						<h3 className="dual-heading nobreak text-center pr-3 pl-0 mb-3 ">
							MOJE <span>ZGŁOSZENIE</span>
						</h3>
						<div className="subheading text-center div-cornertop px-4">
							{report && calculateElapsedTime(report.createdAt)}
						</div>
					</div>
					<div id="mobile-filters">
						{/* eslint-disable */}
						<button
							type="button"
							className={`tab details-tab ${toggleDescription && 'active'} `}
							onClick={() => {
								setToggleDescription(!toggleDescription);
							}}
						/>
						<button
							type="button"
							className={`tab map-tab ${toggleMap && 'active'}`}
							onClick={() => {
								setToggleMap(!toggleMap);
							}}
						/>
						{/* eslint-enable */}
					</div>

					<div
						id="details-container"
						className={`col-sm-12 col-md-12 flex-wrap align-items-start p-0 collapse ${
							toggleDescription && 'show'
						}`}
					>
						<div className="col-sm-12 col-md-4 p-0 ">
							<label className="green">Preferowany termin usługi</label>
							<div className="date-container d-flex flex-wrap ">{renderDueDate()}</div>
						</div>
						<div className="col-sm-12 col-md-4 p-0  d-flex flex-wrap">
							<label className="green">Kategoria ogólna</label>
							<div className="col-sm-12 px-0 category-container d-flex ">{renderServices()}</div>
							<div className="col-sm-12 px-0">
								<label className="green">Płatność</label>
								<div className="txt-solid">{parsePaymentType(_.get(report, 'paymentType', '-'))}</div>
							</div>
						</div>
						<div className="col-sm-12 col-md-4 p-0 ">
							<div className="car-container col-sm-12 p-0 d-flex flex-wrap">
								<label className="green">Samochód</label>
								<div className="car-make txt-solid">
									{capitalizeFirstLetter(_.get(report, 'car.manufacturer', '-'))}
								</div>
								<div className="car-model txt-solid">
									{' '}
									{capitalizeFirstLetter(_.get(report, 'car.model', '-'))}
								</div>
								<div className="car-year txt-solid">{_.get(report, 'car.year', '-')}</div>
							</div>
							<div className="car-container col-sm-12 p-0 d-flex flex-wrap">
								<label className="green">Dodatkowe</label>
								<div className="car-cc txt-solid">{_.get(report, 'car.engine', '-')}</div>
								<div className="car-petrol txt-solid">
									{getFuelTypeName(_.get(report, 'car.fuelType'))}
								</div>
								<div className="car-body txt-solid">
									{capitalizeFirstLetter(getChassisTypeClass(_.get(report, 'car.chassisType')))}
								</div>
								<div className="car-gearbox txt-solid">
									{getTransmissionName(_.get(report, 'car.transmission'))}
								</div>
							</div>
						</div>
						<div className="col-sm-2 pl-0 py-0 m-0">
							<div className="green-footer mt-2 align-self-stretch d-flex flex-wrap justify-content-center align-items-center text-center px-4 py-2">
								<span>Otrzymano</span>
								{_.get(report, 'offers', []).length} ofert
							</div>
						</div>
						<div className="col-sm-8  p-0 mt-0 ">
							<label className="green">Dodatkowe informacje</label>
							<div className="comments limited txt-solid">{_.get(report, 'description')}</div>
						</div>
					</div>

					<Scroll.Element
						id="scroll-container"
						name=""
						className="offers-container col-sm-12 d-flex flex-column px-0 mt-4"
						// style={{
						// 	position: 'relative',
						// 	height: '200px',
						// 	overflow: 'scroll',
						// 	marginBottom: '100px'
						//   }}
					>
						<h3 className="dual-heading mobileonly text-left pr-3 pl-0 my-3">
							Otrzymano <span className="green">{_.get(report, 'offers', []).length} ofert</span>
						</h3>
						{renderOffers()}
					</Scroll.Element>
				</div>
			</div>

			<div id="map-container" className={`w30 collapse ${toggleMap && 'show'}`}>
				<SideMap
					coordinates={workshopsCoordinates}
					userCoordinates={_.get(report, 'address.location.coordinates')}
					handleMarkerClick={handleMarkerClick}
				/>
			</div>
		</>
	);
};

export default MyReport;
